import React from "react";
import {Box, Button, Typography} from "@mui/material";
import {TodoTask} from "src/components/entities/todotask/TodoTask";
import {useSelector} from "react-redux";
import {getTodoTaskGetter} from "src/features/entity";
import { Alert, AlertTitle } from '@mui/material';
import {Assignment} from "@mui/icons-material";

const CATEGORIES_ORDER = [
  'bug',
  'details',
  'feedback',
  'time_range',
  'source_gemagvl4',
  'source_times',
  'gemagvl4_limitation',
  'quantity',
  'quality',
  'default',
];

const TODO_TYPES_ORDER = [
  'match100',
  'match',
]

const SEVERITY_ORDER = ['error', 'warning', 'magic', 'info'];

const INNER_CATEGORY_SEVERITY_ORDER = ['error', 'magic', 'warning', 'info'];

const sortOrder = (
  {severity: firstSeverity, category: firstCategory, todo_type: firstTodoType},
  {severity: secondSeverity, category: secondCategory, todo_type: secondTodoType}
) => {
  if (firstCategory === secondCategory && firstSeverity !== secondSeverity) {
    return INNER_CATEGORY_SEVERITY_ORDER.indexOf(firstSeverity) - INNER_CATEGORY_SEVERITY_ORDER.indexOf(secondSeverity);
  }

  if (firstSeverity !== secondSeverity) {
    return SEVERITY_ORDER.indexOf(firstSeverity) - SEVERITY_ORDER.indexOf(secondSeverity);
  }

  if (firstCategory !== secondCategory) {
    return CATEGORIES_ORDER.indexOf(firstCategory) - CATEGORIES_ORDER.indexOf(secondCategory);
  }

  // if (firstTodoType !== secondTodoType) {
  //   return CATEGORIES_ORDER.indexOf(firstTodoType) - CATEGORIES_ORDER.indexOf(secondTodoType);
  // }

  if (firstTodoType !== secondTodoType) {
    return TODO_TYPES_ORDER.indexOf(firstTodoType) - TODO_TYPES_ORDER.indexOf(secondTodoType);
  }

  return 0;
};

export const TodoTasksCategory = React.memo(({category, todoTasks, highestSeverity, mt, ...props}) => {
  let originalSeverity = todoTasks[0]?.message?.severity;

  let severity = originalSeverity;

  let categoryTitle, categoryDescription;

  switch (category) {
    case "bug":
      categoryTitle = "Sie haben einen Bug entdeckt.";
      categoryDescription = "Es liegen Hinweise vor, die auf einen Bug oder eine bislang fehlende Funktion in Sendemeldung.de hindeuten. Bitte kontaktieren Sie uns, damit wir dies beheben können.";
      break;
    case "details":
      if (severity === 'error') {
        categoryTitle = "Ihre Stammdaten sind unvollständig.";
        categoryDescription = "Bitte ergänzen Sie die nachfolgend aufgeführten Informationen, um Sendemeldungen übermitteln zu können.";
      } else {
        categoryTitle = "Es gibt Hinweise zu Ihren Stammdaten.";
        categoryDescription = "Bitte prüfen Sie die nachfolgend aufgeführten Informationen, bevor Sie die Lieferung übermitteln.";
      }
      break;
    case "feedback":
      categoryTitle = "Rückmeldung von GEMA/GVL erhalten";
      break;
    case "source_gemagvl4":
      categoryTitle = "Ihr Sendesystem erzeugt fehlerhafte GEMAGVL4-Meldungen.";
      categoryDescription = "Die GEMAGVL4-Daten, die Sie übermittelt haben, sind nicht spezifikationskonform. Wir haben versucht, die Fehler automatisch zu korrigieren und Ihre Sendemeldungen dennoch zu erfassen. Jedoch können wir nicht garantieren, dass uns dies vollständig gelungen ist. Bitte überprüfen Sie daher die betroffenen Ausstrahlungen.";
      break;
    case "source_times":
      if (severity === 'error') {
        categoryTitle = "Ihr Sendesystem übermittelt fehlerhafte Ausstrahlungszeiten.";
        categoryDescription = "Die GEMAGVL4-Daten, die Sie übermittelt haben, enthalten fehlerhafte Angaben zu Ausstrahlungszeiten. Bitte überprüfen Sie die nachfolgend aufgeführten Fälle und setzen Sie sich ggf. mit dem Hersteller Ihres Sendesystems in Verbindung, um den Fehler zu korrigieren.";
      } else if (severity === 'warning') {
        categoryTitle = "Bitte überprüfen Sie die erfassten Ausstrahlungszeiten.";
        categoryDescription = "Ihre GEMAGVL4-Daten enthalten Auffälligkeiten in Bezug auf Ausstrahlungszeiten. Dies könnte ein Hinweis auf eine fehlerhafte Konfiguration Ihres Sendesystems sein. Sie können die erkannten Auffälligkeiten nachfolgend prüfen.";
      } else {
        categoryTitle = "Wir haben Ihre Ausstrahlungszeiten korrigiert.";
        categoryDescription = "Ihre GEMAGVL4-Daten enthielten fehlerhafte Angaben zu Ausstrahlungszeiten, die wir automatisch korrigiert haben.";
      }
      break;
    case "gemagvl4_limitation":
      categoryTitle = "Wir haben Uneindeutigkeiten beim GEMAGVL4-Import automatisch aufgelöst.";
      categoryDescription = "Aufgrund technischer Einschränkungen des GEMAGVL4-Formats enthalten Ihre GEMAGVL4-Daten Informationen, die nicht eindeutig sind. Wir haben uns Mühe gegeben, diese Uneindeutigkeiten für Sie aufzulösen. Nachfolgend können Sie überprüfen, ob uns dies gelungen ist.";
      break;
    case "quantity":
      if (severity === 'warning' || severity === 'error') {
        categoryTitle = "Bitte überprüfen Sie den Umfang Ihrer Lieferung.";
        categoryDescription = "Ihre GEMAGVL4-Daten enthalten Auffälligkeiten, die darauf hindeuten, dass zu viele oder zu wenige Ausstrahlungen übermittelt worden sein könnten. Bitte prüfen Sie für Details die nachfolgenden Hinweise.";
      } else {
        categoryTitle = "Platzhalter-Ausstrahlungen werden ausgelassen.";
        categoryDescription = "Ihre GEMAGVL4-Daten scheinen Platzhalter-Ausstrahlungen zu enthalten, die nicht melderelevant sind. Diese werden beim XML-Export ausgelassen.";
      }
      break;
    case "time_range":
      if (severity === 'error') {
        categoryTitle = "Ihre Lieferung enthält unplausible Sendedauern.";
        categoryDescription = "Ihre GEMAGVL4-Daten enthalten unplausible Angaben zu Sendedauern und/oder Meldezeiträumen und würden in dieser Form voraussichtlich von den Verwertungsgesellschaften zurückgewiesen. Bitte überprüfen Sie die nachfolgenden Hinweise.";
      } else {
        categoryTitle = "Ihre Lieferung ist möglicherweise unvollständig.";
        categoryDescription = "Wir glauben, dass diese Lieferung unvollständig ist. Bitte überprüfen Sie für Details die nachfolgenden Hinweise.";
      }
      break;
    case "quality":
      if (severity === 'error') {
        categoryTitle = "Es fehlen Informationen zu Musikproduktionen.";
        categoryDescription = "Zu einigen Musikproduktionen müssen Informationen nachgetragen werden, um eine Übermittlung der XML-Meldung zu ermöglichen.";
      } else if (severity === 'magic') {
        categoryTitle = "Wir haben ergänzende Metadaten gefunden.";
        categoryDescription = "Einige Ihrer Musikproduktionen können mit Referenzdaten zusammengeführt und Metadaten automatisch vervollständigt werden. Sendemeldung.de hat hierzu passende Vorschläge vorbereitet.";
      } else if (severity === 'warning') {
        categoryTitle = "Wir haben potentielle Datenqualitätsmängel erkannt.";
        categoryDescription = (
          <>
            {' '}
            <Typography gutterBottom component="span">Es wurden Mängel an Musik-Metadaten identifiziert.</Typography>
            {' '}
            <Typography style={{fontWeight: 590}} gutterBottom component="span">Sie müssen hier jedoch in der Regel noch keine Änderungen durchführen.</Typography>
            {' '}
            Übergangsweise ist mit den Verwertungsgesellschaften verabredet, Sendemeldungen in der gleichen Qualität zu übermitteln, die auch im GEMAGVL4-Meldeverfahren akzeptiert wurde. Wir arbeiten derzeit an weiteren Werkzeugen, mit deren Hilfe Sie fehlende Metadaten direkt in Sendemeldung.de recherchieren und ergänzen können.
          </>
        );
      } else {
        categoryTitle = "Wir haben Ihre Musik-Metadaten korrigiert.";
        categoryDescription = "Es wurden automatisch Korrekturen an den durch Sendemeldung.de importierten Musik-Metadaten vorgenommen, um die Meldung im XML-Format zu ermöglichen.";
      }

      if (severity === 'warning') {
        severity = 'info';
      }

      break;
    default:
      categoryTitle = "Weitere Hinweise";
  }

  return (
    <Box mt={2}>
      <Alert variant="outlined" severity={severity === 'magic' ? 'info' : severity} icon={<Assignment/>}>
        <AlertTitle>{categoryTitle}</AlertTitle>
        <Typography>{categoryDescription}</Typography>
        <Box mt={2}>
          {todoTasks.map(({id, message}, i) => (
            <Box key={id || i} mt={mt}>
              <TodoTask
                id={id}
                defaultExpanded={(message?.severity === originalSeverity && originalSeverity === highestSeverity) || (highestSeverity === 'warning' && (message?.severity === 'magic' || (message?.severity === 'warning' && originalSeverity === 'magic')))}
                {...props}
              />
            </Box>
          ))}
        </Box>
      </Alert>
    </Box>
  );
});

export const TodoTasks = React.memo(function ({ids, children, mt = 1, showCategories = false, filteredByTodoTaskId, ...props}) {
  const getTodoTask = useSelector(getTodoTaskGetter);

  const todoTasks = ids?.map(getTodoTask);
  const filteredByTodoTask = getTodoTask(filteredByTodoTaskId);

  const [showAll, setShowAll] = React.useState(false);

  if (!showCategories) {
    const filteredTodoTasks = todoTasks?.filter((todoTask) => todoTask?.todo_type === filteredByTodoTask?.todo_type);

    const criticalTodoTasks = todoTasks?.filter((todoTask) => todoTask?.message?.severity === 'error' && todoTask?.todo_type !== filteredByTodoTask?.todo_type);
    const magicTodoTasks = todoTasks?.filter((todoTask) => todoTask?.message?.severity === 'magic' && todoTask?.todo_type !== filteredByTodoTask?.todo_type);
    const warningTodoTasks = todoTasks?.filter((todoTask) => todoTask?.message?.severity === 'warning' && todoTask?.todo_type !== filteredByTodoTask?.todo_type);
    const remainingTodoTasks = todoTasks?.filter((todoTask) => !(todoTask?.message?.severity === 'error' || todoTask?.message?.severity === 'warning' || todoTask?.message?.severity === 'magic') && todoTask?.todo_type !== filteredByTodoTask?.todo_type);

    const nonFilteredTodoTasksCount = ids?.length - filteredTodoTasks?.length;

    return (
      (filteredTodoTasks?.length && !showAll) ? (
        <>
          {ids?.length ? (
            [...filteredTodoTasks].map(({id}, i) => (
              <Box key={id || i} mt={mt}>
                <TodoTask id={id} {...props}/>
              </Box>
            ))
          ) : children}
          {nonFilteredTodoTasksCount ? (
            <Box pt={1}>
              <Button onClick={() => setShowAll(true)} color="info">
                {nonFilteredTodoTasksCount === 1 ? (
                  <>
                    weiteren Hinweis einblenden
                  </>
                ) : (
                  <>
                    {nonFilteredTodoTasksCount} weitere Hinweise einblenden
                  </>
                )}
              </Button>
            </Box>
          ) : null}
        </>
      ) : (
        <>
          {ids?.length ? (
            [...filteredTodoTasks, ...criticalTodoTasks, ...magicTodoTasks, ...warningTodoTasks, ...remainingTodoTasks].map(({id}, i) => (
              <Box key={id || i} mt={mt}>
                <TodoTask id={id} {...props}/>
              </Box>
            ))
          ) : children}
        </>
      )
    );
  }

  const todoTasksByCategory = {};
  for (const todoTask of todoTasks) {
    const {category} = todoTask;
    if (!todoTasksByCategory[category]) {
      todoTasksByCategory[category] = {todoTasks: []};
    }
    todoTasksByCategory[category].todoTasks.push(todoTask);
  }

  Object.values(todoTasksByCategory).forEach(data => {
    data.severity = data?.todoTasks.reduce((acc, {message}) => {
      const severity = message?.severity;
      if (acc === 'error' || severity === 'error') {
        return 'error';
      } else if (acc === 'warning' || severity === 'warning') {
        return 'warning';
      } else if (acc === 'magic' || severity === 'magic') {
        return 'magic';
      } else {
        return 'info';
      }
    }, 'info');

    data.category = data?.todoTasks[0].category;

    data.todoTasks = data.todoTasks.sort((firstEl, secondEl) => sortOrder(
      {...firstEl, severity: firstEl.message?.severity},
      {...secondEl, severity: secondEl.message?.severity},
    ));

    return data;
  });

  const todoTaskCategories = Object.keys(todoTasksByCategory).sort((firstEl, secondEl) => sortOrder(
    todoTasksByCategory[firstEl],
    todoTasksByCategory[secondEl],
  ));

  const highestSeverity = todoTasksByCategory[todoTaskCategories[0]]?.severity;

  return (
    <>
      {!ids?.length ? (
        children
      ) : todoTaskCategories.map(category => (
        <TodoTasksCategory
          key={category}
          category={category}
          todoTasks={todoTasksByCategory[category].todoTasks}
          highestSeverity={highestSeverity}
          mt={mt}
          {...props}
        />
      ))}
    </>
  );
});
