import React, {useMemo} from "react";
import {DashboardLayout} from "src/components/layout";
import Alert from '@mui/material/Alert';
import CircularProgress from "@mui/material/CircularProgress";
import _ from "lodash";
import SystemOverloadIndicator from "src/components/dashboard/components/overload-indicator";
import GemaGVLXMLRow from "src/components/entities/gemagvlxml/GemaGVLXMLRow";
import HrefButton from "src/packages/gatsby-mui-helpers/HrefButton";
import ReportFilter from "src/components/entities/gemagvlxml/components/ReportFilter";
import {useOrganizationRelatedListing} from "src/features/ui/listing/listing-hooks";
import PageToolbar from "src/components/layout/components/PageToolbar";
import {Box} from "@mui/material";
import {useRequirePermissions} from "src/features/dashboard/dashboard-hooks";
import OnlyIfPermissions from "src/features/dashboard/OnlyIfPermissions";

export default function ReportsPage() {
  useRequirePermissions({perm_read_reports: true});

  const {
    filterProps,
    paginationProps,
    isLoading,
    noDataExists,
    renderIds,
  } = useOrganizationRelatedListing({
    listingIdSuffix: 'reports',
    endpoint: '/api/sendemeldung/organizations/[ORGANIZATION_ID]/gemagvlxml_lieferungen/',
    entityType: 'gemagvlxml_lieferung',
  });

  const [expanded, setExpanded] = React.useState(false);
  const handleChange = useMemo(() => _.memoize((panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  }), [setExpanded]);

  return (
    <DashboardLayout
      titlePrefix="Sendemeldungen"
      selectedPage="reports"
    >
      <PageToolbar
        title="Sendemeldungen"
        searchField={(
          <ReportFilter
            {...filterProps}
            allowSearch
          />
        )}
        hidePagination={!!noDataExists}
        paginationProps={paginationProps}
        extraField={!noDataExists && !isLoading && (!filterProps?.value?.length || true) ? (
          <OnlyIfPermissions perm_write_reports>
            <HrefButton
              href="/dashboard/import/"
              variant="outlined"
              color="default"
            >
              hochladen
            </HrefButton>
          </OnlyIfPermissions>
        ) : null}
      >
      </PageToolbar>

      <Box mt={-1}>
        <SystemOverloadIndicator/>

        {renderIds.length === 0 ? (
          noDataExists ? (
            <Alert variant="filled" severity="info">
              Es wurden noch keine Sendemeldungen importiert.

              <p>
                <HrefButton
                  href="/dashboard/import/"
                  variant="contained"
                  color="primary"
                >
                  jetzt Sendemeldungen hochladen
                </HrefButton>
              </p>
            </Alert>
          ) : isLoading ? (
            <Alert variant="filled" severity="info" icon={<CircularProgress size="1rem" color="inherit"/>}>
              Sendemeldungen werden geladen...
            </Alert>
          ) : (
            <Alert variant="filled" severity="info">
              Diese Ansicht enthält keine Einträge.
            </Alert>
          )
        ) : (
          <>
            {renderIds?.map((id, i) => !id ? null : (
              <GemaGVLXMLRow
                key={id || i}
                id={id}
                expanded={expanded === id}
                expandedUuid={expanded}
                onChange={handleChange(id)}
              />
            ))}
          </>
        )}
      </Box>
    </DashboardLayout>
  );
}
