import React, {useState} from "react";
import {Box, Fab, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,} from "@mui/material";
import {DashboardLayout} from "src/components/layout";
import {Add} from "@mui/icons-material";
import {useSelector} from "react-redux";
import Alert from '@mui/material/Alert';
import {getContactGetter, getContactRoleGetter} from "src/features/entity";
import ContactRoleRow from "src/components/entities/person/ContactRoleRow";
import CircularProgress from "@mui/material/CircularProgress";
import {PersonForm} from "src/components/entities/person/PersonForm";
import {useOrganizationRelatedListing} from "src/features/ui/listing/listing-hooks";
import PersonFilter from "src/components/entities/person/PersonFilter";
import PageToolbar from "src/components/layout/components/PageToolbar";
import OnlyIfPermissions from "src/features/dashboard/OnlyIfPermissions";

export default function PersonTable({organizationId}) {
  const {
    filterProps,
    paginationProps,
    isLoading,
    noDataExists,
    renderIds,
  } = useOrganizationRelatedListing({
    listingIdSuffix: 'people',
    endpoint: '/api/sendemeldung/organizations/[ORGANIZATION_ID]/contact_roles/',
    entityType: 'contact_role',
  });

  const [editPerson, setEditPerson] = useState(null);

  const getContactRole = useSelector(getContactRoleGetter);
  const getContact = useSelector(getContactGetter);

  const getContactAndRole = (contactRoleId) => {
    const {
      contact,
      ...role
    } = getContactRole(contactRoleId);
    return {
      contact: getContact(contact),
      ...role,
    };
  };

  return (
    <DashboardLayout
      titlePrefix="Personen"
      selectedPage="people"
      drawerContentProps={{expandSettings: true}}
    >
      <PageToolbar
        title="Personen"
        isAtTop
        searchField={(
          <PersonFilter
            {...filterProps}
            allowSearch
          />
        )}
        hidePagination={!!noDataExists}
        paginationProps={paginationProps}
      >
      </PageToolbar>

      {renderIds.length === 0 ? (
        noDataExists ? (
          <Alert variant="filled" severity="info">
            Es wurden noch keine Personen eingerichtet.
          </Alert>
        ) : isLoading ? (
          <Alert variant="filled" severity="info" icon={<CircularProgress size="1rem" color="inherit"/>}>
            Personen werden geladen...
          </Alert>
        ) : (
          <Alert variant="filled" severity="info">
            Diese Ansicht enthält keine Einträge.
          </Alert>
        )
      ) : (
        <TableContainer component={Paper}>
          <Table aria-label="people table">
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>E-Mail</TableCell>
                <TableCell align="center">Login-Berechtigung</TableCell>
                <TableCell>Rollen</TableCell>
                <TableCell/>
              </TableRow>
            </TableHead>
            <TableBody>
              {renderIds?.map((id, i) => (
                <ContactRoleRow
                  key={id || i}
                  contactRoleId={id}
                  editContactRole={(contactRoleId) => setEditPerson(getContactAndRole(contactRoleId))}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      {/*TODO: Delayed removal of dialog for fadeout transition.*/}
      {editPerson ? (
        <PersonForm
          data={editPerson}
          onClose={() => setEditPerson(null)}
        />
      ) : null}

      <OnlyIfPermissions perm_write_people_and_rights>
        <Box mt={7} mx={1.5} textAlign="right">
          <Fab
            style={{
              position: 'fixed',
              right: 20,
              bottom: 20,
            }}
            color="primary"
            aria-label="add"
            onClick={() => (
              // TODO: Outsource person bootstrap data to api/schemas.
              setEditPerson({
                organization: organizationId,
                contact: {
                  organizations: [organizationId],
                  allow_mails: true,
                },
              })
            )}>
            <Add/>
          </Fab>
        </Box>
      </OnlyIfPermissions>
    </DashboardLayout>
  );
}
