import React, {useEffect, useState} from "react";
import {DashboardLayout} from "src/components/layout";
import {
  AlertTitle,
  Badge,
  Box,
  Card,
  CardActions,
  CardContent,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
  Skeleton,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Tooltip,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import Alert from '@mui/material/Alert';
import makeStyles from '@mui/styles/makeStyles';
import HrefButton from "src/packages/gatsby-mui-helpers/HrefButton";
import {useSelector} from "react-redux";
import {getSelectedOrganization, getSelectedOrganizationId} from "src/features/dashboard";
import {useOrganizationRelatedListing} from "src/features/ui/listing/listing-hooks";
import CircularProgress from "@mui/material/CircularProgress";
import {AccessTime, Assignment, AssignmentLate, AssignmentTurnedIn, Check, MoreVert} from "@mui/icons-material";
import ProgrammeChip from "src/components/entities/programme/ProgrammeChip";
import {useEntityApi, useEntityObserver} from "src/features/entity/entity-hooks";
import {getStationGetter} from "src/features/entity";
import NaturalDateRange from "src/components/entities/gemagvlxml/components/NaturalDateRange";
import axios from "axios";
import SimpleDuration from "src/components/entities/gemagvlxml/SimpleDuration";
import UnreadNotificationsBox from "src/components/entities/notification/UnreadNotificationsBox";
import dateFormat from "dateformat";
import {STATION} from "src/api/api-schemas";
import {useHasPermissions} from "src/features/dashboard/dashboard-hooks";
import OnlyIfPermissions from "src/features/dashboard/OnlyIfPermissions";

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 275,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  listRoot: {
    width: '100%',
    //maxWidth: 800,
    '& .MuiListItem-root': {
      backgroundColor: theme.palette.background.paper,
      borderRadius: theme.shape.borderRadius,
      border: `1px solid ${theme.palette.divider}`,
      marginTop: theme.spacing(1),
    },
  },
}));

function SimpleCard({
  banner,
  title,
  subTitle,
  children,
  actions
}) {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <CardContent>
        <Typography className={classes.title} color="textSecondary" gutterBottom>
          {banner}
        </Typography>
        <Typography variant="h5" component="h2">
          {title}
        </Typography>
        <Typography className={classes.pos} color="textSecondary" component="div">
          {subTitle}
        </Typography>
        <Typography variant="body2" component="div">
          {children}
        </Typography>
      </CardContent>
      <CardActions>
        {actions}
      </CardActions>
    </Card>
  );
}

function PendingStation({id}) {
  useEntityObserver({
    type: 'station',
    id
  });

  const {
    organization: organizationId,
    name,
    gemagvl_sender_prg_id,
    gemagvl4_stations,
    isDeleted,
    is_active,
    is_draft,
    reports_count,
    report_jingles,
    pending_reports_count,
    can_delete,
    latest_report_date,
    first_missing_report_date,
    first_missing_report_date_coverage,
    required_reports_date,
    required_daily_standard_musik_dauer,
    required_daily_jingle_musik_dauer,
  } = useSelector(getStationGetter)(id);

  const [due_periods, setDuePeriods] = useState(null);

  useEffect(async () => {
    if (!id || !organizationId) {
      return;
    }
    try {
      if (due_periods === null) {
        setDuePeriods(undefined);
      }
      const result = await axios.get(`/api/sendemeldung/organizations/${organizationId}/stations/${id}/due_periods/`);
      setDuePeriods(result.data);
    } catch (e) {
      setDuePeriods(null);
      console.error(e);
    }
  }, [id, organizationId, first_missing_report_date]);

  const deadlineExceeded = latest_report_date && latest_report_date < required_reports_date;
  const deadlineSoon = deadlineExceeded || due_periods?.length > 0;

  const hasPublishedAnything = due_periods?.filter(([startDate, endDate, standardDuration, jingleDuration]) =>
    standardDuration && standardDuration !== '0.0' || jingleDuration && jingleDuration !== '0.0'
  )?.length > 0;

  const lastRequiredReportDate = due_periods?.[due_periods?.length - 1]?.[1];

  const [anchorEl, setAnchorEl] = React.useState(null);
  const menuOpen = Boolean(anchorEl);

  const openMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  const entityApi = useEntityApi(STATION);

  const markDateReported = async ({
    organizationId,
    id,
    reported_date,
  }) => {
    await entityApi.post(
      `/api/sendemeldung/organizations/${organizationId}/stations/${id}/mark_date_reported/`,
      {reported_date},
    );
  };

  let hasOutdatedData = first_missing_report_date && due_periods?.filter(([startDate]) => startDate < first_missing_report_date)?.length > 0;

  const hasSubmitReportsPermission = useHasPermissions({perm_submit_reports: true});

  const requiresMusicEveryDay = required_daily_standard_musik_dauer !== '00:00:00';
  const requiresJinglesEveryDay = report_jingles && required_daily_jingle_musik_dauer !== '00:00:00';

  return (
    <ListItem>
      <ListItemIcon>
        {deadlineExceeded ? (
          <Badge color="primary" variant="dot">
            <AssignmentLate/>
          </Badge>
        ) : deadlineSoon ? (
          <AccessTime/>
        ) : due_periods === undefined ? (
          <Skeleton variant="circular">
            <Badge color="primary" variant="dot">
              <Assignment/>
            </Badge>
          </Skeleton>
        ) : (
          <Badge color="primary" variant="dot">
            <Assignment/>
          </Badge>
        )}
      </ListItemIcon>
      <ListItemText
        primary={(
          <>
            {deadlineExceeded ? (
              <>
                Die Meldefrist für <ProgrammeChip id={id} size="small"/> ist bereits überschritten.
              </>
            ) : deadlineSoon ? (
              <>
                Es stehen Sendemeldungen für <ProgrammeChip id={id} size="small"/> an.
              </>
            ) : due_periods === undefined ? (
              <Skeleton/>
            ) : (
              <>
                Einige Sendemeldungen für <ProgrammeChip id={id} size="small"/> erfordern Ihre Aufmerksamkeit.
              </>
            )}
          </>
        )}
        secondary={(<>
          {!deadlineSoon && due_periods === undefined ? (
            <Skeleton/>
          ) : (
            <>
              {due_periods === undefined || hasOutdatedData ? (
                <>
                  <CircularProgress size="0.7rem" color="inherit"/>
                  {' '}
                </>
              ) : null}
              {(deadlineSoon || deadlineExceeded) && due_periods?.length === 0 ? (
                <>
                  Keine weiteren offenen Zeiträume.
                </>
              ) : (deadlineSoon || deadlineExceeded) && due_periods?.length > 0 ? (
                <>
                  {due_periods?.map(([startDate, endDate, standardDuration, jingleDuration], i) => (
                    <span key={i}>
                {i > 0 ? (<>, <br/></>) : null}
                      <NaturalDateRange
                        startDate={startDate}
                        endDate={endDate}
                      />

                      {standardDuration && standardDuration !== '0.0' && jingleDuration && jingleDuration !== '0.0' ? (
                        <>
                          {' '}
                          (bislang erst <SimpleDuration value={standardDuration}/> Musik und <SimpleDuration
                          value={jingleDuration}/> Jingles gemeldet)
                        </>
                      ) : standardDuration && standardDuration !== '0.0' ? (
                        requiresJinglesEveryDay ? (
                          <>
                            {' '}
                            (bislang <SimpleDuration value={standardDuration}/> Musik und noch keine Jingles gemeldet)
                          </>
                        ) : (
                          <>
                            {' '}
                            (bislang erst <SimpleDuration value={standardDuration}/> Musik gemeldet)
                          </>
                        )
                      ) : jingleDuration && jingleDuration !== '0.0' ? (
                        requiresMusicEveryDay ? (
                          <>
                            {' '}
                            (bislang noch keine Musik, jedoch <SimpleDuration value={jingleDuration}/> Jingles gemeldet)
                          </>
                        ) : (
                          <>
                            {' '}
                            (bislang erst <SimpleDuration value={jingleDuration}/> Jingles gemeldet)
                          </>
                        )
                      ) : null}
              </span>
                  ))}
                  {' '}
                  {hasPublishedAnything ? "noch nicht vollständig übermittelt." : "noch nicht übermittelt."}
                </>
              ) : deadlineExceeded ? (
                <>
                  <NaturalDateRange
                    startDate={first_missing_report_date}
                    endDate={required_reports_date}
                  />
                  {' '}
                  noch nicht vollständig übermittelt.
                </>
              ) : null}
              {(!deadlineSoon && !deadlineExceeded) && pending_reports_count > 0 ? (
                <Box component="span" sx={{display: 'block'}}>
                  <HrefButton
                    href={`/dashboard/reports/?filter=status%3Doutstanding%26q%3D${gemagvl_sender_prg_id}`}
                    variant="outlined"
                    color="primary"
                    // className={classes.button}
                    size="small"
                    sx={{mt: 1}}
                  >
                    Sendemeldungen anzeigen
                  </HrefButton>
                </Box>
              ) : (
                <Box component="span" sx={{display: 'block'}}>
                  {deadlineSoon || deadlineExceeded ? (
                    <HrefButton
                      href={`/dashboard/import/`}
                      variant="outlined"
                      color={"primary"}
                      // className={classes.button}
                      size="small"
                      sx={{mt: 1, mr: 1}}
                    >
                      GEMAGVL4-Meldungen hochladen
                    </HrefButton>
                  ) : null}
                  <HrefButton
                    href={`/dashboard/reports/?filter=q%3D${gemagvl_sender_prg_id}`}
                    variant="outlined"
                    color={(pending_reports_count > 0 || (!deadlineSoon && !deadlineExceeded)) ? "primary" : "inherit"}
                    // className={classes.button}
                    size="small"
                    sx={{mt: 1}}
                  >
                    Sendemeldungen anzeigen
                  </HrefButton>
                  {first_missing_report_date && first_missing_report_date <= lastRequiredReportDate && hasSubmitReportsPermission ? (
                    <>
                      <Tooltip title="weitere Aktionen">
                        <IconButton
                          aria-label="more actions"
                          aria-controls={`${id}-more-menu`}
                          aria-haspopup="true"
                          onClick={openMenu}
                          size="small"
                          sx={{
                            mt: 1,
                            ml: 1
                          }}
                        >
                          <MoreVert/>
                        </IconButton>
                      </Tooltip>
                      <Menu
                        id={`${id}-more-menu`}
                        anchorEl={anchorEl}
                        anchorOrigin={{
                          vertical: 'top',
                          horizontal: 'left',
                        }}
                        keepMounted
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'left',
                        }}
                        open={menuOpen}
                        onClose={closeMenu}
                      >
                        <MenuItem onClick={() => {
                          closeMenu();
                          if (confirm(
                            `Sind Sie sicher, dass der ${dateFormat(first_missing_report_date, 'dd.mm.yyyy')} für ${name || gemagvl_sender_prg_id} bereits vollständig gemeldet ist? Für diesen Tag wurden bislang lediglich ${first_missing_report_date_coverage[0]} Sekunden Musik und ${first_missing_report_date_coverage[1]} Sekunden Jingles übermittelt.`
                          )) {
                            markDateReported({
                              organizationId,
                              id,
                              reported_date: first_missing_report_date
                            });
                          }
                        }}>
                          <ListItemIcon>
                            <AssignmentTurnedIn fontSize="small"/>
                          </ListItemIcon>
                          <ListItemText>
                            {dateFormat(first_missing_report_date, 'dd.mm.yyyy')} als vollständig gemeldet markieren
                          </ListItemText>
                        </MenuItem>
                      </Menu>
                    </>
                  ) : null}
                </Box>
              )}
            </>
          )}
        </>)}
      />
    </ListItem>
  );
}

function PreApprovalPage() {
  const classes = useStyles();

  const organization = useSelector(getSelectedOrganization);
  let {
    has_reports,
    has_exported_reports,
    has_published_reports,
  } = organization;

  let gemagvl_test_step = 0;
  if (has_published_reports) {
    gemagvl_test_step = 3;
  } else if (has_exported_reports) {
    gemagvl_test_step = 2;
  } else if (has_reports) {
    gemagvl_test_step = 1;
  }

  return (
    <>
      {has_published_reports ? (
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12}>
            <SimpleCard
              banner="Aktuell"
              title="Warte auf Freigabe durch GVL und GEMA"
            >
              <p>
                Die Verwertungsgesellschaften prüfen derzeit Ihre bereitgestellte Testmeldung.
              </p>
              <p>
                Nach erfolgreichem Abschluss erhalten wir eine Freigabe für Ihr Sendeunternehmen.
                Sobald diese vorliegt, wird die Übermittlung von Produktivmeldungen freigeschaltet.
              </p>
              <p>Wir werden Sie hierüber per E-Mail informieren.</p>
            </SimpleCard>
          </Grid>
        </Grid>
      ) : null}

      <Grid container pt={2}>
        <Grid item xs={12} sm={12}>
          <SimpleCard
            banner="Aktuell"
            title="Beispielmeldung an GVL und GEMA"
            actions={
              <>
                <HrefButton href="/dashboard/timeline/">Projektstatus</HrefButton>
              </>
            }
          >
            {has_published_reports ? (
              <>
                <p>
                  Bevor Produktivmeldungen über Sendemeldung.de übermittelt werden, möchten die
                  Verwertungsgesellschaften die Verarbeitungsprozesse für die einzelnen Sendeunternehmen prüfen.
                  Jedes Sendeunternehmen soll hierzu zunächst eine Beispiel-Sendemeldung hochladen, die von
                  Sendemeldung.de konvertiert und anschließend von den Verwertungsgesellschaften geprüft werden
                  kann.
                </p>
              </>
            ) : (
              <>
                <p>
                  Bevor Produktivmeldungen über Sendemeldung.de übermittelt werden, möchten die
                  Verwertungsgesellschaften die Verarbeitungsprozesse für die einzelnen Sendeunternehmen prüfen.
                  Jedes Sendeunternehmen soll hierzu zunächst eine Beispiel-Sendemeldung hochladen, die von
                  Sendemeldung.de konvertiert und anschließend von den Verwertungsgesellschaften geprüft werden
                  kann.
                </p>
                <Box mt={2} mb={2}>
                  <Alert variant="filled" severity="info" color="warning">
                    <AlertTitle>Wichtig</AlertTitle>
                    <p>
                      Die erfolgreiche Übermittlung einer Beispiel-Meldung ist Voraussetzung für die spätere
                      Übermittlung von Produktivmeldungen.
                    </p>
                    <p>
                      GEMA / GVL geben hierzu jedes Sendeunternehmen einzeln frei.
                      Sobald die Freigabe für Ihr Sendeunternehmen vorliegt, wird Ihnen die Übermittlung von
                      Produktivmeldungen über Sendemeldung.de ermöglicht.
                    </p>
                  </Alert>
                </Box>
              </>
            )}
            <Typography variant="h6">So übermitteln Sie eine Beispiel-Sendemeldung</Typography>
            <Stepper activeStep={gemagvl_test_step} orientation="vertical">
              <Step>
                <StepLabel>Sendemeldungen hochladen</StepLabel>
                <StepContent>
                  <Typography>
                    Bitte laden Sie zunächst eine GEMAGVL4-Datei mit aktuellen Sendemeldungen hoch.
                    Wir empfehlen, direkt die Ausstrahlungen für einen Monat zu testen, der später auch produktiv
                    übermittelt werden soll.
                    Die Verwertungsgesellschaften nehmen für den Test aber auch einen früheren Meldezeitraum
                    entgegen.
                  </Typography>
                  <div className={classes.actionsContainer}>
                    <div>
                      <HrefButton
                        href="/dashboard/import/"
                        variant="contained"
                        color="primary"
                        className={classes.button}
                      >
                        GEMAGVL4-Meldungen hochladen
                      </HrefButton>
                    </div>
                  </div>
                </StepContent>
              </Step>
              <Step>
                <StepLabel>XML-Meldungen generieren</StepLabel>
                <StepContent>
                  <Typography>
                    Nach Upload Ihrer Sendemeldungen können Sie diese prüfen und ggf. korrigieren.
                    Anschließend erzeugen Sie via Klick auf <strong>XML-Meldung generieren</strong> die
                    XML-Meldedatei.
                  </Typography>
                  <div className={classes.actionsContainer}>
                    <div>
                      <HrefButton
                        href="/dashboard/reports/"
                        variant="contained"
                        color="primary"
                        className={classes.button}
                      >
                        zu Ihren Sendemeldungen
                      </HrefButton>
                    </div>
                  </div>
                </StepContent>
              </Step>
              <Step>
                <StepLabel>An GEMA/GVL übermitteln</StepLabel>
                <StepContent>
                  <Typography>
                    Ihre erzeugte Beispiel-XML-Meldung können Sie nun zur Übermittlung an GEMA / GVL freigeben.
                    Aufgrund begrenzter Kapazitäten seitens der Verwertungsgesellschaften kann hierbei nur genau
                    eine XML-Meldung übermittelt werden &ndash; für ein Programm Ihrer Wahl.
                  </Typography>
                  <div className={classes.actionsContainer}>
                    <div>
                      <HrefButton
                        href="/dashboard/reports/"
                        variant="contained"
                        color="primary"
                        className={classes.button}
                      >
                        zu Ihren Sendemeldungen
                      </HrefButton>
                    </div>
                  </div>
                </StepContent>
              </Step>
              <Step>
                <StepLabel>Rückmeldung abwarten</StepLabel>
                <StepContent>
                  <Typography>
                    <strong>Super!</strong>
                    {' '}
                    Sie haben erfolgreich eine XML-Meldung zur Prüfung durch GEMA / GVL freigegeben.
                    Sie müssen jetzt erstmal nichts weiter tun.
                    Wir informieren Sie, sobald Ihre Freigabe für Produktivmeldungen oder eine sonstige Rückmeldung
                    seitens der Verwertungsgesellschaften vorliegt.
                  </Typography>
                </StepContent>
              </Step>
            </Stepper>
            {has_published_reports ? (
              null
            ) : (
              <Box mt={2}>
                <Alert variant="filled" severity="info" color="info">
                  <AlertTitle>Tipp</AlertTitle>
                  Weitere Erklärungen zur Nutzung von Sendemeldung.de finden Sie in unserem Hilfebereich.
                  <p>
                    <HrefButton
                      href="/dashboard/help/"
                      variant="outlined"
                      color="inherit"
                    >
                      zum Hilfebereich
                    </HrefButton>
                  </p>
                </Alert>
              </Box>
            )}
          </SimpleCard>
        </Grid>

      </Grid>
    </>
  );
}

function PendingStationsView() {
  const classes = useStyles();

  const {
    isLoading: pendingStationsIsLoading,
    renderIds: pendingStationsRenderIds,
  } = useOrganizationRelatedListing({
    listingIdSuffix: `pendingStations`,
    endpoint: '/api/sendemeldung/organizations/[ORGANIZATION_ID]/stations/',
    entityType: 'station',
    defaultOrdering: ['reported_until', 'gemagvl_sender_prg_id'],
    filter: [{
      id: 'pending',
      choice: 'has_pending_tasks'
    }],
    defaultPageSize: 100,
  });

  return (
    <Grid item xs={16} p={1}>
      <Paper sx={{
        width: '100%',
        height: '100%',
        p: 2
      }}>
        <Box>
          <Typography variant="h6">
            Anstehende Aufgaben
          </Typography>
          {pendingStationsRenderIds?.length === 0 ? (
            pendingStationsIsLoading ? (
              <Box display="flex" justifyContent="center" alignItems="center" height={150} color="lightgray">
                <CircularProgress size="2rem" color="inherit"/>
              </Box>
            ) : (
              <Box display="flex" justifyContent="center" alignItems="center" height={150} color="darkgray">
                <Check/>&nbsp;alle Programme gemeldet
              </Box>
            )
          ) : (
            <List className={classes.listRoot}>
              {pendingStationsRenderIds?.map((id, i) => (
                <PendingStation id={id} key={id || i}/>
              ))}
            </List>
          )}
        </Box>
      </Paper>
    </Grid>
  );
}

export default function DashboardPage() {
  const selectedOrganizationId = useSelector(getSelectedOrganizationId);
  const organization = useSelector(getSelectedOrganization);
  let {
    requires_test: requiresTest,
    type,
  } = organization;

  return (
    <DashboardLayout
      titlePrefix="Dashboard"
      selectedPage="dashboard"
    >
      <Box my={2}>
        <Typography variant="h6">
          Willkommen zu Sendemeldung.de
        </Typography>
      </Box>

      {requiresTest ? (
        <PreApprovalPage/>
      ) : (
        <Grid container spacing={0} columns={16} alignItems="stretch">
          {type === 'HF' ? (
            <OnlyIfPermissions perm_read_stations>
              <PendingStationsView/>
            </OnlyIfPermissions>
          ) : null}
          {type === 'VWG' ? (
            <Alert variant="filled" severity="info">
              <AlertTitle>Hinweis</AlertTitle>
              Sie sind als Verwertungsgesellschaft eingeloggt.
              Bitte beachten Sie, dass sich die Benutzeroberfläche für Verwertungsgesellschaften derzeit noch in
              Entwicklung befindet und daher möglicherweise noch nicht alles rund läuft.
            </Alert>
          ) : null}
          <UnreadNotificationsBox organizationId={selectedOrganizationId}/>
        </Grid>
      )}
    </DashboardLayout>
  );
}
