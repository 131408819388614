import React, {useState} from "react";
import {DashboardLayout} from "src/components/layout";
import {Box, Grid} from "@mui/material";
import Typography from "@mui/material/Typography";
import {getSelectedOrganization} from "src/features/dashboard";
import {useSelector} from "react-redux";
import makeStyles from '@mui/styles/makeStyles';
import GEMAGVL4ImportSettingsFormDialog from "src/components/entities/user/GEMAGVL4ImportSettingsFormDialog";
import {TIMEZONES} from "src/packages/timezones";
import {MUSIK_HERKUNFT_KNZ} from "src/features/dashboard/dashboard-validation";
import {Check} from "@mui/icons-material";
import {useRequirePermissions} from "src/features/dashboard/dashboard-hooks";
import {SettingAccordion} from "src/components/layout/components/SettingAccordion";

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  details: {
    alignItems: 'center',
  },
  column: {
    flexBasis: '33.33%',
  },
  multiColumn: {
    flexBasis: '66.67%',
  },
  icon: {
    flex: 0,
    paddingRight: theme.spacing(2),
    minWidth: 32,
    color: theme.palette.action.active,
  },
  nextToIcon: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '50.0%',
    flexShrink: 0,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
}));

export default function GEMAGVL4ImportPage() {
  useRequirePermissions({perm_read_details: true, perm_write_reports: true});

  const organization = useSelector(getSelectedOrganization);
  const {
    gemagvl4_encoding_preferences,
    gemagvl4_timezone,
    gemagvl4_import_modifiers,
    standard_musik_herkunft_knz,
    jingle_musik_herkunft_knz,
  } = organization;
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const [editImportSettings, setEditImportSettings] = useState(null);

  return (
    <DashboardLayout
      titlePrefix="Einstellungen für GEMAGVL4-Import"
      selectedPage="gemagvl4"
      drawerContentProps={{expandSettings: true}}
    >
      <Box my={2}>
        <Typography variant="h6">
          Einstellungen für GEMAGVL4-Import
        </Typography>
      </Box>

      <div className={classes.root}>
        <SettingAccordion
          id="gemagvl4_encoding"
          expanded={expanded === 'gemagvl4_encoding'}
          onChange={handleChange('gemagvl4_encoding')}
          onEdit={() => setEditImportSettings(organization)}
          title="Zeichensatz"
          description={(
            <>
              {!gemagvl4_encoding_preferences?.length ? (
                "(automatische Erkennung)"
              ) : (
                gemagvl4_encoding_preferences?.map((encoding) =>
                  encoding === null ? "(automatische Erkennung)" : encoding
                )?.join(', ')
              )}
            </>
          )}
        >
          <Typography>
            Beim Import von GEMAGVL4-Sendemeldungen wird der angegebene Zeichensatz verwendet. Sind mehrere
            Zeichensätze konfiguriert, so wird der erste Zeichensatz verwendet, mit dem die Datei vollständig
            dekodierbar ist.
          </Typography>
        </SettingAccordion>

        <SettingAccordion
          id="gemagvl4_timezone"
          expanded={expanded === 'gemagvl4_timezone'}
          onChange={handleChange('gemagvl4_timezone')}
          onEdit={() => setEditImportSettings(organization)}
          title="Zeitzone"
          description={(
            <>
              {!gemagvl4_timezone ? (
                "MEZ"
              ) : (
                TIMEZONES[gemagvl4_timezone] || gemagvl4_timezone
              )}
            </>
          )}
        >
          <Typography>
            Beim Import von GEMAGVL4-Sendemeldungen werden Zeitangaben der genannten Zeitzone zugeordnet.
          </Typography>
        </SettingAccordion>

        {gemagvl4_import_modifiers ? (
          <SettingAccordion
            id="gemagvl4_import_modifiers"
            expanded={expanded === 'gemagvl4_import_modifiers'}
            onChange={handleChange('gemagvl4_import_modifiers')}
            title="Importregeln / -filter"
            description={(
              <>
                <Grid item container xs={12} style={{alignItems: 'center'}}>
                  <div className={classes.icon}>
                    <Check/>
                  </div>
                  <div className={classes.nextToIcon}>
                    <Typography className={classes.nextToIcon}>
                      Benutzerdefinierte Importregeln aktiv.
                    </Typography>
                  </div>
                </Grid>
              </>
            )}
          >
            <Typography>
              Für Ihr Haus wurden benutzerdefinierte Regeln eingerichtet, die beim Import von Musikproduktionen aus GEMAGVL4
              angewendet werden. Bitte kontaktieren Sie uns, falls Sie mehr erfahren oder die bestehenden Regeln
              anpassen möchten.
            </Typography>
          </SettingAccordion>
        ) : null}

        <SettingAccordion
          id="standard_musik_herkunft_knz"
          expanded={expanded === 'standard_musik_herkunft_knz'}
          onChange={handleChange('standard_musik_herkunft_knz')}
          onEdit={() => setEditImportSettings(organization)}
          title="Musikherkunft Ihrer Musik"
          description={(
            <>
              {standard_musik_herkunft_knz === "AUTO" ? (
                "automatisch ermitteln"
              ) : standard_musik_herkunft_knz === "NEVER_EIGEN" ? (
                "automatisch ermitteln (ohne Eigenproduktionen)"
              ) : (
                standard_musik_herkunft_knz
              )}
            </>
          )}
        >
          <Typography>
            Bei GEMAGVL-XML-Meldungen muss stets die jeweilige Musikherkunft angegeben werden.
            Diese Information ist in GEMAGVL4-Meldungen nicht explizit enthalten.
            Sendemeldung.de versucht daher, die Musikherkunft automatisch anhand der in Ihrer GEMAGVL4-Meldung befüllten
            Felder zu ermitteln.
          </Typography>

          {standard_musik_herkunft_knz === "NEVER_EIGEN" ? (
            <Typography>
              Musikproduktionen ohne Katalognummer, ISRC und Informationen zum Label werden normalerweise als
              Eigenproduktion klassifiziert.
              Ihrer Einstellung zufolge werden jedoch auch Musikproduktionen, für die diese Angaben fehlen, als
              veröffentlichte Tonaufnahme importiert.
            </Typography>
          ) : (
            <Typography>
              Musikproduktionen ohne Katalognummer, ISRC und Informationen zum Label werden dabei in Absprache mit
              der GVL als Eigenproduktion klassifiziert.
              Falls Sie keine Eigenproduktionen ausstrahlen, können Sie dieses Verhalten hier ändern.
            </Typography>
          )}
        </SettingAccordion>

        <SettingAccordion
          id="jingle_musik_herkunft_knz"
          expanded={expanded === 'jingle_musik_herkunft_knz'}
          onChange={handleChange('jingle_musik_herkunft_knz')}
          onEdit={() => setEditImportSettings(organization)}
          title="Musikherkunft Ihrer Jingles"
          description={(
            <>
              {!jingle_musik_herkunft_knz ? (
                "(keine Auswahl)"
              ) : (
                MUSIK_HERKUNFT_KNZ[jingle_musik_herkunft_knz]
              )}
            </>
          )}
        >
          <Typography>
            Bei GEMAGVL-XML-Meldungen muss stets die jeweilige Musikherkunft angegeben werden.
            Diese Information ist in GEMAGVL4-Meldungen nicht enthalten.
            Hier können Sie auswählen, aus welcher Herkunft Ihre Jingles typischerweise stammen &ndash; diese wird dann
            für neu importierte Jingles voreingestellt.
          </Typography>
        </SettingAccordion>
      </div>

      {/*TODO: Delayed removal of dialog for fadeout transition.*/}
      {editImportSettings ? (
        <GEMAGVL4ImportSettingsFormDialog
          data={editImportSettings}
          onClose={() => setEditImportSettings(null)}
        />
      ) : null}

    </DashboardLayout>
  );
}
