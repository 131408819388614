import {STATION_SCHEMA} from "src/features/dashboard/dashboard-validation";
import {
  AlertTitle,
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem
} from "@mui/material";
import {YupField} from "src/packages/react-hook-form-mui-yup-helpers";
import React, {useCallback, useEffect} from "react";
import {
  useDatabase,
  useDatabaseRight,
  useEntityApi,
  useEntityObserver,
  useLookupTable
} from "src/features/entity/entity-hooks";
import {useSelector} from "react-redux";
import {getSelectedOrganization, getSelectedOrganizationId} from "src/features/dashboard";
import {STATION} from "src/api/api-schemas";
import Alert from '@mui/material/Alert';
import Typography from "@mui/material/Typography";
import {getOrganizationGetter, getStationGetter} from "src/features/entity";
import GEMAGVL4Station from "src/components/entities/programme/GEMAGVL4Station";
import axios from "axios";
import {useFormContext} from "react-hook-form";
import {
  Add,
  Album,
  Delete,
  ImportExport,
  KeyboardArrowDown,
  KeyboardArrowUp,
  MoreHoriz,
  QuestionAnswer
} from "@mui/icons-material";
import CircularProgress from "@mui/material/CircularProgress";
import EntityFormDialog from "src/features/entity/EntityFormDialog";

function MergeCandidate({
  id,
  confirm
}) {
  useEntityObserver({
    type: 'station',
    id
  });

  const {
    gemagvl4_stations,
  } = useSelector(getStationGetter)(id);

  return (
    <Alert variant="filled" severity="warning" icon={<QuestionAnswer/>}>
      <Typography>
        Es existiert ein gleichnamiges Programm mit identischen Daten, das für GEMAGVL4-Ausstrahlungen mit folgenden
        Daten verwendet wird:
      </Typography>
      <Typography gutterBottom>
        {gemagvl4_stations?.map((gemagvl4StationId, i) => (
          <GEMAGVL4Station key={gemagvl4StationId || i} id={gemagvl4StationId}/>
        ))}
      </Typography>
      <Typography>
        <Button
          variant="contained"
          color="secondary"
          onClick={confirm}
        >
          Programme zusammenführen
        </Button>
      </Typography>
    </Alert>
  );
}

function LookupTable({
  id,
  order,
  organizationId,
  isImportLookupTable,
  increasePriority,
  decreasePriority,
  remove,
  setImportLookupTable,
  ...props
}) {
  const {database} = useLookupTable({id});
  const {
    owner,
    name,
  } = useDatabase({id: database});

  const organizationDatabaseRightId = organizationId && database ? `${organizationId}/${database}` : undefined;
  const {can_write} = useDatabaseRight({id: organizationDatabaseRightId});

  if (isImportLookupTable || !can_write) {
    setImportLookupTable = undefined;
  }

  const [anchorEl, setAnchorEl] = React.useState(null);
  const menuOpen = Boolean(anchorEl);

  const openMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  return (
    <ListItem {...props}>
      <ListItemIcon>
        {order !== undefined ? (
          <Avatar>{order}</Avatar>
        ) : (
          <Album/>
        )}
      </ListItemIcon>
      <ListItemText
        primary={(
          owner === organizationId ? (
            "Eigene Musik"
          ) : (
            "Fremddatenbank"
          )
        )}
        secondary={(
          <>
            {name || id}
            {isImportLookupTable ? (
              <Box component="span" display="block" mt={0.5}>
                <Chip
                  size="small"
                  label="Ziel für Import neuer Musikproduktionen"
                  component="span"
                />
              </Box>
            ) : null}
          </>
        )}
      />
      {increasePriority || decreasePriority || remove || setImportLookupTable ? (
        <>
          <IconButton
            aria-label="more actions"
            aria-controls={`${id}-more-menu`}
            aria-haspopup="true"
            onClick={openMenu}
            size="large">
            <MoreHoriz/>
          </IconButton>
          <Menu
            id={`${id}-actions`}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            open={menuOpen}
            onClose={closeMenu}
          >
            {increasePriority ? (
              <MenuItem onClick={() => {
                closeMenu();
                increasePriority(id);
              }}>
                <ListItemIcon>
                  <KeyboardArrowUp fontSize="small"/>
                </ListItemIcon>
                <ListItemText>
                  Priorität erhöhen
                </ListItemText>
              </MenuItem>
            ) : null}
            {decreasePriority ? (
              <MenuItem onClick={() => {
                closeMenu();
                decreasePriority(id);
              }}>
                <ListItemIcon>
                  <KeyboardArrowDown fontSize="small"/>
                </ListItemIcon>
                <ListItemText>
                  Priorität reduzieren
                </ListItemText>
              </MenuItem>
            ) : null}
            {(increasePriority || decreasePriority) && (setImportLookupTable || remove) ? (
              <Divider/>
            ) : null}
            {setImportLookupTable ? (
              <MenuItem onClick={() => {
                closeMenu();
                setImportLookupTable(id);
              }}>
                <ListItemIcon>
                  <ImportExport fontSize="small"/>
                </ListItemIcon>
                <ListItemText>
                  Als Datenbank für neue Musikproduktionen festlegen
                </ListItemText>
              </MenuItem>
            ) : null}
            {remove ? (
              <MenuItem onClick={() => {
                closeMenu();
                remove(id);
              }}>
                <ListItemIcon>
                  <Delete fontSize="small"/>
                </ListItemIcon>
                <ListItemText>
                  Datenbank aus Programm entfernen
                </ListItemText>
              </MenuItem>
            ) : null}
          </Menu>
        </>
      ) : null}
    </ListItem>
  );
}

function AddLookupTableDialog({
  lookupTables,
  onClose,
  open
}) {
  const selectedOrganizationid = useSelector(getSelectedOrganizationId);

  const handleClose = () => {
    onClose(null);
  };

  const handleListItemClick = (value) => {
    onClose(value);
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>Datenbank wählen</DialogTitle>
      <List sx={{pt: 0}}>
        {lookupTables?.map((id) => (
          <LookupTable
            key={id}
            id={id}
            organizationId={selectedOrganizationid}
            button
            onClick={() => handleListItemClick(id)}
          />
        ))}
      </List>
    </Dialog>
  );
}

function AddLookupTableButton({
  lookupTables,
  addLookupTable
}) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
    if (value) {
      addLookupTable(value);
    }
  };

  return (
    <>
      <Chip
        icon={<Add/>}
        variant="outlined"
        color="primary"
        clickable
        label="weitere Datenbank verwenden"
        onClick={handleClickOpen}
      />
      <AddLookupTableDialog
        lookupTables={lookupTables}
        open={open}
        onClose={handleClose}
      />
    </>
  );
}

function ProgrammeFormContent({
  data,
  onClose
}) {
  const {
    watch,
    setValue
  } = useFormContext();

  const {
    id,
    organization: organizationId,
    gemagvl4_stations,
    is_active: isActive,
  } = useSelector(getStationGetter)(data?.id);

  const {
    lookup_tables: allLookupTables,
  } = useSelector(getOrganizationGetter)(organizationId);

  useEffect(async () => {
    if (!id || !organizationId) {
      return;
    }

    try {
      const result = await axios.get(`/api/sendemeldung/organizations/${organizationId}/stations/${id}/suggest_merges/`);

      const {merge_candidates} = result.data;

      setValue('merge_candidates', merge_candidates);
    } catch (e) {
    }
  }, [id, organizationId]);

  const mergeCandidates = watch('merge_candidates');

  const [isMerging, setIsMerging] = React.useState(false);

  const entityApi = useEntityApi(STATION);
  const performMerge = async ({
    id,
    mergeId
  }) => {
    setIsMerging(true);
    try {
      const response = await entityApi.post(
        `/api/sendemeldung/organizations/${organizationId}/stations/${id}/merge/`,
        {mergeId},
      );
      onClose();
    } catch (e) {
      console.error(e);
    }
    setIsMerging(false);
  };

  const reportJingles = watch('report_jingles');

  const lookupTables = watch('lookup_tables');
  const importLookupTable = watch('import_lookup_table');

  const increasePriority = useCallback((id) => {
    const index = lookupTables?.indexOf(id);
    if (index <= 0) {
      return;
    }

    const newLookupTables = lookupTables?.slice(0, index - 1)
      .concat(lookupTables?.[index])
      .concat(lookupTables?.[index - 1])
      .concat(lookupTables?.slice(index + 1));
    setValue('lookup_tables', newLookupTables);
  }, [lookupTables]);

  const decreasePriority = useCallback((id) => {
    const index = lookupTables?.indexOf(id);
    if (index === -1 || index >= lookupTables?.length - 1) {
      return;
    }

    const newLookupTables = lookupTables?.slice(0, index)
      .concat(lookupTables?.[index + 1])
      .concat(lookupTables?.[index])
      .concat(lookupTables?.slice(index + 2));
    setValue('lookup_tables', newLookupTables);
  }, [lookupTables]);

  const removeLookupTable = useCallback((id) => {
    const index = lookupTables?.indexOf(id);
    if (index === -1) {
      return;
    }

    const newLookupTables = lookupTables?.slice(0, index)
      .concat(lookupTables?.slice(index + 1));
    setValue('lookup_tables', newLookupTables);
  }, [lookupTables]);

  const unusedLookupTables = allLookupTables?.filter((id) => !lookupTables?.includes(id));

  const addLookupTable = useCallback((id) => {
    const index = lookupTables?.indexOf(id);
    if (index !== -1) {
      return;
    }

    const newLookupTables = lookupTables?.concat([id]);
    setValue('lookup_tables', newLookupTables);
  }, [lookupTables]);

  return (
    <DialogContent style={{whiteSpace: 'normal'}}>
      {!data?.id ? (
        <Box mb={2}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Alert severity="info">
                <AlertTitle>Hinweis</AlertTitle>
                <p>In der Regel ist es nicht erforderlich, Programme manuell anzulegen.</p>
                <p>
                  Laden Sie stattdessen einfach GEMAGVL4-Sendemeldungen hoch.
                  Ihre Programme werden dabei <strong>automatisch</strong> eingerichtet.
                </p>
              </Alert>
            </Grid>
          </Grid>
        </Box>
      ) : null}
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <YupField name="name"/>
        </Grid>
        <Grid item xs={12}>
          <YupField name="gemagvl_sender_prg_id"/>
        </Grid>
        {gemagvl4_stations?.length > 0 ? (
          <Grid item xs={12}>
            <Card mt={2}>
              <CardContent>
                <Typography color="textSecondary" gutterBottom>
                  Einstellungen für GEMAGVL4-Import
                </Typography>
                <Typography gutterBottom>
                  Beim Import aus GEMAGVL4 werden diesem Programm alle Ausstrahlungen mit den folgenden Daten
                  zugeordnet:
                </Typography>
                {gemagvl4_stations?.map((gemagvl4StationId, i) => (
                  <GEMAGVL4Station key={gemagvl4StationId || i} id={gemagvl4StationId}/>
                ))}
              </CardContent>
            </Card>
          </Grid>
        ) : null}
        {data?.id ? (
          <Grid item xs={12}>
            <Card mt={2}>
              <CardContent>
                <Typography color="textSecondary" gutterBottom>
                  Einstellungen für Plausibilitätsprüfungen
                </Typography>
                <Grid item xs={12}>
                  <YupField name="max_overlapping_seconds"/>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        ) : null}

        {data?.id ? (
          <Grid item xs={12}>
            <Card mt={2}>
              <CardContent>
                <Typography color="textSecondary" gutterBottom>
                  Meldezeiträume &amp; Fristerinnerungen
                </Typography>
                {isActive ? (
                  <Grid item xs={12}>
                    <YupField name="report_reminders"/>
                  </Grid>
                ) : null}
                <Grid item xs={12}>
                  <YupField name="required_daily_standard_musik_dauer"/>
                </Grid>
                <Grid item xs={12}>
                  <YupField name="report_jingles"/>
                </Grid>
                {reportJingles ? (
                  <Grid item xs={12}>
                    <YupField name="required_daily_jingle_musik_dauer"/>
                  </Grid>
                ) : null}
              </CardContent>
            </Card>
          </Grid>
        ) : null}

        {data?.id ? (
          <Grid item xs={12}>
            <Card mt={2}>
              <CardContent>
                <Typography color="textSecondary" gutterBottom>
                  Verwendete Musikdatenbanken
                </Typography>
                <List>
                  {lookupTables?.map((lookupTableId, i) => (
                    <LookupTable
                      key={lookupTableId || i}
                      order={i + 1}
                      id={lookupTableId}
                      organizationId={organizationId}
                      isImportLookupTable={lookupTableId === importLookupTable}
                      increasePriority={i === 0 ? undefined : increasePriority}
                      decreasePriority={i === lookupTables?.length - 1 ? undefined : decreasePriority}
                      remove={lookupTableId === importLookupTable ? undefined : removeLookupTable}
                      setImportLookupTable={(id) => setValue('import_lookup_table', id)}
                    />
                  ))}
                </List>
                {unusedLookupTables?.length > 0 ? (
                  <Box sx={{textAlign: 'center'}} pb={1}>
                    <AddLookupTableButton
                      lookupTables={unusedLookupTables}
                      addLookupTable={addLookupTable}
                    />
                  </Box>
                ) : null}
                {lookupTables?.length > 1 ? (
                  <Box pt={1}>
                    <Alert severity="info">
                      <Typography gutterBottom>
                        Falls eine passende Musikproduktion in mehreren Datenbanken vorhanden ist, wird beim Import
                        von GEMAGVL4-Meldungen die Musikproduktion aus der Datenbank verwendet, die die höchste
                        Priorität hat (d. h. weiter oben in der Liste steht).
                      </Typography>
                      <Typography>
                        Neue Musikproduktionen, die bislang in keiner der Datenbanken vorhanden sind, werden in die
                        dafür festgelegte Datenbank geschrieben.
                      </Typography>
                    </Alert>
                  </Box>
                ) : null}
              </CardContent>
            </Card>
          </Grid>
        ) : null}

        {isMerging ? (
          <Grid item xs={12}>
            <CircularProgress size="1rem" color="inherit"/>
          </Grid>
        ) : mergeCandidates?.length > 0 ? (
          <Grid item xs={12}>
            <Typography color="textSecondary" gutterBottom>
              Programme zusammenführen?
            </Typography>
            {mergeCandidates?.map((candidateId, i) => (
              <MergeCandidate
                key={candidateId || i}
                id={candidateId}
                confirm={() => performMerge({
                  id,
                  mergeId: candidateId
                })}
              />
            ))}
          </Grid>
        ) : null}
      </Grid>
    </DialogContent>
  );
}

export function ProgrammeForm({
  data,
  onClose,
  ...props
}) {
  const {
    can_delete: canDelete,
  } = useSelector(getStationGetter)(data?.id);

  const selectedOrganization = useSelector(getSelectedOrganization);

  const entityApi = useEntityApi(STATION);

  const saveStation = async (validatedData) => {
    const {
      organization,
      ...station
    } = validatedData;

    const stationUuid = station?.id;
    if (stationUuid) {
      await entityApi.patch(
        `/api/sendemeldung/organizations/${organization}/stations/${stationUuid}/`,
        validatedData,
      );
    } else {
      await entityApi.post(
        `/api/sendemeldung/organizations/${organization}/stations/`,
        validatedData,
        {
          createEntities: true,
          organization
        },
      );
    }

    // TODO: Generalize save mechanism.
  };

  return (
    <EntityFormDialog
      title={data?.id ? "Programm bearbeiten" : "Programm anlegen"}
      entityType="stations"
      baseUrl={`/api/sendemeldung/organizations/${selectedOrganization?.id}/stations/`}
      open={!!data}
      data={data}
      onClose={onClose}
      submit={saveStation}
      allowDelete={canDelete}
      deleteConfirmation="Programm wirklich löschen?"
      deleteCaption="Programm löschen"
      schema={STATION_SCHEMA}
      onClick={(event) => event.stopPropagation()}
      {...props}
    >
      <ProgrammeFormContent data={data} onClose={onClose}/>
    </EntityFormDialog>
  );
}
