import {useSelector} from "react-redux";
import {
  getGEMAGVLXMLAusstrahlungGetter,
  getGEMAGVLXMLLieferungGetter,
  getOrgMusicWorkGetter,
  getTodoTaskGetter
} from "src/features/entity";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Badge,
  Box, Button,
  Chip,
  Grid,
  IconButton,
  Tooltip,
  Typography
} from "@mui/material";
import React, {useCallback} from "react";
import dateFormat from "dateformat";
import { Skeleton } from '@mui/material';
import {Edit, ExpandMore, HourglassEmpty, MusicNote, Timelapse, VolumeUp, Warning} from "@mui/icons-material";
import makeStyles from '@mui/styles/makeStyles';
import "src/packages/dateformat-german";
import ProgrammeChip from "src/components/entities/programme/ProgrammeChip";
import {TodoTasks} from "src/components/entities/todotask/TodoTasks";
import {useEntityObserver} from "src/features/entity/entity-hooks";
import GemaGVLXMLRow from "src/components/entities/gemagvlxml/GemaGVLXMLRow";
import {parseISO} from "date-fns";
import Alert from '@mui/material/Alert';
import MusicWorkSummary from "src/components/entities/musicwork/components/MusicWorkSummary";
import Duration from "src/packages/Duration";
import {useHasPermissions} from "src/features/dashboard/dashboard-hooks";
import {updateQueryString} from "src/features/ui/listing/listing-hooks";

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  icon: {
    flex: 0,
    paddingRight: theme.spacing(2),
    minWidth: 48,
    color: theme.palette.action.active,
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '50.0%',
    flexShrink: 0,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  heading2: {
    fontSize: theme.typography.pxToRem(15),
    //flexBasis: '100.0%',
    //flexShrink: 0,
    flex: 1,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    '& > p': {
      display: 'inline-block',
    },
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  secondaryHeading2: {
    paddingLeft: theme.spacing(2),
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  expandedAccordion: {
    // background: theme.palette.action.hover,
  },
}));

function TimeStamp({datum, disabled, onClick}) {
  return (
    <Tooltip title={datum}>
      <span>
        <Button
          sx={{
            minHeight: 0,
            minWidth: 0,
            padding: 0,
            "&.Mui-disabled": {
              color: "#000"
            }
          }}
          disabled={disabled}
          color="inherit"
          onClick={disabled ? undefined : onClick}
        >
          {dateFormat(datum, "HH:MM:ss")}
        </Button>
      </span>
    </Tooltip>
  );
}

export default function PlaylistItem(
  {
    id,
    previousId,
    onEdit,
    showStation,
    expansible = true,
    expanded,
    onChange,
    defaultExpanded,
    showReport,
    hideStatus,
    statusText,
    statusIcon,
    hideLink,
    filteredByTodoTaskId,
  },
) {
  const hasWritePermission = useHasPermissions({perm_write_reports: true});

  const classes = useStyles();

  useEntityObserver({type: 'gemagvlxml_ausstrahlung', id});

  const data = useSelector(getGEMAGVLXMLAusstrahlungGetter)(id);
  const {
    id: ausstrahlungId,
    lineno,
    gemagvlxml_lieferung,
    datum_von,
    datum_uhrzeit_von,
    datum_uhrzeit_bis,
    musik_dauer,
    org_music_work,
    created_at,
    todo_tasks,
    station,
    can_edit: canEdit,
    isDeleted,
  } = data;
  const showEdit = canEdit && hasWritePermission;

  useEntityObserver({type: 'org_music_work', id: org_music_work});

  const {
    organization,
    status,
  } = useSelector(getGEMAGVLXMLLieferungGetter)(gemagvlxml_lieferung);
  const isImporting = status === -100;

  const {
    nutzung_art_knz,
  } = useSelector(getOrgMusicWorkGetter)(org_music_work);

  const {
    datum_von: previous_datum_von,
    datum_uhrzeit_von: previous_datum_uhrzeit_von,
  } = useSelector(getGEMAGVLXMLAusstrahlungGetter)(previousId);

  let previousTzo, currentTzo = null;
  if (previous_datum_uhrzeit_von) {
    const dt = parseISO(previous_datum_uhrzeit_von);
    if (dt) {
      previousTzo = dt.getTimezoneOffset();
    }
  }
  if (datum_uhrzeit_von) {
    const dt = parseISO(datum_uhrzeit_von);
    if (dt) {
      currentTzo = dt.getTimezoneOffset();
    }
  }

  let datum_has_changed = (
    !previous_datum_von || (
      datum_von &&
      dateFormat(datum_von, "dd.mm.yyyy") !== dateFormat(previous_datum_von, "dd.mm.yyyy")
    )
  );

  let dst_has_changed = previousTzo && currentTzo && previousTzo !== currentTzo;

  const hasTodoTasks = todo_tasks?.length > 0;

  const getTodoTask = useSelector(getTodoTaskGetter);
  const hasCriticalTodoTasks = todo_tasks?.map(getTodoTask).filter(
    ({message}) => message?.severity === 'error',
  )?.length > 0;
  const hasWarningTodoTasks = todo_tasks?.map(getTodoTask).filter(
    ({message}) => message?.severity === 'warning',
  )?.length > 0;

  expansible = expansible && (hasTodoTasks || showReport);

  const [isExpanded, setIsExpanded] = React.useState(defaultExpanded !== undefined ? defaultExpanded : expanded);
  const simpleOnChange = useCallback((event, changedExpanded) => {
    setIsExpanded(changedExpanded);
  }, [setIsExpanded]);

  if (onChange) {
    expansible = true;
  }

  expanded = expanded || isExpanded;
  onChange = onChange || simpleOnChange;

  let icon = (nutzung_art_knz === 'JINGLE') ? (
    <VolumeUp color="action" fontSize="large"/>
  ) : (
    <MusicNote color="action" fontSize="large"/>
  );

  let formattedDatumVon = (datum_von === null ? (
    <span>Unbekanntes Datum</span>
  ) : datum_von ? (
    <span>{dateFormat(datum_von, "dddd, dd.mm.yyyy")}</span>
  ) : null);

  let formattedDatumUhrzeitVon = (datum_uhrzeit_von ? (
    <TimeStamp
      datum={datum_uhrzeit_von}
      disabled={hideLink}
      onClick={(evt) => updateQueryString({dateTimeFrom: datum_uhrzeit_von}) || evt.stopPropagation()}
    />
  ) : null);

  let formattedDatumUhrzeitBis = (datum_uhrzeit_bis ? (
    <TimeStamp
      datum={datum_uhrzeit_bis}
      disabled
    />
  ) : null);

  if (isDeleted) {
    return (
      <Box mt={2} mb={2}>
        <Alert variant="filled" color="error" severity="success">
          Diese Ausstrahlung wurde gelöscht.
        </Alert>
      </Box>
    );
  }

  return <>
    {datum_has_changed || dst_has_changed || datum_von === null ? (
      <Box mt={2} mb={2}>
        <Typography variant="h5">
          {formattedDatumVon}
          {dst_has_changed ? (
            <>
              {' '}
              &ndash;
              {' '}
              {currentTzo === -60 ? (
                "Winterzeit"
              ) : currentTzo === -120 ? (
                "Sommerzeit"
              ) : (
                "Zeitumstellung"
              )}
            </>
          ) : null}
        </Typography>
      </Box>
    ) : null}
    <Accordion expanded={expanded} onChange={expansible ? onChange : null} TransitionProps={{unmountOnExit: true}}>
      <AccordionSummary
        expandIcon={<ExpandMore visibility={expansible ? "visible" : "hidden"}/>}
        aria-controls={`${id}-content`}
        id={`${id}-header`}
      >
        <Grid container style={{alignItems: 'center'}}>
          <Grid item container xs={6} style={{alignItems: 'center'}}>
            <div className={classes.icon}>
              {!ausstrahlungId ? (
                <Skeleton variant="circular">
                  {icon}
                </Skeleton>
              ) : hasTodoTasks ? (
                <Badge color="primary" badgeContent={todo_tasks?.length}>
                  {icon}
                </Badge>
              ) : (
                icon
              )}
            </div>
            <div className={classes.heading2}>
              <Typography variant="inherit" className={classes.heading2}>
                {!ausstrahlungId ? (
                  <Skeleton/>
                ) : (
                  datum_uhrzeit_von && datum_uhrzeit_bis ? (
                    <>
                      {formattedDatumUhrzeitVon}
                      {' '}&ndash;{' '}
                      {formattedDatumUhrzeitBis} Uhr
                    </>
                  ) : !datum_uhrzeit_von ? (
                    <Chip
                      icon={<Warning/>}
                      component="span"
                      label="Uhrzeit fehlt"
                      color="primary"
                      size="small"
                    />
                  ) : !datum_uhrzeit_bis ? (
                    <>
                      {formattedDatumUhrzeitVon}
                      {' '}&ndash;{' '}
                      <Chip
                        icon={<Warning/>}
                        component="span"
                        label="Ausstrahlungsende fehlt"
                        color="primary"
                        size="small"
                      />
                    </>
                  ) : null
                )}
                {!ausstrahlungId ? (
                  null
                ) : (
                  <Typography variant="inherit" className={classes.secondaryHeading2} component="span">
                    <Chip
                      component="span"
                      icon={<Timelapse/>}
                      size="small"
                      label={(musik_dauer !== null ? (
                        <Duration value={musik_dauer}/>
                      ) : (
                        <Chip
                          icon={<Warning/>}
                          component="span"
                          label="Sendedauer fehlt"
                          color="primary"
                          size="small"
                          sx={{".MuiSvgIcon-root": {color: '#fff'}, color: '#fff'}}
                        />
                      ))}
                      sx={{bgcolor: 'white', ".MuiSvgIcon-root": {color: '#999999'}, color: '#999999'}}
                    />
                  </Typography>
                )}
                {showStation && station ? (
                  <Typography variant="inherit" className={classes.secondaryHeading2} component="span">
                    <ProgrammeChip id={station}/>
                  </Typography>
                ) : null}
                {' '}
              </Typography>
              <Box mt={-0.5}>
                {!ausstrahlungId ? (
                  <Skeleton variant="text"/>
                ) : (isImporting && org_music_work === null) ? (
                  <Skeleton variant="text"/>
                ) : (org_music_work === null) ? (
                  <Chip label="keine Musikproduktion zugeordnet"/>
                ) : (
                  <>
                    <MusicWorkSummary id={org_music_work} createLink={!hideLink}/>
                  </>
                )}
              </Box>
            </div>
          </Grid>
          <Grid item container xs={5} style={{alignItems: 'center'}}>
            {statusText !== undefined ? (
              <>
                <div className={classes.icon}>
                  {statusIcon}
                </div>
                <div className={classes.heading2}>
                  <Typography className={classes.secondaryHeading}>
                    {statusText}
                  </Typography>
                </div>
              </>
            ) : hideStatus ? (
              null
            ) : hasCriticalTodoTasks && !expanded ? (
              <>
                <div className={classes.icon}>
                  <HourglassEmpty color="primary" fontSize="large"/>
                </div>
                <div className={classes.heading2}>
                  <Typography className={classes.secondaryHeading}>
                    Korrektur erforderlich.
                  </Typography>
                </div>
              </>
            ) : hasWarningTodoTasks && !expanded ? (
              <>
                <div className={classes.icon}>
                  <HourglassEmpty color="primary" fontSize="large"/>
                </div>
                <div className={classes.heading2}>
                  <Typography className={classes.secondaryHeading}>
                    Prüfung erforderlich.
                  </Typography>
                </div>
              </>
            ) : hasTodoTasks && !expanded ? (
              <>
                <div className={classes.icon}>
                  <HourglassEmpty color="action" fontSize="large"/>
                </div>
                <div className={classes.heading2}>
                  <Typography className={classes.secondaryHeading}>
                    Hinweise liegen vor.
                  </Typography>
                </div>
              </>
            ) : null}
          </Grid>
          <Grid item container xs={1} style={{alignItems: 'right'}}>
            {showEdit && onEdit ? (
              <IconButton
                aria-label="edit"
                onClick={onEdit ? ((event) => {
                  event.stopPropagation();
                  return onEdit({
                    id,
                    gemagvlxml_lieferung,
                    organization,
                    datum_uhrzeit_von,
                    datum_uhrzeit_bis
                  });
                }) : null}
                size="large">
                <Edit/>
              </IconButton>
            ) : null}
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        <div style={{width: '100%'}}>
          {hasTodoTasks ? (
            <>
              <Typography variant="h6">
                Hinweise
              </Typography>
              <TodoTasks
                ids={todo_tasks}
                gemagvlxmlausstrahlungId={id}
                filteredByTodoTaskId={filteredByTodoTaskId}
              />
            </>
          ) : null}
          {showReport && hasTodoTasks ? (
            <Box my={2}/>
          ) : null}
          {showReport ? (
            <>
              <Typography variant="h6" gutterBottom>
                Zugehörige Lieferung
              </Typography>
              <GemaGVLXMLRow
                id={gemagvlxml_lieferung}
                defaultExpanded={false}
                expansible
              />
            </>
          ) : null}
        </div>
      </AccordionDetails>
    </Accordion>
  </>;
}
