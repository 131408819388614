import React, {useEffect} from "react";
import {Router} from "@gatsbyjs/reach-router";
import {useSelector} from "react-redux";
import {navigate} from "gatsby";
import ReportsPage from "src/components/dashboard_pages/reports";
import ReportPage from "src/components/dashboard_pages/report";
import UploadedFileReportsPage from "src/components/dashboard_pages/uploaded_file_reports";
import {isLoggedIn} from "src/features/session";
import DatabasePage from "src/components/dashboard_pages/database";
import BillingPage from "src/components/dashboard_pages/billing";
import DocumentsPage from "src/components/dashboard_pages/documents";
import NotificationsPage from "src/components/dashboard_pages/notifications";
import OrganizationPage from "src/components/dashboard_pages/organization";
import GEMAGVL4ImportPage from "src/components/dashboard_pages/gemagvl4";
import PeoplePage from "src/components/dashboard_pages/people";
import ProgrammesPage from "src/components/dashboard_pages/programmes";
import SecurityPage from "src/components/dashboard_pages/security";
import DashboardPage from "src/components/dashboard_pages";
import MusicWorkPage from "src/components/dashboard_pages/music_work";
import NotFoundPage from "src/components/dashboard_pages/404";

import {LocalizationProvider} from '@mui/x-date-pickers';
import {StyledEngineProvider} from '@mui/material/styles';
import {AdapterLuxon} from '@mui/x-date-pickers/AdapterLuxon';
import TimelinePage from "src/components/dashboard_pages/timeline";
import ChangelogPage from "src/components/dashboard_pages/changelog";
import ImportPage from "src/components/dashboard_pages/import";
import RefDataPage from "src/components/dashboard_pages/refdata";
import DatabaseRightsPage from "src/components/dashboard_pages/database_rights";
import KnownBugsPage from "src/components/dashboard_pages/known_bugs";
import HelpPage from "src/components/dashboard_pages/help";


const PrivateRoute = ({component: Component, location, ...rest}) => {
  const loggedIn = useSelector(isLoggedIn);

  const neitherLoggedInNorLoginPage = (!loggedIn && location.pathname !== `/dashboard/login`);

  useEffect(() => {
    if (neitherLoggedInNorLoginPage) {
      navigate("/");
    }
  }, [neitherLoggedInNorLoginPage]);

  if (neitherLoggedInNorLoginPage) {
    return null;
  }

  return (
    <Component {...rest} />
  );
}

const App = () => {
  return (
    <StyledEngineProvider injectFirst>
      <LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale="de">
        <Router basepath="/dashboard">
          <PrivateRoute path="/database" component={DatabasePage}/>
          <PrivateRoute path="/database/:id" component={DatabasePage}/>
          <PrivateRoute path="/database/:databaseId/rights" component={DatabaseRightsPage}/>
          <PrivateRoute path="/database/:databaseId/:id" component={MusicWorkPage}/>
          <PrivateRoute path="/database/:databaseId/:id/*" component={MusicWorkPage}/>
          <PrivateRoute path="/billing" component={BillingPage}/>
          <PrivateRoute path="/changelog" component={ChangelogPage}/>
          <PrivateRoute path="/documents" component={DocumentsPage}/>
          <PrivateRoute path="/documents/:id" component={DocumentsPage}/>
          <PrivateRoute path="/import" component={ImportPage}/>
          <PrivateRoute path="/notifications" component={NotificationsPage}/>
          <PrivateRoute path="/known_bugs" component={KnownBugsPage}/>
          <PrivateRoute path="/help" component={HelpPage}/>
          <PrivateRoute path="/organization" component={OrganizationPage}/>
          <PrivateRoute path="/gemagvl4" component={GEMAGVL4ImportPage}/>
          <PrivateRoute path="/people" component={PeoplePage}/>
          <PrivateRoute path="/programmes" component={ProgrammesPage}/>
          <PrivateRoute path="/reports" component={ReportsPage}/>
          <PrivateRoute path="/reports/:id/*" component={ReportPage}/>
          <PrivateRoute path="/security" component={SecurityPage}/>
          <PrivateRoute path="/timeline" component={TimelinePage}/>
          <PrivateRoute path="/refdata" component={RefDataPage}/>
          <PrivateRoute path="/refdata/*" component={RefDataPage}/>
          <PrivateRoute path="/uploads/:id/reports" component={UploadedFileReportsPage}/>
          <PrivateRoute path="/" component={DashboardPage}/>
          <PrivateRoute default component={NotFoundPage}/>
        </Router>
      </LocalizationProvider>
    </StyledEngineProvider>
  )
};

export default App;
