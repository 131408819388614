import {Button, DialogContent, Grid, Typography} from "@mui/material";
import React, {useCallback, useContext, useEffect, useRef, useState} from "react";
import {DOCUMENT_SCHEMA} from "src/features/dashboard/dashboard-validation";
import {YupField, YupFormProvider} from "src/packages/react-hook-form-mui-yup-helpers";
import {useEntityApi, useEntityDeleter, useEntityObserver} from "src/features/entity/entity-hooks";
import {DOCUMENT} from "src/api/api-schemas";
import {useSelector} from "react-redux";
import {getSelectedOrganizationId} from "src/features/dashboard";
import ReceiverOrganizationSuggestionsField
  from "src/components/entities/document/ReceiverOrganizationSuggestionsField";
import {useFormContext} from "react-hook-form";
import EntityFormDialog, {EntityFormDialogImplementation} from "src/features/entity/EntityFormDialog";
import {useYupForm} from "src/packages/react-hook-form-mui-yup-helpers/yup-form-utils";
import MuiTextField from "@mui/material/TextField";
import Dropzone from "src/components/dashboard/dropzone-view";
import {createUploadsFromFiles} from "src/features/dashboard/file-events";
import {getDocumentGetter} from "src/features/entity";
import DocumentRow, {FileDocument} from "src/components/entities/document/DocumentRow";

const DocumentFormContext = React.createContext(undefined);

const DocumentFormProvider = ({
  children,
  value
}) => {
  return (
    <DocumentFormContext.Provider value={value}>
      {children}
    </DocumentFormContext.Provider>
  );
};

function useDocumentFormContext() {
  return useContext(DocumentFormContext);
}

const delay = async t => await new Promise(r => setTimeout(r, t));

function useDocumentAttachments({
  saveDraft,
  submit,
  form
}) {
  const {
    handleSubmit,
    setValue,
    setError,
    watch,
  } = form;

  const id = watch('id');
  const idRef = useRef(id);
  idRef.current = id;

  useEntityObserver({
    type: 'document',
    id
  });

  const selectedOrganizationId = useSelector(getSelectedOrganizationId);

  const entityApi = useEntityApi(DOCUMENT);

  // const [state, dispatch] = useReducer(reducer, initialState);

  const [uploadChangeCounter, setUploadChangeCounter] = useState(0);
  const uploadsInProgressRef = useRef({});

  const [isSubmitting, setIsSubmitting] = useState(false);
  const isSubmittingRef = useRef(isSubmitting);

  // const [uploadsInProgress, setUploadsInProgress] = useState(0);

  const ensureDraft = useCallback(async () => {
    if (!idRef.current) {
      await handleSubmit(async (...args) => {
        let result;
        try {
          result = await saveDraft(...args);
        } catch (e) {
          throw e;
        }
        return result;
      })();
    }
  }, [handleSubmit, saveDraft]);

  const addAttachment = useCallback(async ({
    internalId,
    name,
    size,
    blob
  }) => {
    uploadsInProgressRef.current[internalId] = {
      internalId,
      name,
      size,
    };

    uploadsInProgressRef.current[internalId].cancelUpload = () => {
      if (!uploadsInProgressRef.current[internalId].failed) {
        return false;
      }

      delete uploadsInProgressRef.current[internalId];
      setUploadChangeCounter(uploadChangeCounter => uploadChangeCounter + 1);
    };

    setUploadChangeCounter(uploadChangeCounter => uploadChangeCounter + 1);

    // while (!isSubmittingRef.current) {
    //   await delay(100);
    // }

    try {
      await ensureDraft();
    } catch (e) {
      console.error("draft not saved");
      uploadsInProgressRef.current[internalId].failed = true;
      setUploadChangeCounter(uploadChangeCounter => uploadChangeCounter + 1);
      return;
    }

    const id = idRef.current;

    let data = new FormData();
    data.append('child_of', id);
    data.append('name', name);
    data.append('file', blob);

    let success = false;
    for (let i = 0; i < 4; i++) {
      try {
        const response = await entityApi.post(
          `/api/sendemeldung/organizations/${selectedOrganizationId}/documents/drafts/`,
          data,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          }
        );
        if (response?.data?.id) {
          success = true;
          break;
        } else {
          await delay(i * 1000);
        }
      } catch (e) {
        console.error(e);
        await delay(i * 1000);
      }
    }

    if (success) {
      setUploadChangeCounter(uploadChangeCounter => uploadChangeCounter + 1);
      delete uploadsInProgressRef.current[internalId];
    } else {
      // TODO: handle errors
      uploadsInProgressRef.current[internalId].failed = true;
      setUploadChangeCounter(uploadChangeCounter => uploadChangeCounter + 1);
    }
  }, [uploadsInProgressRef, isSubmittingRef, ensureDraft, selectedOrganizationId, entityApi]);

  const newSubmit = useCallback(async (validatedData) => {
    if (isSubmittingRef.current) {
      console.warn("already submitting");
      return false;
    }

    setIsSubmitting(true);
    isSubmittingRef.current = true;

    if (Object.keys(uploadsInProgressRef.current).length > 0) {
      // We need a draft first.
      try {
        await ensureDraft();
      } catch (e) {
        console.error(e);

        setIsSubmitting(false);
        isSubmittingRef.current = false;

        setError('submit', {
          type: 'generic',
          message: "Entwurf konnte nicht gespeichert werden."
        });
        // TODO: Render actual error message in form.

        return false;
      }
    }

    // Wait for uploads to finish.
    while (Object.keys(uploadsInProgressRef.current).length > 0) {
      await delay(100);
    }

    // Make sure to include the current id to avoid duplicates.
    const id = idRef.current;
    if (id) {
      validatedData = {id, ...validatedData};
    }

    const result = await submit(validatedData);

    // Reset isSubmitting unless already reset due to unmount.
    if (isSubmittingRef.current) {
      setIsSubmitting(false);
      isSubmittingRef.current = false;
    }

    return result;
  }, [submit, ensureDraft, uploadsInProgressRef]);

  // Reset isSubmitting immediately on unmount.
  useEffect(() => {
    return () => {
      if (isSubmittingRef.current) {
        setIsSubmitting(false);
        isSubmittingRef.current = false;
      }
    };
  });

  return [addAttachment, newSubmit, uploadsInProgressRef.current];
}

function DocumentFormDialogContent({data}) {
  const {
    handleSubmit,
    watch,
    setFocus,
  } = useFormContext();

  const id = watch('id');

  const selectedOrganizationId = useSelector(getSelectedOrganizationId);

  useEntityObserver({
    type: 'document',
    id
  });

  const getDocument = useSelector(getDocumentGetter);

  const {children} = getDocument(id);

  const {
    addAttachment,
    uploadsInProgress
  } = useDocumentFormContext();

  const {
    deleteEntity,
    deletingUuids
  } = useEntityDeleter({
    entityType: 'documents',
    baseUrl: `/api/sendemeldung/organizations/${selectedOrganizationId}/documents/drafts/`,
  });

  const hasUploadsInProgress = Object.keys(uploadsInProgress)?.length > 0;

  const hasAttachments = hasUploadsInProgress || children?.length > 0;

  const autoSelectTriggered = useRef();

  useEffect(() => {
    if (data?.description) {
      autoSelectTriggered.current = true;
      setFocus("message");
    }
  }, [setFocus]);

  return (
    <DialogContent>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <YupField
            name="organization"
            Component={ReceiverOrganizationSuggestionsField}
            TextFieldComponent={MuiTextField}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12}>
          <YupField name="description" variant="outlined"/>
        </Grid>
        <Grid item xs={12}>
          <YupField
            name="message"
            variant="outlined"
            onSelect={(e) => {
              // Force caret to be at beginning on autoselect.
              if (!autoSelectTriggered.current) {
                return;
              }
              autoSelectTriggered.current = false;
              e.target.setSelectionRange(0, 0);
            }}
          />
        </Grid>
      </Grid>

      {hasAttachments ? (
        <>
          <br/>
          <Typography variant="h6">Anhänge</Typography>
          {hasUploadsInProgress ? (
            <>
              {Object.values(uploadsInProgress)
                ?.map(({
                  internalId,
                  name,
                  cancelUpload,
                  failed,
                  size
                }, i) => (
                  <FileDocument
                    key={internalId || i}
                    id={internalId}
                    name={name}
                    size={size}
                    deleteDocument={failed ? cancelUpload : null}
                    isUploading
                    failed={failed}
                  />
                  // <p key={internalId || i}>
                  //   {name}
                  // </p>
                ))}
            </>
          ) : null}

          {children?.map((uuid, i) => (
            <Grid item xs={12} key={uuid || i}>
              <DocumentRow
                key={uuid || i}
                id={uuid}
                organizationId={selectedOrganizationId}
                deleteDocument={(id) => confirm("Anhang wirklich löschen?") && deleteEntity(id)}
                deletedMessage="Dieser Anhang wurde gelöscht."
                hideTimestamp
              />
            </Grid>
          ))}
        </>
      ) : null}

      <br/>

      <Dropzone
        uploadFiles={files => createUploadsFromFiles({files}, addAttachment)}
      >
        <p>bitte Anhänge hier hineinziehen</p>
        <p>oder</p>
      </Dropzone>
    </DialogContent>
  );
}

function DocumentFormContainer({
  schema,
  data,
  errors,
  submit: baseSubmit,
  children,
  saveDocument
}) {
  // Wrap submit function so that we can update it inside this closure.
  let wrappedSubmit = baseSubmit;
  const submitWrapper = async (...args) => await wrappedSubmit(...args);

  // Instantiate form, using the previously created submit wrapper.
  const {
    form,
    submitAction
  } = useYupForm({
    schema,
    data,
    errors,
    submit: submitWrapper
  });

  // Define document-specific functions.
  const {
    setValue,
    reset
  } = form;

  const saveDraft = async (data, e) => {
    const id = await saveDocument(data);
    if (id) {
      setValue('id', id);
      reset({
        ...data,
        id
      });
    } else {
      reset(data);
    }
  };

  const [addAttachment, newSubmit, uploadsInProgress] = useDocumentAttachments({
    saveDraft,
    submit: baseSubmit,
    form,
  });

  // Update wrappedSubmit inside this closure so that it is called by the submit function associated to the form.
  wrappedSubmit = newSubmit;

  return (
    <YupFormProvider schema={schema} form={form}>
      <form onSubmit={submitAction}>
        <DocumentFormProvider value={{
          addAttachment,
          saveDraft,
          uploadsInProgress
        }}>
          {children}
        </DocumentFormProvider>
      </form>
    </YupFormProvider>
  );
}

function DocumentFormDialogImplementationComponent({...props}) {
  const {
    saveDraft,
  } = useDocumentFormContext();
  const {
    formState: {
      isSubmitting,
      isDirty
    },
    handleSubmit,
    watch,
    setError,
  } = useFormContext();

  const id = watch('id');

  return (
    <EntityFormDialogImplementation
      {...props}
      additionalButtons={(isDirty || id) ? (
        <>
          <Button
            color="inherit"
            onClick={handleSubmit(async (...args) => {
              try {
                return await saveDraft(...args);
              } catch (e) {
                console.error(e);
                setError('saveDraft', "Entwurf konnte nicht gespeichert werden.");
              }
            })}
            disabled={isSubmitting || !saveDraft || !isDirty}
          >
            {isDirty ? "Entwurf speichern" : "Entwurf gespeichert"}
          </Button>
        </>
      ) : null}
    />
  );
}

export default function DocumentForm(
  {
    data,
    onClose,
    ...props
  }
) {
  const selectedOrganizationId = useSelector(getSelectedOrganizationId);

  const entityApi = useEntityApi(DOCUMENT);

  const saveDocument = async (validatedData) => {
    const {id} = validatedData;

    if (id) {
      await entityApi.patch(
        `/api/sendemeldung/organizations/${selectedOrganizationId}/documents/drafts/${id}/`,
        validatedData,
      );
      return id;
    } else {
      const response = await entityApi.post(
        `/api/sendemeldung/organizations/${selectedOrganizationId}/documents/drafts/`,
        validatedData,
        {
          createEntities: true,
          organization: selectedOrganizationId,
        },
      );
      return response?.data?.id;
    }
  };

  return (
    <EntityFormDialog
      id="document"
      entityType="documents"
      baseUrl={`/api/sendemeldung/organizations/${selectedOrganizationId}/documents/drafts/`}
      title={data?.id ? "Nachricht bearbeiten" : "Nachricht verfassen"}
      open={!!data}
      data={data}
      onClose={onClose}
      submit={(data) => saveDocument({
        ...data,
        status: 200,
      })}
      allowDelete
      deleteConfirmation="Nachricht wirklich löschen?"
      deleteCaption="Entwurf löschen"
      schema={DOCUMENT_SCHEMA}
      saveCaption="Nachricht versenden"
      cancelCaption="Schließen"
      maxWidth="xl"
      ContainerComponent={DocumentFormContainer}
      containerComponentProps={{
        saveDocument,
      }}
      DialogImplementationComponent={DocumentFormDialogImplementationComponent}
      {...props}
    >
      <DocumentFormDialogContent data={data}/>
    </EntityFormDialog>
  );
}
