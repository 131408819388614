import * as React from 'react';
import {useEffect, useRef, useState} from 'react';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import {
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  useScrollTrigger,
  useTheme
} from "@mui/material";
import {Transition} from 'react-transition-group';
import {Pagination} from "src/components/paginator";
import {AdminPanelSettings, Check, GetApp, Menu as MenuIcon, Search} from "@mui/icons-material";
import {getListing} from "src/features/ui/listing";
import {useSelector} from "react-redux";
import {ExportDialog} from "src/components/entities/musicwork/ExportDialog";
import {navigate} from "gatsby";

const HideOnScroll = ({
  offset = 80,
  children
}) => {
  const trigger = useScrollTrigger();
  const theme = useTheme();
  return (
    <Transition
      appear={false}
      in={!trigger}
      timeout={0}
      onExited={(node) => {
        const {
          top,
          height
        } = node.getBoundingClientRect();
        const transform = `translateY(-${top + height - offset}px)`;
        node.style.transform = transform;
        node.style.webkitTransform = transform;

        const options = {
          duration: theme.transitions.duration.leavingScreen,
          easing: theme.transitions.easing.easeOut,
        };
        node.style.transition = theme.transitions.create(
          ['transform'],
          options
        );
        node.style.webkitTransition = theme.transitions.create(
          ['-webkit-transform'],
          options
        );
      }}
      onEntered={(node) => {
        node.style.transform = 'none';
        node.style.webkitTransform = 'none';

        const options = {
          duration: theme.transitions.duration.enteringScreen,
          easing: theme.transitions.easing.easeIn,
        };
        node.style.transition = theme.transitions.create(
          ['transform'],
          options
        );
        node.style.webkitTransition = theme.transitions.create(
          ['-webkit-transform'],
          options
        );
      }}
    >
      {children}
    </Transition>
  );
};

// Hook
function useOnScreen(ref, rootMargin = "0px") {
  // State and setter for storing whether element is visible
  const [isIntersecting, setIntersecting] = useState(false);
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        // Update our state when observer callback fires
        setIntersecting(entry.isIntersecting);
      },
      {
        rootMargin,
      }
    );
    const refCurrent = ref.current;
    if (refCurrent) {
      observer.observe(refCurrent);
    }
    return () => {
      if (refCurrent) {
        observer.unobserve(refCurrent);
      }
    };
  }, []); // Empty array ensures that effect is only run on mount and unmount
  return isIntersecting;
}

const PAGE_SIZES = [10, 25, 50, 100];

export default function PageToolbar({
  title,
  searchField,
  extraField,
  isAtTop,
  hidePagination = false,
  paginationField,
  PaginationComponent = Pagination,
  paginationProps,
  exportUrl,
  manageRightsUrl,
  exportIsFiltered,
}) {
  // const trigger = useScrollTrigger({
  //   disableHysteresis: true,
  //   threshold: 0,
  // });

  const ref = useRef();

  const onScreen = useOnScreen(ref, isAtTop ? "-151px" : "-63px");

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [exportOpen, setExportOpen] = React.useState(false);

  const {
    listingId,
    setPageSize
  } = paginationProps || {};
  const {pageSize} = useSelector(getListing)(listingId);

  const toolbar = (
    <Box boxShadow={(!onScreen && ref.current) ? 4 : 0} mb={0.5}>
      <Toolbar sx={{
        pt: isAtTop ? 12 : 0.5,
        pb: 0.5,
        background: '#fafafa',
        height: isAtTop ? 152 : 64,
      }} position="relative">
        {title ? (
          <Box sx={{
            flex: 0,
          }}>
            <Typography
              variant="h6"
              noWrap
              component="div"
              // sx={{
              //   display: {
              //     xs: 'none',
              //     sm: 'block'
              //   }
              // }}
            >
              {title}
            </Typography>
          </Box>
        ) : null}
        <Box sx={{
          flex: 1,
          ml: title ? 2 : 0,
        }}>
          {searchField}
        </Box>
        {extraField ? (
          <Box sx={{
            flex: 0,
            ml: 2,
          }}>
            {extraField}
          </Box>
        ) : null}
        {(!hidePagination || exportUrl || manageRightsUrl) ? (
          <Box sx={{
            flex: 0,
            ml: 1,
          }}>
            <IconButton
              aria-label="menu"
              aria-controls={open ? 'listing-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              color="default"
              onClick={handleClick}
            >
              <MenuIcon/>
            </IconButton>
            <Menu
              id="listing-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
            >
              {!hidePagination ? (
                PAGE_SIZES.map((value) => (
                  <MenuItem
                    key={value}
                    onClick={() => {
                      setPageSize(value);
                      handleClose();
                    }}
                  >
                    <ListItemIcon>
                      {pageSize === value ? (<Check fontSize="small"/>) : null}
                    </ListItemIcon>
                    <ListItemText>
                      {value} Einträge pro Seite
                    </ListItemText>
                  </MenuItem>
                ))
              ) : null}
              {(!hidePagination && (exportUrl || manageRightsUrl)) ? (
                <Divider/>
              ) : null}
              {exportUrl ? (
                <MenuItem
                  onClick={() => {
                    setExportOpen(true);
                    handleClose();
                  }}
                >
                  <ListItemIcon>
                    <GetApp fontSize="small"/>
                  </ListItemIcon>
                  <ListItemText>
                    Daten exportieren...
                  </ListItemText>
                </MenuItem>
              ) : null}
              {exportUrl ? (
                <Divider/>
              ) : null}
              {exportUrl && manageRightsUrl ? (
                <MenuItem
                  onClick={() => {
                    navigate(manageRightsUrl);
                    handleClose();
                  }}
                >
                  <ListItemIcon>
                    <AdminPanelSettings fontSize="small"/>
                  </ListItemIcon>
                  <ListItemText>
                    Berechtigungen verwalten...
                  </ListItemText>
                </MenuItem>
              ) : null}
              {exportUrl && manageRightsUrl ? (
                <Divider/>
              ) : null}
              {exportUrl ? (
                <MenuItem
                  onClick={() => {
                    navigate("/dashboard/refdata/");
                    handleClose();
                  }}
                >
                  <ListItemIcon>
                    <Search fontSize="small"/>
                  </ListItemIcon>
                  <ListItemText>
                    Referenzdaten
                  </ListItemText>
                </MenuItem>
              ) : null}
            </Menu>
          </Box>
        ) : null}
        {!hidePagination ? (
          <Box sx={{
            flex: 0,
            ml: 0,
            '& > .MuiTablePagination-root': {
              minWidth: 'max-content',
            },
          }}>
            {paginationField ? (
              paginationField
            ) : (
              <PaginationComponent {...paginationProps}/>
            )}
          </Box>
        ) : null}
      </Toolbar>
      {exportUrl ? (
        <ExportDialog
          open={exportOpen}
          onClose={() => setExportOpen(false)}
          exportUrl={exportUrl}
          exportIsFiltered={exportIsFiltered}
        />
      ) : null}
    </Box>
  );

  return (
    <>
      <Box position="sticky" top={0} sx={{zIndex: 1099}} pb={1}>
        <Box
          mx={-3}
          mt={isAtTop ? -12 : 0}
        >
          {isAtTop ? (
            <HideOnScroll offset={60}>
              {toolbar}
            </HideOnScroll>
          ) : (
            toolbar
          )}
        </Box>
      </Box>
      <Box ref={ref}/>
    </>
  );
}
