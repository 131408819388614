import React, {useState} from "react";

import {useEntityObserver} from "src/features/entity/entity-hooks";
import {ResponsiveTimeRange, useColorScale} from "src/packages/extended-nivo-calendar";
import dateFormat from "dateformat";
import {useSelector} from "react-redux";
import {getGEMAGVLXMLLieferungGetter} from "src/features/entity";
import {addDays, addMonths} from "date-fns";
import {Box, List, ListItem, ListItemAvatar, ListItemText, Tooltip, Typography} from "@mui/material";
import {formatReadableDuration, parseDate} from "src/packages/date-utils";
import CircularProgressWithLabel from "src/components/core/CircularProgressWithLabel";
import SimpleDuration from "src/components/entities/gemagvlxml/SimpleDuration";
import {BoxLegendSvg} from "@nivo/legends";
import {Container, SvgWrapper} from "@nivo/core";
import {navigate} from "gatsby";
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 260,
    paddingRight: 20,
    backgroundColor: theme.palette.background.paper,
  },
}));

function StatsListItem(
  {
    enabled,
    selected,
    value,
    primary,
    secondary,
    onClick,
  }
) {
  return (
    <ListItem
      disabled={!enabled}
      selected={selected}
      button={!!(enabled && onClick)}
      onClick={onClick}
    >
      <ListItemAvatar>
        <CircularProgressWithLabel
          variant="determinate"
          value={value}
          showZero
        />
      </ListItemAvatar>
      <ListItemText
        primary={primary}
        secondary={secondary}
      />
    </ListItem>
  );
}

function StatsOverview(
  {
    id,
    standardSelected,
    jingleSelected,
    onStandardClick,
    onJingleClick,
  }
) {
  const classes = useStyles();

  useEntityObserver({type: 'gemagvlxml_lieferung', id});

  const getGEMAGVLXMLLieferung = useSelector(getGEMAGVLXMLLieferungGetter);
  const {stats} = getGEMAGVLXMLLieferung(id);

  const formattedStandardDuration = formatReadableDuration(stats?.coverage?.standard_musik_dauer);
  const formattedJingleDuration = formatReadableDuration(stats?.coverage?.jingle_musik_dauer);

  const onClickMakesSense = (
    standardSelected || jingleSelected || (
      !!stats?.coverage?.standard_musik_dauer && !!stats?.coverage?.jingle_musik_dauer
    )
  )

  return (
    <div className={classes.root}>
      <List component="nav">
        <StatsListItem
          enabled={!!stats?.coverage?.standard_musik_dauer || standardSelected}
          selected={standardSelected}
          onClick={onClickMakesSense ? onStandardClick : undefined}
          value={stats?.coverage?.standard_musik_dauer_percentage}
          primary="Musik"
          secondary={formattedStandardDuration}
        />
        <StatsListItem
          enabled={!!stats?.coverage?.jingle_musik_dauer || jingleSelected}
          selected={jingleSelected}
          onClick={onClickMakesSense ? onJingleClick : undefined}
          value={stats?.coverage?.jingle_musik_dauer_percentage}
          primary="Jingles"
          secondary={formattedJingleDuration}
        />
      </List>
    </div>
  );
}

const HeatmapTooltip = React.memo((({gemagvlxmlLieferungId, day, date, value}) => {
  const getGEMAGVLXMLLieferung = useSelector(getGEMAGVLXMLLieferungGetter);
  const {
    stations,
  } = getGEMAGVLXMLLieferung(gemagvlxmlLieferungId);

  return (
    <div>
      <Tooltip
        title={(
          <>
            {dateFormat(date, "dddd, d. mmmm yyyy")}
            <List>
              <ListItem>
                <ListItemAvatar>
                  <CircularProgressWithLabel textColor="inherit" variant="determinate"
                                             value={value / (86400 * (stations?.length || 1))}
                                             showZero/>
                </ListItemAvatar>
                <ListItemText
                  primary="Sendedauer"
                  secondary={(
                    <span style={{color: 'white'}}>
                    <SimpleDuration value={value}/>
                  </span>
                  )}
                />
              </ListItem>
            </List>
          </>
        )}
        open
        disableInteractive
        disableFocusListener
        disableHoverListener
        disableTouchListener
      >
        <div/>
      </Tooltip>
    </div>
  );
}));

function useTimeRanges({datum_von, datum_bis, maxMonths}) {
  const timeRanges = [];

  if (datum_von !== datum_bis) {
    let startDate = parseDate(datum_von);
    let endDate = parseDate(datum_bis);

    if (startDate.getDate() !== 1) {
      startDate.setDate(1);
    }

    if (endDate.getDate() !== 1 && addDays(endDate, 1).getDate() !== 1) {
      endDate = addDays(addMonths(endDate, 1).setDate(1), -1);
    }

    let currentDate = startDate;
    while (currentDate <= endDate) {
      const nextMonth = addMonths(currentDate, 1);
      timeRanges.push([currentDate, addDays(nextMonth, -1)]);
      currentDate = nextMonth;

      if (maxMonths && timeRanges.length >= maxMonths) {
        break;
      }
    }
  }

  return timeRanges;
}

function useData({stats, showAll, selectJingles}) {
  return Object.entries(stats?.coverage?.by_date || {}).map(([day, data]) => ({
    day,
    value: (showAll ? (
      data?.standard_musik_dauer + data?.jingle_musik_dauer
    ) : selectJingles ? (
      data?.jingle_musik_dauer
    ) : (
      data?.standard_musik_dauer
    )),
  }));
}

export default function GEMAGVLXMLStats({id, children, forceChildren}) {
  useEntityObserver({type: 'gemagvlxml_lieferung', id});

  const getGEMAGVLXMLLieferung = useSelector(getGEMAGVLXMLLieferungGetter);
  const {
    datum_von,
    datum_bis,
    stats,
    stations,
  } = getGEMAGVLXMLLieferung(id);

  const timeRanges = useTimeRanges({datum_von, datum_bis, maxMonths: 13});

  const [selectMusic, setSelectMusic] = useState(false);
  const toggleMusic = () => {
    setSelectMusic(!selectMusic);
  }
  const [selectJingles, setSelectJingles] = useState(false);
  const toggleJingles = () => {
    setSelectJingles(!selectJingles);
  }

  const showAll = (selectMusic && selectJingles) || (!selectMusic && !selectJingles);

  const data = useData({stats, showAll, selectJingles});

  const tooMuchScaleFn = useColorScale({
    data,
    minValue: 25 * 3600 * stations?.length,
    colors: [
      "#ff0000",
      "#cc0000",
    ],
  });

  const minExpectedMusikDauer = (showAll ? (
    stats?.coverage?.min_standard_musik_dauer + stats?.coverage?.min_jingle_musik_dauer
  ) : selectJingles ? (
    stats?.coverage?.min_jingle_musik_dauer
  ) : (
    stats?.coverage?.min_standard_musik_dauer
  ));

  const lessThanExpectedScaleFn = useColorScale({
    data: [],
    minValue: 0,
    maxValue: minExpectedMusikDauer,
    colors: [
      "#f8f6cc",
      "#fcf682",
      "#fff500",
    ],
  });

  const defaultRangeColorScaleFn = useColorScale({
    data: [],
    minValue: 2 * 3600 * stations?.length,
    maxValue: 25 * 3600 * stations?.length,
    colors: [
      "#dcfdae",
      "#d6fba7",
      "#d0f99f",
      "#caf798",
      "#c4f591",
      "#bef48a",
      "#b7f283",
      "#b1f07c",
      "#aaee76",
      "#a2ec6f",
      "#9bea68",
      "#93e861",
      "#8be65a",
      "#83e553",
      "#7ae34c",
      "#70e144",
      "#66df3c",
      "#5bdd34",
      "#4edb2c",
      "#3fd921",
      "#2bd715",
      "#00d500",
    ],
  });

  const tooltipFn = React.useCallback(({day, date, value}) => (
    <HeatmapTooltip
      gemagvlxmlLieferungId={id}
      day={day}
      date={date}
      value={value}
    />
  ), [id]);

  const colorScaleFn = React.useCallback((value) => {
    if (value <= 0) {
      return '#eeeeee';
    }

    if (value < minExpectedMusikDauer) {
      return lessThanExpectedScaleFn(value);
    }

    if (value > 25 * 3600 * stations?.length) {
      return tooMuchScaleFn(value);
    }

    return defaultRangeColorScaleFn(value);
  });

  return datum_von === datum_bis ? children : (
    <div>
      {forceChildren && children ? (
        <div style={{marginBottom: 20}}>
          {children}
        </div>
      ) : null}
      <div style={{width: '100%', height: '260px', position: 'relative'}}>
        <div style={{
          position: 'absolute',
          left: 0,
          right: 0,
          width: '100%',
          height: '280px',
          overflowX: 'auto',
          overflowY: 'hidden',
        }}>
          <div style={{width: `${timeRanges?.length * 240 + 240 + 280}px`, height: '260px', overflow: 'hidden'}}>
            <Box display="flex" flexDirection="row">
              <StatsOverview
                id={id}
                standardSelected={selectMusic}
                jingleSelected={selectJingles}
                onStandardClick={toggleMusic}
                onJingleClick={toggleJingles}
              />
              {timeRanges.map(([startDate, endDate], i) => (
                <div key={i} style={{height: '260px', width: '240px', marginLeft: 10}}>
                  <Typography align="center">{dateFormat(startDate, "mmmm yyyy")}</Typography>
                  <div style={{height: '240px'}}>
                    <ResponsiveTimeRange
                      data={data}
                      from={dateFormat(addDays(startDate, -1), "yyyy-mm-dd")}
                      to={dateFormat(endDate, "yyyy-mm-dd")}
                      emptyColor="#f8f8f8"
                      colorScale={colorScaleFn}
                      dayRadius={20}
                      daySpacing={5}
                      margin={{top: 0, right: 0, bottom: 0, left: 0}}
                      dayBorderWidth={2}
                      dayBorderColor="#ffffff"
                      weekdayTicks={i === 0 ? [0, 1, 2, 3, 4, 5, 6] : []}
                      weekdayLegendOffset={i === 0 ? 25 : 0}
                      monthLegend={() => null}
                      onClick={(e) => {
                        const day = e?.day;
                        if (!day || e?.value === undefined) {
                          return;
                        }
                        navigate(`/dashboard/reports/${id}/entries/?date=${day}`);
                      }}
                      tooltip={tooltipFn}
                    />
                  </div>
                </div>
              ))}
              <div style={{height: '260px', width: '240px', marginLeft: 10}}>
                <div style={{height: '240px'}}>
                  <Container>
                    <SvgWrapper width={200} height={240} margin={{top: 0, right: 0, left: 0, bottom: 0}}>
                      <BoxLegendSvg
                        containerWidth={200}
                        containerHeight={240}
                        anchor='right'
                        direction='column'
                        justify={false}
                        itemCount={1}
                        itemWidth={42}
                        itemHeight={36}
                        itemsSpacing={14}
                        itemDirection='right-to-left'
                        // translateX={-60}
                        // translateY={-60}
                        symbolSize={20}
                        theme={{background: '#ffffff'}}
                        data={[
                          {id: 1, label: 'zu hohe Sendedauer', color: '#ff0000'},
                          {id: 2, label: 'plausible Sendedauer', color: '#00d500'},
                          {id: 3, label: 'geringe Sendedauer', color: '#fff500'},
                          {id: 4, label: 'keine Ausstrahlungen', color: '#eeeeee'},
                        ]}
                      />
                    </SvgWrapper>
                  </Container>
                </div>
              </div>
            </Box>
          </div>
        </div>
      </div>
    </div>
  );
}
