import React, {useState} from "react";
import {useEntityObserver} from "src/features/entity/entity-hooks";
import {useSelector} from "react-redux";
import {getGEMAGVLXMLLieferungGetter} from "src/features/entity";
import {Button, Chip, ListItemIcon, ListItemText, MenuItem, Tooltip} from "@mui/material";
import Typography from "@mui/material/Typography";
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator
} from "@mui/lab";
import {
  AttachFile, Cancel,
  Check,
  Clear,
  HourglassEmpty,
  ImportExport,
  MarkChatRead,
  QuestionMark,
  Remove
} from "@mui/icons-material";
import {uploadedFiles} from "src/features/orm/selectors";
import {timelineOppositeContentClasses,} from '@mui/lab/TimelineOppositeContent';
import NaturalTime from "src/packages/natural-time-view";
import HrefButton from "src/packages/gatsby-mui-helpers/HrefButton";
import {useSessionTokenUrl} from "src/api/api-hooks";
import {capitalizeFirstLetter} from "src/packages/string-utils";
import {useHasPermissions} from "src/features/dashboard/dashboard-hooks";
import SimpleMoreMenu from "src/packages/SimpleMoreMenu";
import ReportCancelRequestFormDialog from "src/components/entities/gemagvlxml/ReportCancelRequestFormDialog";

function HistoryItem({
  icon,
  children,
  dateTime,
  isLast
}) {
  return (
    <TimelineItem>
      <TimelineOppositeContent mt={1}>
        <Typography
          color="textSecondary"
        >
          <NaturalTime date={dateTime}/>
        </Typography>
      </TimelineOppositeContent>

      <TimelineSeparator>
        <TimelineDot>
          {icon}
        </TimelineDot>

        {!isLast ? (
          <TimelineConnector/>
        ) : null}
      </TimelineSeparator>

      <TimelineContent mt={1}>
        {children}
      </TimelineContent>
    </TimelineItem>
  );
}

function UploadHistoryItem({id}) {
  useEntityObserver({
    type: 'uploaded_file',
    id
  });
  const uploadedFile = useSelector(state => uploadedFiles(state, id));

  const {
    id: uploadedFileId,
    name,
    uploaded_at,
  } = uploadedFile || {};

  return (
    <HistoryItem
      icon={<ImportExport/>}
      dateTime={uploaded_at}
      isLast
    >
      Sendemeldungen importiert aus
      {' '}
      <HrefButton
        href={`/dashboard/import/?filter=q%3D${id}`}
        //variant="outlined"
        color="default"
        size="small"
      >
        {name || "GEMAGVL4-Datei"}
      </HrefButton>
    </HistoryItem>
  );
}

function ExportHistoryItem({
  id,
  organization,
  lieferungId,
  name,
  updated_at,
  type: lieferungType,
  cancellation_declined_at,
  status,
  is_pre_approval_report: isPreApprovalReport,
  can_request_cancel: canRequestCancel,
}) {
  const hasSubmitPermission = useHasPermissions({perm_submit_reports: true});
  const showRequestCancel = canRequestCancel && hasSubmitPermission;

  const downloadUrl = useSessionTokenUrl(`/api/sendemeldung/organizations/${organization}/gemagvlxml_exports/${id}/blob/`);

  const lieferungName = lieferungType === 'A' ? (
    "ergänzende Lieferung"
  ) : lieferungType === 'K' ? (
    "Korrekturlieferung"
  ) : (
    "Lieferung"
  );

  const [cancelRequest, setCancelRequest] = useState(null);
  const cancellationFailed = !!cancellation_declined_at;

  let summary;

  switch (status) {
    case 'publishing':
      if (cancellationFailed) {
        summary = (
          <>
            {capitalizeFirstLetter(lieferungName)} weiterhin für GEMA/GVL freigegeben &ndash; die angefragte Stornierung war nicht mehr möglich
          </>
        );
      } else {
        summary = (
          <>
            {capitalizeFirstLetter(lieferungName)} für GEMA/GVL freigegeben
          </>
        );
      }

      break;

    case 'exported':
      summary = (
        <>
          {capitalizeFirstLetter(lieferungName)} für GEMA/GVL vorbereitet &ndash; warte auf Freigabe zur Übermittlung
        </>
      );
      break;

    case 'request_cancel':
      summary = (
        <>
          Stornierung angefordert für zuvor an GEMA/GVL übermittelte {lieferungName}
        </>
      );
      break;

    case 'cancelled':
      summary = (
        <>
          Zuvor an GEMA/GVL übermittelte {lieferungName} storniert
        </>
      );
      break;

    default:
      summary = (
        <>
          {capitalizeFirstLetter(lieferungName)} für GEMA/GVL hat Status: {status}
        </>
      );
      break;

  }

  return (
    <HistoryItem
      icon={(
        status === 'publishing' ? (
          <Check/>
        ) : status === 'exported' ? (
          <HourglassEmpty/>
        ) : status === 'cancelled' ? (
          <Clear/>
        ) : status === 'request_cancel' ? (
          <HourglassEmpty/>
        ) : (
          <QuestionMark/>
        )
      )}
      dateTime={updated_at}
    >
      {summary}

      {isPreApprovalReport ? (
        <>
          {' '}
          <Tooltip
            title={(
              <>
                Bevor die Verwertungsgesellschaften Produktivmeldungen im XML-Format annehmen, muss zunächst
                eine Beispiel-Sendemeldung bereitgestellt werden.
                Diese wird von GEMA / GVL technisch überprüft; im Erfolgsfall erhält Ihr Sendeunternehmen
                die entsprechende Freigabe.
              </>
            )}
          >
            <Chip label="Testlieferung"/>
          </Tooltip>
        </>
      ) : null}

      <Typography pt={1}>
        {downloadUrl ? (
          <Button
            href={downloadUrl}
            size="small"
            color="default"
            variant="outlined"
            startIcon={<AttachFile/>}
          >
            {name}
          </Button>
        ) : name}
        {showRequestCancel && ' '}
        {showRequestCancel ? (
          <SimpleMoreMenu id={id} size="normal">
            <MenuItem
              onClick={() => setCancelRequest({
                  organization,
                  id,
                  gemagvlxml_lieferung: lieferungId,
                })}
            >
              <ListItemIcon>
                <Cancel fontSize="small"/>
              </ListItemIcon>
              <ListItemText>
                {capitalizeFirstLetter(lieferungName)} stornieren
              </ListItemText>
            </MenuItem>
          </SimpleMoreMenu>
          // <Button
          //   href={downloadUrl}
          //   size="small"
          //   color="default"
          //   variant="text"
          //   startIcon={<AttachFile/>}
          // >
          //   Lieferung stornieren
          // </Button>
        ) : null}
      </Typography>

      {/*TODO: Delayed removal of dialog for fadeout transition.*/}
      {cancelRequest ? (
        <ReportCancelRequestFormDialog
          data={cancelRequest}
          onClose={() => setCancelRequest(null)}
        />
      ) : null}
    </HistoryItem>
  );
}

export function GEMAGVLXMLHistory({
  id,
  isVisible
}) {
  useEntityObserver({
    type: 'gemagvlxml_lieferung',
    id
  });

  const getGEMAGVLXMLLieferung = useSelector(getGEMAGVLXMLLieferungGetter);
  const {
    id: gemaGvlXmlLieferungId,
    upload,
    gemagvlxml_exports: xmlExports,
  } = getGEMAGVLXMLLieferung(id);

  return (
    <>
      <Timeline sx={{
        [`& .${timelineOppositeContentClasses.root}`]: {
          flex: 0.1,
        },
      }}>
        {xmlExports?.map(({
          id,
          ...xmlExport
        }) => (
          <ExportHistoryItem
            key={id}
            id={id}
            lieferungId={gemaGvlXmlLieferungId}
            {...xmlExport}
          />
        ))}

        {isVisible && upload ? (
          <UploadHistoryItem id={upload}/>
        ) : null}
      </Timeline>

      {/*{xmlExports?.length > 0 ? (*/}
      {/*  <Collapse in={true}>*/}
      {/*    <Box mt={2}>*/}
      {/*      <Typography component="h6" gutterBottom>*/}
      {/*        Erzeugte XML-Lieferungen*/}
      {/*      </Typography>*/}
      {/*      {xmlExports.map(({*/}
      {/*        id,*/}
      {/*        ...xmlExport*/}
      {/*      }) => (*/}
      {/*        <GEMAGVLXMLExport*/}
      {/*          key={id}*/}
      {/*          id={id}*/}
      {/*          {...xmlExport}*/}
      {/*        />*/}
      {/*      ))}*/}
      {/*    </Box>*/}
      {/*  </Collapse>*/}
      {/*) : null}*/}

      {/*<p>Die Sendemeldungen stammen aus einer hochgeladenen Datei.</p>*/}
      {/*{isVisible ? (*/}
      {/*  <UploadedFileRow id={upload} expansible variant="outlined" hideReportLinks/>*/}
      {/*) : null}*/}
    </>
  );
}
