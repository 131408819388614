import React from "react";
import {DashboardLayout} from "src/components/layout";
import {Box, Paper,} from "@mui/material";
import Typography from "@mui/material/Typography";
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles({
  paper: {
    padding: '6px 16px',
  },
});

export default function ChangelogPage() {
  const classes = useStyles();

  return (
    <DashboardLayout
      titlePrefix="Änderungsprotokoll"
      selectedPage="changelog"
    >
      <Box my={2}>
        <Typography variant="h6">
          Änderungsprotokoll
        </Typography>
      </Box>

      <Box my={2}>
        <Paper elevation={3} className={classes.paper}>
          <Box mt={2} mb={2}>
            <Typography variant="h5">
              Version 1.0.6
            </Typography>

            <ul>
              <li>Verbesserung der Referenzdatenzuordnungsvorschläge für Musikproduktionen mit fehlerhaften Komponisten
                (Interpreten, die als Platzhalter ins Komponist-Feld übernommen wurden, werden nun als solche erkannt)
              </li>
              <li>Technische Unterstützung für XML-Änderungsmeldungen hinzugefügt: Sobald alle technischen Tests
                abgeschlossen sind und die Funktion seitens der Verwertungsgesellschaften freigegeben ist, werden
                bereits übermittelte Sendemeldungen über die Funktion &bdquo;Meldung bearbeiten&ldquo; nachbearbeitet
                und Änderungen (etwa zwischenzeitlich ergänzte Metadaten) an die Verwertungsgesellschaften nachgereicht
                werden können.
              </li>
              <li>Hinweis auf fehlende Zeitangaben bei Jingle-Sammelmeldungen in Absprache mit den Verwertungsgesellschaften entfernt</li>
              <li>Verbesserungen im Umgang mit Sendemeldungen, die mit dem GEMA-Excel-Tool erstellt wurden</li>
              <li>Übersichtlichkeit des Dashboards erhöht</li>
              <li>Anzeige von Nachrichten im Kontaktbereich verbessert</li>
              <li>Automatische Bereinigung beim Import automatisch angelegter Programme, wenn die zugehörige
                Sendemeldung wieder gelöscht wird
              </li>
              <li>Diverse Verbesserungen beim GEMAGVL4-Import; Heuristik für Import syntaktisch fehlerhafter GEMAGVL4-Ausstrahlungen erweitert</li>
              <li>Fehlerbehebung: Direktlink von hochgeladener Datei zu enthaltenen Sendemeldungen war in einigen Fällen
                nicht sichtbar.
              </li>
              <li>Fehlerbehebung: In einigen Fällen wurde trotz deaktivierter Jingle-Meldungen für ein Programm auf
                fehlende Jingles hingewiesen.
              </li>
              <li>Fehlerbehebung: Darstellungsprobleme in Personenliste mit vielen Personen behoben.</li>
              <li>Fehlerbehebung: Probleme mit Formularfeldern zur Datums-/Zeiteingabe behoben (Änderung des
                Veröffentlichungsdatums einer erschienenen Tonaufnahme war nicht möglich).
              </li>
              <li>Verbesserte Performance</li>
              <li>Weitere Fehlerbehebungen und Verbesserungen der Nutzbarkeit</li>
            </ul>
          </Box>
        </Paper>
      </Box>

      <Box my={2}>
        <Paper elevation={3} className={classes.paper}>
          <Box mt={2} mb={2}>
            <Typography variant="h5">
              Version 1.0.5
            </Typography>

            <ul>
              <li>Verzögerung beim Versand von Passwort-zurücksetzen-Mails behoben</li>
              <li>Fehlerbehebung: Korrekturen negativer Sendedauern wurden beim Export nicht berücksichtigt</li>
              <li>Bedienelemente zur Anpassung von Zeitangaben verbessert</li>
            </ul>
          </Box>
        </Paper>
      </Box>

      <Box my={2}>
        <Paper elevation={3} className={classes.paper}>
          <Box mt={2} mb={2}>
            <Typography variant="h5">
              Version 1.0.4
            </Typography>

            <ul>
              <li>Fehlerbehebung: Prüfung auf überlappende Ausstrahlungen hat in einigen Situationen nicht richtig funktioniert</li>
            </ul>
          </Box>
        </Paper>
      </Box>

      <Box my={2}>
        <Paper elevation={3} className={classes.paper}>
          <Box mt={2} mb={2}>
            <Typography variant="h5">
              Version 1.0.3
            </Typography>

            <ul>
              <li>Verbesserte Kompatibilität zu spezifikationswidrigen GEMAGVL4-Dateien: Ausstrahlungszeitangaben der
                Form 02:00:00 - 02:119:59, die manche Sendesysteme im Zeitfenster der Zeitumstellung von Sommer- auf
                Winterzeit ausgeben, werden nun unterstützt.
              </li>
              <li>Fehler beim Musikdatenbankexport behoben</li>
              <li>Weitere kleinere Fehlerbehebungen</li>
              <li>Aktualisierung verwendeter Bibliotheken</li>
            </ul>
          </Box>
        </Paper>
      </Box>

      <Box my={2}>
        <Paper elevation={3} className={classes.paper}>
          <Box mt={2} mb={2}>
            <Typography variant="h5">
              Version 1.0.2
            </Typography>

            <ul>
              <li>Erweiterung der Referenzdatenzuordnungsvorschläge durch Berücksichtigung verbreiteter Alternativschreibweisen von Interpreten anhand der Musicbrainz-Datenbank sowie weitere Verbesserungen der Ähnlichkeitsmetrik</li>
              <li>Aufteilung der Interpretenangaben in einzelne Interpreten und Extraktion weiterer Interpreten (Featuring-Angaben) aus Titeln zur Verbesserung und Erweiterung der Referenzdatenzuordnungsvorschläge</li>
              <li>Verbesserung der Referenzdatenzuordnungsvorschläge durch Anpassung der Ähnlichkeitsmetrik für Komponisten-Schreibweisen: Bei Angabe &bdquo;B, A&ldquo; werden nun sowohl der Fall, dass A ein Vorname und B ein Nachname ist, als auch der Fall, dass A und B jeweils Nachnamen sind, berücksichtigt. Zudem wurde die Zuordnung von GVL-Referenzdatensätzen, für die keine Vornamen vorliegen, zu Musikproduktionen, bei denen diese Angabe vorhanden ist, verbessert.</li>
              <li>Weitere Erweiterung der Referenzdatenzuordnungsvorschläge durch verbesserten Titelvergleich sowie Berücksichtigung im GVL-Datensatz enthaltener Titel-Zusatzinformationen bei der Ähnlichkeitsberechnung</li>
              <li>Zuordnung von Referenzdatenvorschlägen bei abgeschnittenen Titeln und Interpretenangaben verbessert</li>
              <li>Referenzdatenvorschläge im Falle abgeschnittener ISRCs ermöglicht</li>
              <li>Verbesserte Identifier-Ähnlichkeitsberechnung</li>
              <li>Ergänzung fehlender Interpreten aus verknüpften Referenzdaten</li>
              <li>Hinweis auf fehlende Jingle-Meldungen innerhalb einer Lieferung deaktiviert, da Jingle-Anlieferung oftmals über separate Dateien erfolgt</li>
              <li>Entschärfung der Plausibilitätsprüfungen in Hinblick auf Überlappungen in Absprache mit GEMA/GVL: Überlappungen zwischen Jingles sowie zwischen Jingles und Standard-Ausstrahlungen führen fortan nicht mehr zu einem Hinweis; geprüft werden nur noch Überlappungen zwischen mehreren Standard-Ausstrahlungen.</li>
              <li>Entschärfung der Plausibilitätsprüfungen in Hinblick auf Interpret/Urheber-Angaben für Jingles in Absprache mit GEMA/GVL: Bei Jingles, die als Eigen- oder Auftragsproduktion gekennzeichnet sind, genügt fortan die Angabe eines Urhebers <i>oder</i> oder eines Interpreten.</li>
              <li>Berücksichtigung auch von Jingle-Ausstrahlungen bei Plausibilitätsprüfung auf Meldelücken</li>
              <li>Automatische Initialisierung der Struktur <i>erschienene Tonaufnahme</i> bei Auswahl der Musikherkunft <i>veröffentlichte Tonaufnahme</i> oder <i>Production Library Music</i> und automatische Entfernung einer zugewiesenen erschienenen Tonaufnahme ohne Inhalte bei Änderung zu einer anderen Musikherkunft</li>
              <li>Einführung einer zusätzlichen Plausibilitätsprüfung auf die Kombination aus Musikherkunft und etwaigen Angaben zu einer erschienenen Tonaufnahme</li>
              <li>Passwort-Reset via Webinterface ermöglicht</li>
              <li>Weitere Fehlerbehebungen und Verbesserungen der Benutzbarkeit</li>
            </ul>
          </Box>
        </Paper>
      </Box>

      <Box my={2}>
        <Paper elevation={3} className={classes.paper}>
          <Box mt={2} mb={2}>
            <Typography variant="h5">
              Version 1.0.1
            </Typography>

            <ul>
              <li>Möglichkeiten zum Import nicht spezifikationskonformer GEMAGVL4-Lieferungen erweitert: Eingefügte Tabulatoren (etwa durch Nachbearbeitung in einem Texteditor) und bestimmte Steuerzeichen am Zeilenanfang und -ende (Leerzeichen, Anführungsstriche) werden nun unterstützt; die Validierung der Felder SENDEDAUER und STOPPZEIT wurde verschärft.</li>
              <li>Import von GEMA_WERK_NR aus bislang nicht verwendetem BES-Feld in GEMAGVL4 in Abstimmung mit GEMA/GVL ermöglicht.</li>
              <li>Fehlerbehebungen beim Umgang mit hochgeladenen ZIP-Dateien</li>
              <li>Verbesserungen beim Umgang mit verschiedenen Zeichenkodierungen</li>
              <li>Darstellung betroffener Zeilennummern bei Importfehlern</li>
              <li>Plausibilitätsprüfungen und Lösungsvorschläge verbessert: Auf fehlende Datumsangaben wird jetzt explizit hingewiesen; differenzierterer Hinweis auf Lieferungen mit Meldelücken; Vermeidung von Hinweisen auf zu lange Ausstrahlungen im Falle von Sammel-Jinglemeldungen; Vermeidung von Hinweisen auf fehlende Metadaten, die durch bereits verknüpfte Referenzdaten abgedeckt sind.</li>
              <li>Übersichtlichkeit der Ausstrahlungsliste erhöht: Bei Filterung nach einem Hinweistyp wird der jeweils betrachtete Hinweis zu einer Ausstrahlung hervorgehoben.</li>
              <li>Filterung nach Ausstrahlungen ab bestimmtem Ausstrahlungszeitpunkt ermöglicht (via Klick auf Startzeit einer betrachteten Ausstrahlung)</li>
              <li>Darstellung mehrerer überlappender Ausstrahlungen innerhalb der entsprechenden Hinweismeldung ermöglicht</li>
              <li>Neuberechnung entstehender Meldelücken nach erfolgreicher Stornierung einer Lieferung</li>
              <li>Automatische Anpassung der Deklaration einer Tonaufnahme von <i>Label ohne Labelcode</i> zu <i>Label mit Labelcode</i> bei nachträglicher Angabe eines Labelcodes in Absprache mit Verwertungsgesellschaften</li>
              <li>Automatische Anpassung der Musikherkunft einer Musikproduktion von <i>Eigenproduktion bzw. Auftragsproduktion</i> zu <i>veröffentlichte Tonaufnahme bzw. Production Music Library</i> bei nachträglicher Angabe eines Identifiers (Labelcode, Labelname, ISRC, Katalognummer oder EAN/UPC) oder Verknüpfung entsprechender Referenzdaten in Absprache mit Verwertungsgesellschaften</li>
              <li>Verlaufsansicht der zu einer Sendemeldung gehörigen Lieferungen ergänzt (in Vorbereitung auf Einführung des Rückkanals)</li>
              <li>Kontaktbereich um Antwort-Funktion erweitert</li>
              <li>Informationen zum gebuchten Tarif eingebunden</li>
              <li>Weitere technische Vorbereitungen auf Einführung des Rückkanals getroffen und interne Kommunikationsprozesse mit Verwertungsgesellschaften verbessert</li>
              <li>Aktualisierung verwendeter Bibliotheken</li>
              <li>Zahlreiche weitere Fehlerbehebungen und Verbesserungen der Nutzbarkeit (u.a. verbesserter Umgang mit neuen Programmnummern, verbesserter Umgang mit fehlender MUSIK_DAUER, Behebung von Darstellungsproblemen bei fehlenden Datumsangaben, verbesserter Umgang mit negativen Ausstrahlungszeiträumen)</li>
            </ul>
          </Box>
        </Paper>
      </Box>

      <Box my={2}>
        <Paper elevation={3} className={classes.paper}>
          <Box mt={2} mb={2}>
            <Typography variant="h5">
              Version 1.0.0
            </Typography>

            <ul>
              <li>Feingranulare Verwaltung von Zugriffsrechten angelegter Unternutzer ermöglicht</li>
              <li>Benachrichtigungssystem umgesetzt: Über abgeschlossene oder fehlgeschlagene Aktionen sowie Neuigkeiten, die Ihre Aufmerksamkeit erfordern, wird ab sofort an zentraler Stelle gesammelt informiert.</li>
              <li>Überblick über anstehende Aufgaben und Meldefristen ergänzt</li>
              <li>Dashboard/Startseite umgestaltet</li>
              <li>Autovervollständigung für Labelcodes und Labels ergänzt</li>
              <li>Werkzeuge zur Recherche in Referenzdaten unabhängig von bestehender Musikdatenbank ergänzt</li>
              <li>Referenzdatenvorschläge erweitert und Vorschläge auch bei fehlenden Identifiern ermöglicht</li>
              <li>Bereitstellung von Referenzdaten-Vorschlägen in Formularen auch unabhängig von erfolgter Referenzdaten-Zuordnung</li>
              <li>Unternehmensübergreifende Nutzung von und Verwendung mehrerer Musikdatenbanken für einzelne Programme ermöglicht</li>
              <li>Möglichkeiten zum Dokumentaustausch mit GEMA/GVL ergänzt</li>
              <li>Hilfebereich mit Erklärvideos bereitgestellt</li>
              <li>Umstellung auf neue Version der Meldeschnittstelle (GEMAGVL-XML-HF Version 1.1)</li>
              <li>Suchfunktion verbessert</li>
              <li>Manuelles Überschreiben nicht erfüllter Meldefristen ermöglicht</li>
              <li>Zahlreiche Fehlerbehebungen und Verbesserungen der Benutzbarkeit</li>
            </ul>
          </Box>
        </Paper>
      </Box>

      <Box my={2}>
        <Paper elevation={3} className={classes.paper}>
          <Box mt={2} mb={2}>
            <Typography variant="h5">
              Version 0.2.1-alpha
            </Typography>

            <ul>
              <li>Ausgabe von GVL-Produkt-IDs in XML-Meldungen für Musikproduktionen mit zugeordneten Referenzdaten (in Absprache mit GEMA/GVL)</li>
              <li>Automatische Ergänzung von ISRCs bei zugeordneten Referenzdaten (in Absprache mit GEMA/GVL)</li>
              <li>Automatische Vervollständigung von Titel und Interpret bei zugeordneten Referenzdaten (in Absprache mit GEMA/GVL)</li>
              <li>Unterscheidung zwischen veröffentlichten Tonaufnahmen und Eigenproduktionen beim Import von Musikproduktionen aus GEMAGVL4 (in Absprache mit der GVL)</li>
              <li>Warnung ergänzt, wenn Lieferungen zu viele Eigenproduktionen beinhalten</li>
              <li>Musikdatenbank-Export ermöglicht (beta)</li>
              <li>Filtermöglichkeiten für Musikproduktionen und Ausstrahlungen erweitert</li>
              <li>Kleinere Verbesserungen der Benutzbarkeit</li>
              <li>Fehlerbehebungen</li>
            </ul>
          </Box>
        </Paper>
      </Box>

      <Box my={2}>
        <Paper elevation={3} className={classes.paper}>
          <Box mt={2} mb={2}>
            <Typography variant="h5">
              Version 0.2.0-alpha
            </Typography>

            <ul>
              <li>Bereitstellung von Referenzdaten innerhalb Sendemeldung.de, die mit Musikproduktionen verknüpft werden können (Ihre bestehende Musikdatenbank haben wir bereits mit den zur Verfügung stehenden Referenzdaten abgeglichen und Zuordnungsvorschläge bereitgestellt)</li>
              <li>Übernahme von Metadaten via Autovervollständigung und konkreter Vorschläge aus zugeordneten Referenzdaten ermöglicht</li>
              <li>Filter- und Suchfunktionen in allen Bereichen bereitgestellt</li>
              <li>Änderungsverlauf für Musikproduktions-Metadaten bereitgestellt</li>
              <li>Detailansicht für Musikproduktionen vereinfacht</li>
              <li>GEMAGVL4-Import verbessert</li>
              <li>Begriffsanpassung: <i>Musik-Produktions-IDs</i> werden ab sofort allgemeiner als <i>Identifier</i> bezeichnet.</li>
              <li>Sichtbarkeit von Filter- und Paginierungsinformationen auch beim Scrollen sichergestellt</li>
              <li>Verbesserte Handhabung der Vor- und Zurück-Funktionen des Webbrowsers: Bei Navigierung zurück zu Listen und Tabellen bleiben die besuchte Seite und gesetzte Filter von nun an erhalten.</li>
              <li>Zahlreiche weitere Verbesserungen der Nutzbarkeit</li>
              <li>Diverse Fehlerbehebungen</li>
            </ul>
          </Box>
        </Paper>
      </Box>

      <Box my={2}>
        <Paper elevation={3} className={classes.paper}>
          <Box mt={2} mb={2}>
            <Typography variant="h5">
              Version 0.1.13-alpha
            </Typography>

            <ul>
              <li>Weitere Plausibilitätsprüfungen ergänzt, die in der Vergangenheit zu Ablehnungen seitens der Verwertungsgesellschaften führten (Tage mit mehr als 25 Stunden Sendedauer; Lieferungen mit Meldelücken; Lieferungen mit mehreren Programmen, jedoch unterschiedlichen Meldezeiträumen)</li>
              <li>Hinweis auf neu angelegte Programme / fehlerhafte Programmkennzeichen ergänzt</li>
              <li>Darstellung der Sendedauer-Übersicht erweitert</li>
              <li>Kleinere Fehlerbehebungen und Verbesserungen der Nutzbarkeit</li>
            </ul>
          </Box>
        </Paper>
      </Box>

      <Box my={2}>
        <Paper elevation={3} className={classes.paper}>
          <Box mt={2} mb={2}>
            <Typography variant="h5">
              Version 0.1.12-alpha
            </Typography>

            <ul>
              <li>Verbesserte Darstellung der Sendedauer-Übersicht</li>
              <li>Änderung der Repräsentation von Zeitstempeln in generierten XML-Meldungen in Absprache mit GEMA/GVL</li>
              <li>Kleinere Fehlerbehebungen und Verbesserungen der Nutzbarkeit</li>
            </ul>
          </Box>
        </Paper>
      </Box>

      <Box my={2}>
        <Paper elevation={3} className={classes.paper}>
          <Box mt={2} mb={2}>
            <Typography variant="h5">
              Version 0.1.11-alpha
            </Typography>

            <ul>
              <li>Filterung von Ausstrahlungen nach Zeitraum ermöglicht</li>
              <li>Dateinamen exportierter XML-Meldungen, die ausschließlich Jingles enthalten, in Absprache mit GEMA/GVL angepasst</li>
              <li>Stornierungsanfragen: Angabe einer Stornierungsbegründung ermöglicht</li>
              <li>Fehlerbehebung: Unerlaubte Steuerzeichen in Musikwerk-Daten führen nun nicht mehr zu Fehlern beim XML-Export</li>
            </ul>
          </Box>
        </Paper>
      </Box>

      <Box my={2}>
        <Paper elevation={3} className={classes.paper}>
          <Box mt={2} mb={2}>
            <Typography variant="h5">
              Version 0.1.10-alpha
            </Typography>

            <ul>
              <li>Stornierungsanfrage für bereits übermittelte Lieferungen ermöglicht</li>
              <li>Umgang mit fehlenden Urhebern und Interpreten in Absprache mit GEMA und GVL angepasst: Bei Jingle-Meldungen wird bei fehlenden Interpreten nun &ndash; sofern vorhanden &ndash; der Komponist als Interpret gemeldet &ndash; und vice versa.</li>
              <li>Kleinere Fehlerbehebungen und Verbesserungen der Nutzbarkeit</li>
            </ul>
          </Box>
        </Paper>
      </Box>

      <Box my={2}>
        <Paper elevation={3} className={classes.paper}>
          <Box mt={2} mb={2}>
            <Typography variant="h5">
              Version 0.1.9-alpha
            </Typography>

            <ul>
              <li>Bugfix: Import von GEMAGVL4-Dateien mit fehlerhaften Zeilen wurde mit einem unbekannten Fehler abgebrochen</li>
            </ul>
          </Box>
        </Paper>
      </Box>

      <Box my={2}>
        <Paper elevation={3} className={classes.paper}>
          <Box mt={2} mb={2}>
            <Typography variant="h5">
              Version 0.1.8-alpha
            </Typography>

            <ul>
              <li>Programmverwaltung technisch überarbeitet und Darstellung erweitert</li>
              <li>Zusammenführung von Programmen mit verschiedenen GEMAGVL4-Programmnummern ermöglicht</li>
              <li>Automatische E-Mail-Erinnerungen an anstehende Meldefristen</li>
              <li>Darstellung von Meldezeiträumen in Sendemeldungsansicht vereinfacht</li>
              <li>Darstellung von Sendedauer-Statistiken zu Lieferungen ergänzt</li>
              <li>Automatische Unterscheidung zwischen den Musikherkunftsarten &raquo;veröffentlichte Tonaufnahme&laquo; und &raquo;Production Music Library&laquo; beim Import von Musikwerken aus GEMAGVL4 (Ihre bereits importierten Musikwerke haben wir für Sie bereits aktualisiert.)</li>
              <li>Bearbeitung bereits exportierter, aber noch nicht übermittelter Sendemeldungen ermöglicht</li>
              <li>Anzeige der Anzahl offener Sendemeldungen im Menü</li>
              <li>Hilfetexte erweitert</li>
            </ul>
          </Box>
        </Paper>
      </Box>

      <Box my={2}>
        <Paper elevation={3} className={classes.paper}>
          <Box mt={2} mb={2}>
            <Typography variant="h5">
              Version 0.1.7-alpha
            </Typography>

            <ul>
              <li>Darstellung sendemeldungsbezogener Aufgaben überarbeitet und Aufgaben kategorisiert</li>
              <li>Schnelllösungen für zahlreiche typische Aufgaben ergänzt</li>
              <li>Diverse kleinere Verbesserungen der Benutzeroberfläche</li>
            </ul>
          </Box>
        </Paper>
      </Box>

      <Box my={2}>
        <Paper elevation={3} className={classes.paper}>
          <Box mt={2} mb={2}>
            <Typography variant="h5">
              Version 0.1.6-alpha
            </Typography>

            <ul>
              <li>Weitere Plausibilitätsprüfungen ergänzt (ungewöhnlich lange Ausstrahlungslängen und Ausstrahlungszeiträume, die in der Zukunft liegen)</li>
              <li>Fehlerbehebung: Bei Ausstrahlungen, die während der Sommerzeit starteten, aber bis in die Winterzeit andauerten, wurde das Ausstrahlungsende falsch berechnet. (Von Ihnen bereits importierte Sendemeldungen haben wir automatisch korrigiert. Für Sendemeldungen, die bereits an die Verwertungsgesellschaften übermittelt wurden, haben wir in enger Absprache mit den Verwertungsgesellschaften eine Korrekturmeldung übermittelt. Betroffene Kunden wurden per E-Mail informiert.)</li>
            </ul>
          </Box>
        </Paper>
      </Box>

      <Box my={2}>
        <Paper elevation={3} className={classes.paper}>
          <Box mt={2} mb={2}>
            <Typography variant="h5">
              Version 0.1.5-alpha
            </Typography>

            <ul>
              <li>Dateinamen exportierter XML-Meldungen abermals in Absprache mit GEMA/GVL angepasst</li>
              <li>Einstellungen-Bereich angepasst und erweitert</li>
              <li>Deaktivierung von E-Mail-Benachrichtigungen / Newsletterempfang für einzelne Nutzer ermöglicht</li>
              <li>Fehlerbehebung: Download einzelner Dateien aus hochgeladenen ZIP-Archiven ermöglicht</li>
              <li>Automatische Aktualisierung der Benutzeroberfläche, sobald eine neue Sendemeldung-Version zur Verfügung steht</li>
              <li>Kleinere Fehlerbehebungen und Verbesserungen der Benutzeroberfläche</li>
            </ul>
          </Box>
        </Paper>
      </Box>

      <Box my={2}>
        <Paper elevation={3} className={classes.paper}>
          <Box mt={2} mb={2}>
            <Typography variant="h5">
              Version 0.1.4-alpha
            </Typography>

            <ul>
              <li>Dateinamen exportierter XML-Meldungen erneut in Absprache mit GEMA/GVL angepasst</li>
            </ul>
          </Box>
        </Paper>
      </Box>

      <Box my={2}>
        <Paper elevation={3} className={classes.paper}>
          <Box mt={2} mb={2}>
            <Typography variant="h5">
              Version 0.1.3-alpha
            </Typography>

            <ul>
              <li>Umsetzung einiger kundenspezifischer Erweiterungen</li>
              <li>Dateinamen exportierter XML-Meldungen in Absprache mit GEMA/GVL angepasst</li>
            </ul>
          </Box>
        </Paper>
      </Box>

      <Box my={2}>
        <Paper elevation={3} className={classes.paper}>
          <Box mt={2} mb={2}>
            <Typography variant="h5">
              Version 0.1.2-alpha
            </Typography>

            <ul>
              <li>Grundlegende technische Unterstützung für durch GEMA/GVL bereitgestellte GEMAGVL-XML-Rückmeldungen ergänzt</li>
              <li>XML-Export fehlerhafter Produktion-IDs in bestimmten Fällen ermöglicht: Anstatt eine fehlerhafte Produktion-ID bei der Generierung der XML-Meldung auszulassen, wird nun zunächst versucht, die Produktion-ID als Katalognummer zu melden. Erst wenn eine Produktion-ID auch keine gültige Katalognummer im Sinne der Spezifikation der XML-Schnittstelle darstellt, wird diese ausgelassen und bei Bedarf durch einen Platzhalter ersetzt.</li>
              <li>Zusätzliche Absicherung von Nutzerkonten via Zwei-Faktor-Authentifizierung ermöglicht</li>
              <li>Fehlerbehebung: Statusanzeige für hochgeladene Archive nach Löschung enthaltener Dateien korrigiert</li>
              <li>Filterung nach geänderten Musikwerken ermöglicht</li>
              <li>Performance-Verbesserungen</li>
            </ul>
          </Box>
        </Paper>
      </Box>

      <Box my={2}>
        <Paper elevation={3} className={classes.paper}>
          <Box mt={2} mb={2}>
            <Typography variant="h5">
              Version 0.1.1-alpha
            </Typography>

            <ul>
              <li>Fehlerbehebungen bei der Erfassung von Änderungen an Musikwerken: Im Einzelfall konnte es in der Vergangenheit vorkommen, dass Musikwerke trotz vorgenommener manueller Änderungen aus der Datenbank entfernt wurden, wenn die zugehörigen Lieferungen gelöscht wurden.</li>
              <li>Bestandsdaten in Musikdatenbanken migriert, um neue Absprachen mit GEMA / GVL zu erfüllen: Die beim GEMAGVL4-Import zu verwendenden Platzhalterlisten wurden erweitert; Ihre Musikdatenbanken wurden automatisch aktualisiert, sodass kein erneuter Import erforderlich ist.</li>
              <li>Kleinere Verbesserungen der Benutzeroberfläche</li>
            </ul>
          </Box>
        </Paper>
      </Box>

      <Box my={2}>
        <Paper elevation={3} className={classes.paper}>
          <Box mt={2} mb={2}>
            <Typography variant="h5">
              Version 0.1.0-alpha
            </Typography>

            <ul>
              <li>Übermittlung von Produktivmeldungen nach Abstimmung mit GEMA/GVL ermöglicht</li>
              <li>Klassifikation von Produktion-IDs aus GEMAGVL4-Daten verbessert</li>
              <li>Generierung der XML-Meldung und anschließende Übermittlung an GEMA/GVL mit einem Klick ermöglicht</li>
              <li>Weitere Verbesserungen der Benutzeroberfläche</li>
            </ul>
          </Box>
        </Paper>
      </Box>

      <Box my={2}>
        <Paper elevation={3} className={classes.paper}>
          <Box mt={2} mb={2}>
            <Typography variant="h5">
              Version 0.0.36-dev
            </Typography>

            <ul>
              <li>Verbesserte Unterstützung für GEMA-Werknummern</li>
              <li>Festlegung eines alternativen Anzeigenamens für Sendeunternehmen ermöglicht</li>
              <li>Performance-Verbesserungen</li>
              <li>Kleinere Verbesserungen der Benutzeroberfläche</li>
            </ul>
          </Box>
        </Paper>
      </Box>

      <Box my={2}>
        <Paper elevation={3} className={classes.paper}>
          <Box mt={2} mb={2}>
            <Typography variant="h5">
              Version 0.0.35-dev
            </Typography>

            <ul>
              <li>
                In Absprache mit den Verwertungsgesellschaften wurde die Musikdauer (Feld STOPPZEIT in GEMAGVL4) aus
                den von Sendemeldung.de verwalteten Datenmodellen entfernt.
                Diese Information ist zukünftig nicht mehr erforderlich.
                Etwaige Duplikate in bestehenden Musikdatenbanken, die durch den Wegfall der Stoppzeit entstanden sind,
                haben wir entsprechend zusammengeführt.
              </li>
            </ul>
          </Box>
        </Paper>
      </Box>

      <Box my={2}>
        <Paper elevation={3} className={classes.paper}>
          <Box mt={2} mb={2}>
            <Typography variant="h5">
              Version 0.0.34-dev
            </Typography>

            <ul>
              <li>Technische Anpassungen des Übermittlungsprozesses an GEMA/GVL</li>
            </ul>
          </Box>
        </Paper>
      </Box>

      <Box my={2}>
        <Paper elevation={3} className={classes.paper}>
          <Box mt={2} mb={2}>
            <Typography variant="h5">
              Version 0.0.33-dev
            </Typography>

            <ul>
              <li>Formatprüfung für weitere Produktion-ID-Typen ergänzt: Katalognummer, ISWC, GEMA-Werk-Nummer, ICE,
                GVL-Produkt-ID.
              </li>
              <li>Informationen zu Testwochen aktualisiert.</li>
            </ul>
          </Box>
        </Paper>
      </Box>

      <Box my={2}>
        <Paper elevation={3} className={classes.paper}>
          <Box mt={2} mb={2}>
            <Typography variant="h5">
              Version 0.0.32-dev
            </Typography>

            <ul>
              <li>Sammelaktion zur Festlegung der Musikherkunft aller bereits importierten Jingles einer Lieferung
                ergänzt
              </li>
              <li>Sammelaktion zur Behebung fehlender Musikwerk-Längen ergänzt</li>
            </ul>
          </Box>
        </Paper>
      </Box>

      <Box my={2}>
        <Paper elevation={3} className={classes.paper}>
          <Box mt={2} mb={2}>
            <Typography variant="h5">
              Version 0.0.31-dev
            </Typography>

            <ul>
              <li>Performance-Verbesserungen</li>
              <li>Verschiedene Verbesserungen der Benutzeroberfläche</li>
              <li>Dateianzahlbegrenzung für ZIP-Archive erhöht</li>
              <li>Robustheit der GEMAGVL4-Importprozesse erhöht</li>
              <li>Fehlerbehebung: Fehlgeschlagene Uploads konnten nicht entfernt werden.</li>
            </ul>
          </Box>
        </Paper>
      </Box>

      <Box my={2}>
        <Paper elevation={3} className={classes.paper}>
          <Box mt={2} mb={2}>
            <Typography variant="h5">
              Version 0.0.30-dev
            </Typography>

            <ul>
              <li>
                Darstellung zu bearbeitender Aufgaben verbessert und teilweise Erklärungstexte ergänzt.
              </li>
              <li>
                Generierung von XML-Meldungen bei verschiedenen vorliegenden Mängeln ermöglicht:
                Sendemeldung.de fügt in diesem Fall automatisch Platzhalter in die XML-Meldungen ein, um die formalen
                technischen Anforderungen zu erfüllen.
              </li>
              <li>
                Fehlerbehebung: Mängel an Musikwerken, die nur in Ausstrahlungen referenziert werden, die in der
                XML-Meldung ohnehin nicht ausgegeben werden (zum Beispiel wegen einer Sendedauer von 0 Sekunden),
                verhindern die Generierung von XML-Meldungen nicht mehr.
              </li>
              <li>Fehlerbehebung: Technisches Problem im Lieferant-ID-Formular</li>
              <li>Platzhalter-Liste erweitert</li>
              <li>Weitere kleinere Verbesserungen der Benutzeroberfläche</li>
            </ul>
          </Box>
        </Paper>
      </Box>

      <Box my={2}>
        <Paper elevation={3} className={classes.paper}>
          <Box mt={2} mb={2}>
            <Typography variant="h5">
              Version 0.0.29-dev
            </Typography>

            <ul>
              <li>Fehlerbehebung: Hinweis auf fehlende Tonträgerinformationen bei Eigenproduktionen</li>
              <li>
                Fehlerbehebung: Möglichkeit zum Wechsel zwischen Sendeunternehmen wurde neu angelegten Nutzern erst
                nach erneutem Laden der Webseite angeboten.
              </li>
            </ul>
          </Box>
        </Paper>
      </Box>

      <Box my={2}>
        <Paper elevation={3} className={classes.paper}>
          <Box mt={2} mb={2}>
            <Typography variant="h5">
              Version 0.0.28-dev
            </Typography>

            <ul>
              <li>Informationen zu Testwochen ergänzt</li>
              <li>Löschen von Sendemeldungen ermöglicht, solange diese noch nicht exportiert wurden</li>
              <li>Darstellung von und Bearbeitungsmöglichkeiten für Musikwerke erweitert</li>
              <li>Menüstruktur leicht geändert</li>
              <li>Performance-Verbesserungen</li>
              <li>Verschiedene Fehlerbehebungen und Verbesserungen der Nutzbarkeit</li>
            </ul>
          </Box>
        </Paper>
      </Box>

      <Box my={2}>
        <Paper elevation={3} className={classes.paper}>
          <Box mt={2} mb={2}>
            <Typography variant="h5">
              Version 0.0.27-dev
            </Typography>

            <ul>
              <li>Unterstützung bei Festlegung der Lieferant-ID anhand von GEMA / GVL bereitgestellter Listen</li>
              <li>Erkennung von Promo-CDs verbessert</li>
              <li>Voreinstellung für Musikherkunft neu importierter Jingle-Meldungen ist jetzt konfigurierbar.</li>
              <li>Platzhalter-Liste erweitert</li>
              <li>Fehlerbehebungen beim XML-Export</li>
              <li>Weitere Fehlerbehebungen</li>
            </ul>
          </Box>
        </Paper>
      </Box>

      <Box my={2}>
        <Paper elevation={3} className={classes.paper}>
          <Box mt={2} mb={2}>
            <Typography variant="h5">
              Version 0.0.26-dev
            </Typography>

            <ul>
              <li>Anpassung der XML-Programmkennzeichen entsprechend Absprache mit GEMA / GVL</li>
              <li>Sendernamen auch in Bestandsdaten aktualisiert (gemäß bereitgestellter Liste von GEMA / GVL)</li>
              <li>Verschiedene Prüfungen &amp; angezeigte Aufgaben überarbeitet, um Bearbeitung zu vereinfachen</li>
              <li>Ausstrahlungen mit 0 Sekunden Sendedauer werden in XML-Ausgabe nun automatisch ausgelassen.</li>
              <li>Standardbelegung des Musikherkunft-Felds beim Import angepasst</li>
              <li>Hinweis auf fehlende Musikwerke ergänzt</li>
              <li>Fehlerbehebung: Anzeige des Lieferungszeitraums bei fehlenden Ausstrahlungszeiten</li>
              <li>Fehlerbehebung: Anzeige von Jingle-Warnungen während Import</li>
            </ul>
          </Box>
        </Paper>
      </Box>

      <Box my={2}>
        <Paper elevation={3} className={classes.paper}>
          <Box mt={2} mb={2}>
            <Typography variant="h5">
              Version 0.0.25-dev
            </Typography>

            <ul>
              <li>
                Fehlerbehebung: Dateiimport wurde teilweise nicht oder verzögert abgeschlossen oder mit einem internen
                Serverfehler quittiert
              </li>
            </ul>
          </Box>
        </Paper>
      </Box>

      <Box my={2}>
        <Paper elevation={3} className={classes.paper}>
          <Box mt={2} mb={2}>
            <Typography variant="h5">
              Version 0.0.24-dev
            </Typography>

            <ul>
              <li>Programmspezifische Anpassung der tolerierten Überlappungszeit ermöglicht</li>
              <li>
                Verbesserungen beim GEMAGVL4-Import:
                <ul>
                  <li>Fehler beim Import von GEMAGVL4-Dateien mit spezifikationswidrigem NUTZUNG-Feld behoben.</li>
                  <li>Fehlerhafte Zeilen führen nun nicht mehr zur Erstellung leerer Programme oder Musikwerke.</li>
                </ul>
              </li>
              <li>Detailverbesserungen in Ausstrahlungsliste</li>
              <li>Performance-Verbesserungen</li>
            </ul>
          </Box>
        </Paper>
      </Box>

      <Box my={2}>
        <Paper elevation={3} className={classes.paper}>
          <Box mt={2} mb={2}>
            <Typography variant="h5">
              Version 0.0.23-dev
            </Typography>

            <ul>
              <li>Performance-Verbesserungen</li>
              <li>Kleinere Fehlerbehebungen</li>
            </ul>
          </Box>
        </Paper>
      </Box>

      <Box my={2}>
        <Paper elevation={3} className={classes.paper}>
          <Box mt={2} mb={2}>
            <Typography variant="h5">
              Version 0.0.22-dev
            </Typography>

            <ul>
              <li>Passwort-Änderung ermöglicht</li>
              <li>Performance-Verbesserungen</li>
            </ul>
          </Box>
        </Paper>
      </Box>

      <Box my={2}>
        <Paper elevation={3} className={classes.paper}>
          <Box mt={2} mb={2}>
            <Typography variant="h5">
              Version 0.0.21-dev
            </Typography>

            <ul>
              <li>Performance-Verbesserungen</li>
              <li>Anzeige des Benutzernamens im Header</li>
              <li>Fehlerbehebung: Darstellungsprobleme beim Wechsel zwischen Sendeunternehmen</li>
              <li>Fehlerbehebung: Darstellungsprobleme in Personen-Tabelle</li>
            </ul>
          </Box>
        </Paper>
      </Box>

      <Box my={2}>
        <Paper elevation={3} className={classes.paper}>
          <Box mt={2} mb={2}>
            <Typography variant="h5">
              Version 0.0.20-dev
            </Typography>

            <ul>
              <li>Programmkürzel-Listen eingebunden</li>
              <li>Hinweis auf fehlende Produktion-IDs ergänzt</li>
              <li>Änderungsprotokoll eingebunden</li>
              <li>Platzhalter-Listen aktualisiert</li>
              <li>Kleinere Verbesserungen der Nutzbarkeit</li>
              <li>Fehlerbehebungen</li>
            </ul>
          </Box>
        </Paper>
      </Box>

      <Box my={2}>
        <Paper elevation={3} className={classes.paper}>
          <Box mt={2} mb={2}>
            <Typography variant="h5">
              Version 0.0.19-dev
            </Typography>

            <ul>
              <li>Darstellung der Aufgabe, nach der gefiltert wird, in Ausstrahlungs- und Musikwerk-Liste</li>
              <li>Löschen von Aufgaben ermöglicht</li>
              <li>Anlegen weiterer Administrator-Nutzer ermöglicht</li>
              <li>Serverseitig gelöschte Datensätze werden nun live in der Benutzeroberfläche reflektiert</li>
              <li>Detailverbesserungen der Benutzeroberfläche</li>
              <li>Fehlerbehebung: Live-Aktualisierung von Datensätzen schlug vereinzelt fehl, wenn sehr viele Datensätze
                gleichzeitig dargestellt wurden
              </li>
              <li>Fehlerbehebung: Fehlender Zugriff auf Vertragsdokumente nach Nutzerkonto-Umwandlung</li>
              <li>Fehlerbehebung: Probleme bei der Aktualisierung von Ansprechpartnern</li>
            </ul>
          </Box>
        </Paper>
      </Box>
    </DashboardLayout>
  );
}
