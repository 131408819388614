import {Box, IconButton, Toolbar} from "@mui/material";
import {useSelector} from "react-redux";
import React, {useEffect, useMemo, useState} from "react";
import {getGEMAGVLXMLLieferungGetter, getTodoTaskGetter} from "src/features/entity";
import PlaylistItem from "src/components/entities/gemagvlxml/components/PlaylistItem";
import {useQuery} from "src/packages/route-utils";
import {getSelectedOrganizationId} from "src/features/dashboard";
import GEMAGVLXMLAusstrahlungFormDialog from "src/components/entities/gemagvlxml/GEMAGVLXMLAusstrahlungFormDialog";
import {useEntityObserver} from "src/features/entity/entity-hooks";
import {TodoTask} from "src/components/entities/todotask/TodoTask";
import {formatDate, formatDateTime, isValidDate, parseDate} from "src/packages/date-utils";
import {KeyboardArrowLeft, KeyboardArrowRight} from "@mui/icons-material";
import {addDays} from "date-fns";
import PlaylistDateChoiceButton from "src/components/entities/gemagvlxml/components/PlaylistDateChoiceButton";
import PlaylistFilter from "src/components/entities/gemagvlxml/components/PlaylistFilter";
import {updateQueryString, useOrganizationRelatedListing} from "src/features/ui/listing/listing-hooks";
import PageToolbar from "src/components/layout/components/PageToolbar";
import {deserializeFilterValue} from "src/packages/FancyFilterField";

export default function Playlist(
  {
    gemagvlxml_lieferung,
    orgMusicWorkId,
    listingId: listingIdSuffix,
    meta,
    children,
    noEntriesContent,
    loadingContent,
    // totalCount,
    showReports,
    disableEdit = false,
    defaultOrdering = 'datum_von,datum_uhrzeit_von,lineno',
    hideLinks,
    isEmbedded = false,
    listingProps,
    filterParamName = 'filter',
    getPlaylistItemProps = id => undefined,
  }
) {
  let queryTodoTaskId = useQuery('todoTaskId');
  if (isEmbedded) {
    queryTodoTaskId = undefined;
  }
  useEntityObserver({
    type: 'todo_task',
    id: queryTodoTaskId
  });
  const todoTask = useSelector(getTodoTaskGetter)(queryTodoTaskId);
  const todoType = todoTask?.todo_type;

  let dateTimeFrom = useQuery('dateTimeFrom');
  if (isEmbedded) {
    dateTimeFrom = undefined;
  }

  const parsedDateTimeFrom = useMemo(() => {
    let parsedDateTimeFrom = new Date(dateTimeFrom);
    if (!dateTimeFrom || !isValidDate(parsedDateTimeFrom)) {
      parsedDateTimeFrom = undefined;
    }
    return parsedDateTimeFrom;
  }, [dateTimeFrom])
  const formattedDateTimeFrom = parsedDateTimeFrom ? formatDateTime(parsedDateTimeFrom) : '';

  let queryDate = useQuery('date');
  if (isEmbedded) {
    queryDate = undefined;
  }
  let date = useMemo(() => {
    try {
      return parseDate(queryDate) || undefined;
    } catch (e) {
      return undefined;
    }
  }, [queryDate]);

  let {
    organization: organizationId,
    stations,
    datum_von,
    datum_bis
  } = useSelector(getGEMAGVLXMLLieferungGetter)(gemagvlxml_lieferung);
  const selectedOrganizationid = useSelector(getSelectedOrganizationId);

  if (gemagvlxml_lieferung === undefined) {
    organizationId = selectedOrganizationid;
  }

  const {
    filter,
    updateFilter,
    filterProps,
    paginationProps,
    ordering,
    isLoading,
    noDataExists,
    renderIds,
  } = useOrganizationRelatedListing({
    organizationId,
    listingIdSuffix,
    endpoint: (gemagvlxml_lieferung ? (
      `/api/sendemeldung/organizations/[ORGANIZATION_ID]/gemagvlxml_lieferungen/${gemagvlxml_lieferung}/ausstrahlungen/`
    ) : (
      '/api/sendemeldung/organizations/[ORGANIZATION_ID]/gemagvlxml_ausstrahlungen/'
    )),
    entityType: 'gemagvlxml_ausstrahlung',
    defaultOrdering: [defaultOrdering],
    meta: {
      ...meta,
      todo_tasks: !!todoType,
      todo_type: todoType,
      music_work: orgMusicWorkId,
      datum_von: formatDate(date),
      datum_uhrzeit_von__gte: formattedDateTimeFrom,
    },
    ...listingProps,
    filterParamName,
  });

  const showStations = stations?.length !== 1;

  let queryFilter = useQuery(filterParamName);
  if (isEmbedded) {
    queryFilter = undefined;
  }
  useEffect(() => {
    // FIXME: When reloading the page after removing the filter, the filter is set again.
    const filter = deserializeFilterValue(queryFilter);

    let addFilters = [];

    if (queryTodoTaskId && (!queryFilter || !filter?.filter(el => el.id === 'quality' && el.choice === 'specific_todo_task')?.length)) {
      addFilters.push({
        id: 'quality',
        choice: 'specific_todo_task'
      });
    }

    if (dateTimeFrom && (!queryFilter || !filter?.filter(el => el.id === 'dateTimeFrom' && el.choice === 'selected')?.length)) {
      addFilters.push({
          id: 'dateTimeFrom',
          choice: 'selected',
      });
    }

    if (addFilters.length > 0) {
      updateFilter(
        null,
        [...filter, ...addFilters],
        {replace: true},
      );
    }
  }, [dateTimeFrom, queryTodoTaskId]);

  const [editAusstrahlung, setEditAusstrahlung] = useState(null);

  let previousId = null;

  const filteredByTodoTask = queryTodoTaskId && (
    filter.filter(({
      id,
      choice
    }) => id === 'quality' && choice === 'specific_todo_task').length > 0
  );

  const extraOptions = useMemo(() => {
    const extraOptions = [];

    if (queryTodoTaskId) {
      extraOptions.push({
        category: "Status",
        id: 'quality',
        label: 'Hinweise',
        choice: 'specific_todo_task',
        chipLabel: ({
          id,
          choice
        }) => ({
          'specific_todo_task': "Einträge mit ausgewähltem Hinweis",
          'has_todo_tasks': "Einträge mit Hinweisen",
          'no_todo_tasks': "Einträge ohne Hinweise",
        }[choice]),
        choiceLabel: ({
          id,
          choice
        }) => ({
          'specific_todo_task': "ausgewählter",
          'has_todo_tasks': "liegen vor",
          'no_todo_tasks': "liegen nicht vor",
        }[choice]),
        choices: ['specific_todo_task', 'has_todo_tasks', 'no_todo_tasks'],
      });
    }

    if (parsedDateTimeFrom) {
      extraOptions.push({
        category: "Zeitraum",
        id: 'dateTimeFrom',
        label: 'Ausstrahlungsbeginn',
        choice: `selected`,
        chipLabel: ({
          id,
          choice
        }) => ({
          'selected': `Einträge ab ausgewähltem Zeitpunkt`,
        }[choice]),
        choiceLabel: ({
          id,
          choice
        }) => ({
          'selected': `ab ausgewähltem Zeitpunkt`,
        }[choice]),
        choices: ['selected'],
      });
    }

    return extraOptions;
  }, [queryTodoTaskId, parsedDateTimeFrom]);

  return <>
    {!isEmbedded ? (
      <PageToolbar
        searchField={(
          <PlaylistFilter
            {...filterProps}
            allowSearch
            extraOptions={extraOptions}
          />
        )}
        hidePagination={!!noDataExists}
        paginationProps={paginationProps}
        extraField={(
          <Toolbar variant="dense">
            {date ? (
              <IconButton
                aria-label="previousDay"
                color="inherit"
                onClick={() => updateQueryString({date: formatDate(addDays(date, -1))})}
                disabled={!datum_von || date <= parseDate(datum_von)}
                size="large">
                <KeyboardArrowLeft/>
              </IconButton>
            ) : null}
            <PlaylistDateChoiceButton
              gemagvlxml_lieferung={gemagvlxml_lieferung}
              date={date}
              selectDate={value => updateQueryString({date: formatDate(value)})}
            />
            {date ? (
              <IconButton
                aria-label="nextDay"
                color="inherit"
                onClick={() => updateQueryString({date: formatDate(addDays(date, 1))})}
                disabled={!datum_bis || date >= parseDate(datum_bis)}
                size="large">
                <KeyboardArrowRight/>
              </IconButton>
            ) : null}
          </Toolbar>
        )}
      >
      </PageToolbar>
    ) : null}

    {filteredByTodoTask ? (
      <Box mb={2}>
        <TodoTask
          id={queryTodoTaskId}
          gemagvlxmlLieferungId={gemagvlxml_lieferung}
          hidePlaylistButton
        />
      </Box>
    ) : null}

    {renderIds.length === 0 ? (
      noDataExists ? (
        noEntriesContent || children
      ) : isLoading ? (
        loadingContent || children
      ) : (
        children
      )
    ) : (
      <>
        {renderIds?.map((id, i) => {
          const result = (
            <PlaylistItem
              key={id || i}
              id={id}
              previousId={previousId}
              onEdit={!disableEdit ? setEditAusstrahlung : undefined}
              showStation={showStations}
              showReport={showReports}
              hideLink={hideLinks}
              filteredByTodoTaskId={filteredByTodoTask ? queryTodoTaskId : undefined}
              {...getPlaylistItemProps(id)}
            />
          );
          previousId = id;
          return result;
        })}
      </>
    )}

    {/*TODO: Delayed removal of dialog for fadeout transition.*/}
    {editAusstrahlung ? (
      <GEMAGVLXMLAusstrahlungFormDialog
        data={editAusstrahlung}
        onClose={() => setEditAusstrahlung(null)}
      />
    ) : null}
  </>;
}
