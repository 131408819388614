import React from 'react';
import FancyFilterField from "src/packages/FancyFilterField";

export default function StationFilter({...props}) {
  return (
    <FancyFilterField
      options={stationFilterOptions}
      groupBy={(option) => option.category}
      limitTags={3}
      fullWidth
      variant="outlined"
      placeholder="Programme suchen..."
      {...props}
    />
  );
}

const stationFilterOptions = [
  {
    category: "Status",
    id: 'status',
    label: 'Status',
    choice: 'active',
    chipLabel: ({
      id,
      choice
    }) => ({
      'active': "aktive Programme",
      'draft': "Entwürfe",
    }[choice]),
    choiceLabel: ({
      id,
      choice
    }) => ({
      'active': "aktiv",
      'draft': "Entwurf",
    }[choice]),
    choices: ['active', 'draft'],
  },
];
