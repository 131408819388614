import React from "react";
import {useSelector} from "react-redux";
import NaturalTime from "src/packages/natural-time-view";
import {useEntityDeleter, useEntityObserver} from "src/features/entity/entity-hooks";
import {Box, Button, Card, CardContent} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import {PlaylistPlay} from "@mui/icons-material";
import {navigate} from "gatsby";
import FileRow from "src/components/entities/upload/FileRow";
import Alert from '@mui/material/Alert';
import { AlertTitle, Skeleton } from '@mui/material';
import {uploadedFiles} from "src/features/orm/selectors";
import {useSessionTokenUrl} from "src/api/api-hooks";
import Typography from "@mui/material/Typography";
import {getUploadedFileGetter} from "src/features/entity";
import HrefChip from "src/packages/gatsby-mui-helpers/HrefChip";
import {useHasPermissions} from "src/features/dashboard/dashboard-hooks";

const UploadedFileDetails = React.memo(function ({id}) {
  const uploadedFile = useSelector(state => uploadedFiles(state, id));
  const {
    duplicate_of,
    errors,
    warnings,
    children,
    type,
    encoding,
    encoding_confidence,
    is_duplicate: isDuplicate,
    is_completed: isCompleted,
    is_processing_started: isProcessingStarted,
    is_processing_queued: isProcessingQueued,
  } = uploadedFile || {};

  useEntityObserver({type: 'uploaded_file', id});

  return (
    <>
      {isDuplicate ? (
        <>
          <Alert variant="filled" severity="info">
            <AlertTitle>Doppelter Upload erkannt</AlertTitle>
            Diese Datei ist eine inhaltsgleiche Kopie einer bereits verarbeiteten Datei und wurde daher nicht nochmal
            verarbeitet.
          </Alert>
          {duplicate_of ? (
            <Box mt={2}>
              <UploadedFileRow id={duplicate_of} expansible variant="outlined"/>
            </Box>
          ) : null}
        </>
      ) : isCompleted ? (
        type === 'gemagvl4' ? (
          <Box mb={2}>
            <Alert variant="filled" severity="info">
              Die Datei wurde erfolgreich vorverarbeitet.
              Es wurden Sendemeldungen im GEMAGVL4-Format erkannt.
            </Alert>
          </Box>
        ) : (
          <Box mb={2}>
            <Alert variant="filled" severity="info">
              Die Datei wurde erfolgreich verarbeitet.
            </Alert>
          </Box>
        )
      ) : (isProcessingStarted || isProcessingQueued && children?.length > 0) ? (
        <Box mb={2}>
          <Alert severity="info">
            <AlertTitle>Verarbeitung läuft</AlertTitle>
            Die Datei wird gerade von Sendemeldung.de verarbeitet. Bitte haben Sie einen Augenblick Geduld.
          </Alert>
        </Box>
      ) : isProcessingQueued ? (
        <Box mb={2}>
          <Alert severity="info">
            <AlertTitle>Warte auf Verarbeitung...</AlertTitle>
            Derzeit befinden sich noch andere Dateien in Verarbeitung durch Sendemeldung.de.
            Die Datei wird automatisch verarbeitet, sobald alle vorherigen Verarbeitungsprozesse abgeschlossen wurden.
          </Alert>
        </Box>
      ) : null}

      {errors?.map(({title, message, id, meta}, i) => (
        <Box mb={2} key={i}>
          <Alert variant="filled" severity="error">
            <AlertTitle>{title}</AlertTitle>
            {message}

            {meta?.gemagvl4_sender ? (
              <Card sx={{mt: 1}}>
                <CardContent>
                  <Typography color="textSecondary" gutterBottom>
                    Betroffenes Programm
                  </Typography>
                  {meta?.gemagvl4_sender} / {meta?.gemagvl4_prognr}
                </CardContent>
              </Card>
            ) : null}

            {meta?.first_line ? (
              <Card sx={{mt: 1}}>
                <CardContent>
                  <Typography color="textSecondary" gutterBottom>
                    Betroffene Zeilennummer
                  </Typography>
                  {meta?.first_line}
                </CardContent>
              </Card>
            ) : null}

            {meta?.parsed_lines > 0 ? (
              <Card sx={{mt: 1}}>
                <CardContent>
                  <Typography color="textSecondary" gutterBottom>
                    Erste fehlerhafte Zeilennummer
                  </Typography>
                  {meta?.parsed_lines + 1}
                </CardContent>
              </Card>
            ) : null}
          </Alert>
        </Box>
      ))}
      {warnings?.map(({title, message}, i) => (
        <Box mb={2} key={i}>
          <Alert variant="filled" severity="warning">
            <AlertTitle>{title}</AlertTitle>
            {message}
          </Alert>
        </Box>
      ))}

      {type !== 'container' && type !== 'duplicate' && encoding ? (
        <Card>
          <CardContent>
            <Typography color="textSecondary" gutterBottom>
              Verwendete Zeichenkodierung
            </Typography>
            {encoding}
            {encoding_confidence !== null ? (
              <>
                {' '}
                {encoding_confidence === 0.0 ? (
                  <Box mt={2}>
                    <Alert variant="filled" severity="warning">
                      Die Dateikodierung konnte nicht automatisch ermittelt wurden, daher wurde ersatzweise die
                      Standard-Kodierung aus der GEMAGVL4-Spezifikation verwendet.
                    </Alert>
                  </Box>
                ) : (
                  <>
                    (automatisch ermittelt &ndash; Konfidenz: {(encoding_confidence * 100).toFixed(2) + "%"})
                  </>
                )}
              </>
            ) : null}
          </CardContent>
        </Card>
      ) : null}

      {children?.length > 0 ? (
        <Box>
          {children?.map((childId, i) => (
            <UploadedFileRow key={childId || i} id={childId} expansible variant="outlined"/>
          ))}
        </Box>
      ) : null}
    </>
  );
});

const UploadedFileRow = React.memo(function ({id, expanded, onlyRoot, hideReportLinks, ...props}) {
  const uploadedFile = useSelector(state => uploadedFiles(state, id));
  const {
    id: uploadedFileId,
    name,
    sha256_hexdigest,
    uploaded_at,
    organization,
    errors,
    warnings,
    gemagvlxml_lieferungen,
    type,
    child_of,
    is_duplicate: isDuplicate,
    is_completed: isCompleted,
    is_failed: isFailed,
    is_processing: isProcessing,
    can_delete: canDelete,
    is_processing_started: isProcessingStarted,
    is_processing_queued: isProcessingQueued,
    processing_progress: processingProgress,
  } = uploadedFile || {};

  const hasReports = gemagvlxml_lieferungen?.length > 0;

  const hasWritePermission = useHasPermissions({perm_write_reports: true});
  const showDelete = canDelete && hasWritePermission && !hasReports;

  const getUploadedFile = useSelector(getUploadedFileGetter);
  const {isDeleted} = getUploadedFile(id);

  useEntityObserver({type: 'uploaded_file', id});

  const downloadUrl = useSessionTokenUrl(`/api/sendemeldung/organizations/${organization}/uploaded_files/${id}/blob/`);

  const {deleteEntity, deletingUuids} = useEntityDeleter({
    entityType: 'uploaded_files',
    baseUrl: `/api/sendemeldung/organizations/${organization}/uploaded_files/`,
  });

  const primaryActions = (isDuplicate || (gemagvlxml_lieferungen?.length > 0 && !hideReportLinks)) ? (
    <>
      {isDuplicate ? (
        <Button
          color="primary"
          startIcon={<DeleteIcon/>}
          onClick={
            id ? (
              () => deleteEntity(id)
            ) : undefined
          }
        >
          Kopie entfernen
        </Button>
      ) : null}
      {(hasReports && !hideReportLinks) ? (
        <Button
          color="primary"
          startIcon={<PlaylistPlay/>}
          onClick={() => navigate(`/dashboard/uploads/${id}/reports/`)}
        >
          Sendemeldungen anzeigen
        </Button>
      ) : null}
    </>
  ) : null;

  let serverStatus = (
    processingProgress?.status_text ? (
      processingProgress?.status_text
    ) : isProcessingQueued ? (
      "Warte auf Verarbeitung..."
    ) : isProcessingStarted ? (
      "wird verarbeitet..."
    ) : isDuplicate ? (
      "Doppelter Upload erkannt."
    ) : (isCompleted && gemagvlxml_lieferungen?.length > 0) ? (
      type === 'xmlrueck' ? (
        "Rückmeldungen erkannt."
      ) : (
        "Sendemeldungen erkannt."
      )
    ) : isCompleted ? (
      "Datei verarbeitet."
    ) : isFailed ? (
      type === 'xmlrueck' ? (
        "Rückmeldung nicht verarbeitet."
      ) : type === 'gemagvl4' ? (
        "Sendemeldungen nicht verarbeitet."
      ) : hasReports ? (
        "Hinweise liegen vor."
      ) : (
        "Keine Sendemeldungen erkannt."
      )
    ) : null
  );

  let serverWarning = false;
  if (isCompleted && (errors || warnings)) {
    serverWarning = true;
    serverStatus = (
      <>
        {serverStatus}
        <br/>
        Es liegen Hinweise vor.
      </>
    );
  } else if (isCompleted && (gemagvlxml_lieferungen?.length > 0 && !hideReportLinks) && !expanded) {
    serverStatus = (
      <>
        <HrefChip
          label="Sendemeldungen anzeigen"
          variant="outlined"
          clickable
          color="primary"
          href={`/dashboard/uploads/${id}/reports/`}
        />
      </>
    );
  }

  if (onlyRoot && child_of) {
    return null;
  }

  if (isDeleted) {
    return (
      <Box mt={2} mb={2}>
        <Alert variant="filled" color="error" severity="success">
          Diese Datei wurde gelöscht.
        </Alert>
      </Box>
    );
  }

  if (!uploadedFileId) {
    return (
      <Skeleton width="100%">
        <FileRow/>
      </Skeleton>
    );
  }

  let serverProgress = undefined;
  if (processingProgress?.total) {
    serverProgress = processingProgress?.progress / processingProgress?.total;
  }

  return (
    <FileRow
      id={id}
      name={name}
      type={type}
      contentHash={sha256_hexdigest}
      showContentHash
      expanded={expanded}
      {...props}
      clientStatus={
        uploaded_at ? (
          <>
            hochgeladen
            {' '}
            <NaturalTime inSentence date={uploaded_at}/>
          </>
        ) : null
      }
      serverInProgress={isProcessing}
      serverSuccessful={isCompleted}
      serverFailed={isFailed}
      serverWarning={serverWarning}
      serverStatus={serverStatus}
      serverProgress={serverProgress}
      downloadUrl={downloadUrl}
      primaryActions={primaryActions}
      onDelete={
        id && showDelete ? (
          () => deleteEntity(id)
        ) : undefined
      }
    >
      <UploadedFileDetails id={id}/>
    </FileRow>
  );
});

export default UploadedFileRow;
