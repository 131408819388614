import React, {useMemo} from 'react';
import FancyFilterField from "src/packages/FancyFilterField";

export default function ReportFilter({...props}) {
  return (
    <FancyFilterField
      options={reportOptions}
      groupBy={(option) => option.category}
      limitTags={3}
      fullWidth
      variant="outlined"
      placeholder="Lieferungen suchen..."
      {...props}
    />
  );
}

const reportOptions = [
  {
    category: "Status",
    id: 'status',
    label: 'Status',
    choice: 'outstanding',
    chipLabel: ({id, choice}) => ({
      'outstanding': "offene Lieferungen",
      'published': "veröffentlichte Lieferungen",
      'cancelled': "stornierte Lieferungen",
    }[choice]),
    choiceLabel: ({id, choice}) => ({
      'outstanding': "offen",
      'published': "veröffentlicht",
      'cancelled': "storniert",
    }[choice]),
    choices: ['outstanding', 'published', 'cancelled'],
  },
];
