import {Box} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import React from "react";

export default function CircularProgressWithLabel({value, showZero, textColor="textSecondary", ...props}) {
  return (
    <Box position="relative" display="inline-flex">
      <CircularProgress value={value * 100} {...props} />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        {value || showZero ? (
          <Typography variant="caption" component="div" color={textColor}>
            {`${Math.round(value * 100)}%`}
          </Typography>
        ) : null}
      </Box>
    </Box>
  );
}
