import makeStyles from '@mui/styles/makeStyles';
import Alert from '@mui/material/Alert';
import {AlertTitle, Box, Fab, List} from '@mui/material';
import CircularProgress from "@mui/material/CircularProgress";
import React, {useState} from "react";
import {useOrganizationRelatedListing} from "src/features/ui/listing/listing-hooks";
import DocumentFilter from "src/components/entities/document/DocumentFilter";
import PageToolbar from "src/components/layout/components/PageToolbar";
import DocumentRow from "src/components/entities/document/DocumentRow";
import {PersonForm} from "src/components/entities/person/PersonForm";
import OnlyIfPermissions from "src/features/dashboard/OnlyIfPermissions";
import {Add} from "@mui/icons-material";
import DocumentForm from "src/components/entities/document/DocumentForm";
import {getSelectedOrganizationId} from "src/features/dashboard";
import {useSelector} from "react-redux";
import {getDocumentGetter} from "src/features/entity";
import {MessageViewerDialog} from "src/components/entities/document/MessageViewerDialog";

const ID_TO_TITLE = {
  inbox: "Posteingang",
  drafts: "Entwürfe",
  sent: "Gesendet",
}

export default function DocumentList({id, organizationId, skipDrafts, skipSent}) {
  const {
    filterProps,
    paginationProps,
    isLoading,
    noDataExists,
    renderIds,
  } = useOrganizationRelatedListing({
    listingIdSuffix: `documents/${id}`,
    endpoint: `/api/sendemeldung/organizations/[ORGANIZATION_ID]/documents/${id}/`,
    entityType: 'document',
    reverseCreatedIds: true,
  });

  const getDocument = useSelector(getDocumentGetter);

  const [editDocument, setEditDocument] = useState(null);
  const [viewMessage, setViewMessage] = useState(null);

  return (
    <>
      <PageToolbar
        title={ID_TO_TITLE[id]}
        isAtTop
        searchField={(
          <DocumentFilter
            {...filterProps}
            allowSearch
          />
        )}
        hidePagination={!!noDataExists}
        paginationProps={paginationProps}
      >
      </PageToolbar>

      {renderIds?.length === 0 ? (
        noDataExists ? (
          <Alert variant="filled" severity="info">
            <AlertTitle>Keine Dokumente und Nachrichten gefunden</AlertTitle>
            Hier wurden noch keine Dokumente oder Nachrichten hinterlegt.
          </Alert>
        ) : isLoading ? (
          <Alert variant="filled" severity="info" icon={<CircularProgress size="1rem" color="inherit"/>}>
            Dokumente und Nachrichten werden geladen...
          </Alert>
        ) : (
          <Alert variant="filled" severity="info">
            <AlertTitle>Keine Dokumente und Nachrichten gefunden</AlertTitle>
            Es wurden keine Dokumente und Nachrichten gefunden, die Ihren Suchkriterien entsprechen.
          </Alert>
        )
      ) : (
        // <List className={classes.root}>
        <>
          {renderIds?.map((id, i) => (
            <DocumentRow
              key={id || i}
              id={id}
              organizationId={organizationId}
              editDocument={(documentId) => setEditDocument(getDocument(documentId))}
              createMessage={setEditDocument}
              viewMessage={(documentId) => setViewMessage(documentId)}
              skipDraft={skipDrafts}
              skipSent={skipSent}
            />
          ))}
        </>
        // </List>
      )}

      {/*TODO: Delayed removal of dialog for fadeout transition.*/}
      {editDocument ? (
        <DocumentForm
          data={editDocument}
          onClose={() => setEditDocument(null)}
        />
      ) : null}

      <MessageViewerDialog
        id={viewMessage}
        organizationId={organizationId}
        open={!!viewMessage}
        onClose={() => setViewMessage(null)}
        createMessage={setEditDocument}
      />

      <OnlyIfPermissions perm_write_documents>
        <Box mt={7} mx={1.5} textAlign="right">
          <Fab
            style={{
              position: 'fixed',
              right: 20,
              bottom: 20,
            }}
            color="primary"
            aria-label="add"
            onClick={() => (
              // TODO: Outsource person bootstrap data to api/schemas.
              setEditDocument({
                //sender: organizationId,
                //type: 'message',
                //status: 0,
              })
            )}>
            <Add/>
          </Fab>
        </Box>
      </OnlyIfPermissions>
    </>
  );
}
