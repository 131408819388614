import React, {useState} from "react";
import {DashboardLayout} from "src/components/layout";
import {AlertTitle, Box, Card, CardContent, Chip, Grid, IconButton, Paper} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import Typography from "@mui/material/Typography";
import {useSelector} from "react-redux";
import {getErschieneneTonaufnahmeGetter, getMusikPersonGetter} from "src/features/entity";
import MusikTitel from "src/components/entities/musicwork/components/MusikTitel";
import {useGVLProduct, useOrgMusicWork} from "src/features/entity/entity-hooks";
import {getSelectedOrganization} from "src/features/dashboard";
import MusikPerson, {VERLEGER_LIST} from "src/components/entities/musicwork/components/MusikPerson";
import ErschieneneTonaufnahme from "src/components/entities/musicwork/components/ErschieneneTonaufnahme";
import {TodoTasks} from "src/components/entities/todotask/TodoTasks";
import Playlist from "src/components/entities/gemagvlxml/components/Playlist";
import Alert from '@mui/material/Alert';
import CircularProgress from "@mui/material/CircularProgress";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import {MusicPersonForm} from "src/components/entities/musicwork/MusicPersonForm";
import {MusicProductionIDForm} from "src/components/entities/musicwork/MusicProductionIDForm";
import {ErschieneneTonaufnahmeForm} from "src/components/entities/musicwork/ErschieneneTonaufnahmeForm";
import {MusicWorkForm} from "src/components/entities/musicwork/MusicWorkForm";
import {Add, Edit} from "@mui/icons-material";
import {BESETZUNG_KNZ, RECHT_KNZ} from "src/features/dashboard/dashboard-validation";
import {MusicNutzungForm} from "src/components/entities/musicwork/MusicNutzungForm";
import HrefComponent from "src/packages/gatsby-mui-helpers/HrefComponent";
import MusikProduktionIds from "src/components/entities/musicwork/components/MusikProduktionIds";
import MusikInterpreten from "src/components/entities/musicwork/components/MusikInterpreten";
import MusikUrheber from "src/components/entities/musicwork/components/MusikUrheber";
import MusikHerkunft from "src/components/entities/musicwork/components/MusikHerkunft";
import MusicWorkChangeHistory from "src/components/entities/musicwork/MusicWorkChangeHistory";
import {Unshrinkable} from "src/packages/unshrinkable";
import NaturalTime from "src/packages/natural-time-view";
import GVLLinkIndicator from "src/components/entities/musicwork/components/GVLLinkIndicator";
import {useHasPermissions, useRequirePermissions} from "src/features/dashboard/dashboard-hooks";
import OnlyIfPermissions from "src/features/dashboard/OnlyIfPermissions";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(1),
    width: '100%',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  tabRoot: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
}));

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

function TabPanel(props) {
  const {
    children,
    value,
    index,
    ...other
  } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}

export default function MusicWorkPage({
  databaseId,
  id,
  showLinkedData = false,
  '*': subPage
}) {
  useRequirePermissions({perm_read_music: true});
  const hasWritePermission = useHasPermissions({perm_write_music: true});

  let tab = subPage === 'changes' ? 1 : subPage === 'plays' ? 2 : subPage === 'tasks' ? 0 : undefined;

  const classes = useStyles();

  const orgMusicWork = useOrgMusicWork({id});
  const {
    id: orgMusicWorkId,
    organization,
    database,
    musik_titel,
    titel_sonstige,
    recht_knz,
    besetzung_knz,
    gattung,
    nutzung_art_knz,
    aufnahme_datum,
    prod_titel,
    prod_ort,
    musik_herkunft_knz,
    todo_tasks: todoTasks,
    stations: stationIds,
    is_silence,
    created_at,
    updated_at,
    is_tentative,
    linked_gvl_produkt: linkedGVLProduktId,
    todo_tasks,
  } = orgMusicWork;

  const linked_gvl_produkt = useGVLProduct({id: linkedGVLProduktId});

  if (tab === undefined) {
    if (todo_tasks?.length === 0) {
      tab = 1;
    } else {
      tab = 0;
    }
  }

  const {
    erschienene_tonaufnahme: erschieneneTonaufnahmeId,
    musik_personen,
    musik_produktion_ids,
    needs_erschienene_tonaufnahme: needsErschieneneTonaufnahme,
    ...orgMusicWorkData
  } = orgMusicWork;
  const erschienene_tonaufnahme = useSelector(getErschieneneTonaufnahmeGetter)(erschieneneTonaufnahmeId);

  const {
    is_empty: erschieneneTonaufnahmeIsEmpty
  } = erschienene_tonaufnahme;

  const getMusikPerson = useSelector(getMusikPersonGetter);
  const personen = musik_personen?.map(getMusikPerson);

  const verleger = personen?.filter(({musik_person_rolle_knz}) => VERLEGER_LIST.includes(musik_person_rolle_knz));

  const [editMusicWork, setEditMusicWork] = useState(null);
  const [editMusicNutzung, setEditMusicNutzung] = useState(null);
  const [editPerson, setEditPerson] = useState(null);
  const [editProduktionId, setEditProduktionId] = useState(null);
  const [editErschieneneTonaufnahme, setEditErschieneneTonaufnahme] = useState(null);

  return (
    <DashboardLayout
      titlePrefix="Musikproduktion"
      selectedPage={`database/${database}`}
    >
      <Box my={2}>
        <Typography variant="h6">
          Details zu Musikproduktion
        </Typography>
      </Box>

      {orgMusicWorkId ? (
        <>
          <Box mt={2}>
            <Grid container>
              <Grid container item xs={8}>
                <Card className={classes.root}>
                  <CardContent sx={{position: 'relative'}}>
                    <Chip label={RECHT_KNZ[recht_knz]} size="small" sx={{
                      position: 'absolute',
                      right: 16,
                      top: 16
                    }}/>
                    <Typography className={classes.title} color="textSecondary" gutterBottom>
                      Titel
                      <OnlyIfPermissions perm_write_music>
                        {' '}
                        <IconButton
                          aria-label="edit"
                          onClick={() => setEditMusicWork({
                            id,
                            organization,
                            database,
                            musik_titel,
                            recht_knz,
                            besetzung_knz,
                            gattung,
                            is_silence,
                          })}
                          size="small"
                        >
                          <Edit/>
                        </IconButton>
                      </OnlyIfPermissions>
                    </Typography>
                    <Typography variant="h5" component="h2">
                      <MusikTitel
                        id={orgMusicWorkId}
                      />
                      {showLinkedData && linked_gvl_produkt?.titel ? (
                        <Box mt={2}>
                          <Paper variant="outlined">
                            <Box p={1} sx={{
                              maxHeight: 250,
                              overflowY: 'auto'
                            }}>
                              <Typography variant="h6">
                                Verfügbare Referenzdaten
                              </Typography>
                              {linked_gvl_produkt?.titel?.map(({
                                name,
                                zusatz,
                                weiterer
                              }, i) => (
                                <Typography key={i}>
                                  {name}
                                  {' '}
                                  {zusatz ? (
                                    <Chip component="span" label={zusatz}/>
                                  ) : null}
                                  {weiterer ? (
                                    <Chip size="small" component="span" label="Alternativtitel"/>
                                  ) : null}
                                  <Chip size="small" component="span" label="GVL"/>
                                </Typography>
                              ))}
                            </Box>
                          </Paper>
                        </Box>
                      ) : null}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>

              <Grid container item xs={4}>
                {(created_at || updated_at) ? (
                  <Card className={classes.root}>
                    <CardContent>
                      <Typography className={classes.title} color="textSecondary" gutterBottom>
                        Status
                      </Typography>
                      {!is_tentative && updated_at ? (
                        <p>zuletzt geändert <Unshrinkable><NaturalTime date={updated_at} inSentence/></Unshrinkable></p>
                      ) : created_at ? (
                        <p>erfasst <Unshrinkable><NaturalTime date={created_at} inSentence/></Unshrinkable></p>
                      ) : null}
                      {linkedGVLProduktId ? (
                        <GVLLinkIndicator id={id} allowUnlink/>
                      ) : null}
                    </CardContent>
                  </Card>
                ) : null}
              </Grid>

              <Grid container item xs={6}>
                <Card className={classes.root}>
                  <CardContent>
                    <Typography className={classes.title} color="textSecondary" gutterBottom>
                      Urheber
                      <OnlyIfPermissions perm_write_music>
                        {' '}
                        <IconButton
                          aria-label="add"
                          onClick={() => setEditPerson({
                            org_music_work: id,
                            musik_person_rolle_knz: 'K'
                          })}
                          size="small"
                        >
                          <Add/>
                        </IconButton>
                      </OnlyIfPermissions>
                    </Typography>
                    <MusikUrheber id={id}/>
                    {showLinkedData && linked_gvl_produkt?.personen ? (
                      <Box mt={2}>
                        <Paper variant="outlined">
                          <Box p={1} sx={{
                            maxHeight: 250,
                            overflowY: 'auto'
                          }}>
                            <Typography variant="h6">
                              Verfügbare Referenzdaten
                            </Typography>
                            {linked_gvl_produkt?.personen?.map(({
                              vorname,
                              vorname_zusatz,
                              nachname,
                              nachname_zusatz,
                              rolle
                            }, i) => rolle === 'K' ? (
                              <Typography key={i}>
                                {vorname}
                                {' '}
                                {vorname_zusatz}
                                {' '}
                                {nachname}
                                {' '}
                                {nachname_zusatz}
                                {' '}
                                <Chip size="small" component="span" label="GVL"/>
                              </Typography>
                            ) : null)}
                          </Box>
                        </Paper>
                      </Box>
                    ) : null}
                  </CardContent>
                </Card>

                <Card className={classes.root}>
                  <CardContent>
                    <Typography className={classes.title} color="textSecondary" gutterBottom>
                      Interpreten
                      <OnlyIfPermissions perm_write_music>
                        {' '}
                        <IconButton
                          aria-label="add"
                          onClick={() => setEditPerson({
                            org_music_work: id,
                            musik_person_rolle_knz: 'INT'
                          })}
                          size="small"
                        >
                          <Add/>
                        </IconButton>
                      </OnlyIfPermissions>
                    </Typography>
                    <MusikInterpreten id={id}/>
                    {showLinkedData && linked_gvl_produkt?.personen ? (
                      <Box mt={2}>
                        <Paper variant="outlined">
                          <Box p={1} sx={{
                            maxHeight: 250,
                            overflowY: 'auto'
                          }}>
                            <Typography variant="h6">
                              Verfügbare Referenzdaten
                            </Typography>
                            {linked_gvl_produkt?.personen?.map(({
                              vorname,
                              vorname_zusatz,
                              nachname,
                              nachname_zusatz,
                              rolle
                            }, i) => rolle === 'I' ? (
                              <Typography key={i}>
                                {vorname}
                                {' '}
                                {vorname_zusatz}
                                {' '}
                                {nachname}
                                {' '}
                                {nachname_zusatz}
                                {' '}
                                <Chip size="small" component="span" label="GVL"/>
                              </Typography>
                            ) : null)}
                          </Box>
                        </Paper>
                      </Box>
                    ) : null}
                  </CardContent>
                </Card>

                {verleger?.length > 0 ? (
                  <Card className={classes.root}>
                    <CardContent>
                      <Typography className={classes.title} color="textSecondary" gutterBottom>
                        Verleger
                        <OnlyIfPermissions perm_write_music>
                          {' '}
                          <IconButton
                            aria-label="add"
                            onClick={() => setEditPerson({
                              org_music_work: id,
                              musik_person_rolle_knz: 'V'
                            })}
                            size="small"
                          >
                            <Add/>
                          </IconButton>
                        </OnlyIfPermissions>
                      </Typography>
                      {verleger?.map(({id}, i) => (
                        <p key={id || i}>
                          <MusikPerson
                            id={id}
                            showRole
                            onEdit={setEditPerson}
                          />
                        </p>
                      ))}
                    </CardContent>
                  </Card>
                ) : null}
              </Grid>

              <Grid container item xs={6}>
                {besetzung_knz ? (
                  <Card className={classes.root}>
                    <CardContent>
                      <Typography className={classes.title} color="textSecondary" gutterBottom>
                        Besetzung
                        <OnlyIfPermissions perm_write_music>
                          {' '}
                          <IconButton
                            aria-label="edit"
                            onClick={() => setEditMusicWork({
                              id,
                              organization,
                              database,
                              musik_titel,
                              recht_knz,
                              besetzung_knz,
                              gattung,
                              is_silence,
                            })}
                            size="small"
                          >
                            <Edit/>
                          </IconButton>
                        </OnlyIfPermissions>
                      </Typography>
                      {BESETZUNG_KNZ[besetzung_knz]}
                    </CardContent>
                  </Card>
                ) : null}

                {gattung ? (
                  <Card className={classes.root}>
                    <CardContent>
                      <Typography className={classes.title} color="textSecondary" gutterBottom>
                        Gattung
                        <OnlyIfPermissions perm_write_music>
                          {' '}
                          <IconButton
                            aria-label="edit"
                            onClick={() => setEditMusicWork({
                              id,
                              organization,
                              database,
                              musik_titel,
                              recht_knz,
                              besetzung_knz,
                              gattung,
                              is_silence,
                            })}
                            size="small"
                          >
                            <Edit/>
                          </IconButton>
                        </OnlyIfPermissions>
                      </Typography>
                      {gattung}
                    </CardContent>
                  </Card>
                ) : null}

                {(aufnahme_datum || prod_titel || prod_ort || musik_herkunft_knz === 'LIVE') ? (
                  <Card className={classes.root}>
                    <CardContent>
                      <Typography className={classes.title} color="textSecondary" gutterBottom>
                        Aufnahme
                        <OnlyIfPermissions perm_write_music>
                          {' '}
                          <IconButton
                            aria-label="add"
                            onClick={() => setEditMusicNutzung({
                              id,
                              organization,
                              database,
                              nutzung_art_knz,
                              aufnahme_datum,
                              prod_titel,
                              prod_ort,
                              musik_herkunft_knz
                            })}
                            size="small"
                          >
                            <Edit/>
                          </IconButton>
                        </OnlyIfPermissions>
                      </Typography>
                      {aufnahme_datum ? (
                        <div>
                          {aufnahme_datum}
                          {' '}
                          <Chip label="Datum" size="small"/>
                        </div>
                      ) : musik_herkunft_knz === 'LIVE' ? (
                        <OnlyIfPermissions perm_write_music>
                          <Chip
                            icon={<Add/>}
                            variant="outlined"
                            color="primary"
                            clickable
                            label="Aufnahmedatum ergänzen"
                            onClick={() => setEditMusicNutzung({
                              id,
                              organization,
                              database,
                              nutzung_art_knz,
                              aufnahme_datum,
                              prod_titel,
                              prod_ort,
                              musik_herkunft_knz
                            })}
                          />
                        </OnlyIfPermissions>
                      ) : null}
                      {prod_titel ? (
                        <div>
                          {prod_titel}
                          {' '}
                          <Chip label="Name der Produktion" size="small"/>
                        </div>
                      ) : null}
                      {prod_ort ? (
                        <div>
                          {prod_ort}
                          {' '}
                          <Chip label="Ort" size="small"/>
                        </div>
                      ) : null}
                    </CardContent>
                  </Card>
                ) : null}

                <Card className={classes.root}>
                  <CardContent>
                    <Typography className={classes.title} color="textSecondary" gutterBottom>
                      Musikherkunft
                      <OnlyIfPermissions perm_write_music>
                        {' '}
                        <IconButton
                          aria-label="add"
                          onClick={() => setEditMusicNutzung({
                            id,
                            organization,
                            database,
                            nutzung_art_knz,
                            aufnahme_datum,
                            prod_titel,
                            prod_ort,
                            musik_herkunft_knz
                          })}
                          size="small"
                        >
                          <Edit/>
                        </IconButton>
                      </OnlyIfPermissions>
                    </Typography>
                    <MusikHerkunft id={id}/>
                  </CardContent>
                </Card>

                {(!erschieneneTonaufnahmeIsEmpty || needsErschieneneTonaufnahme) ? (
                  <Card className={classes.root}>
                    <CardContent>
                      <Typography className={classes.title} color="textSecondary" gutterBottom>
                        Erschienene Tonaufnahme
                        <OnlyIfPermissions perm_write_music>
                          {' '}
                          <IconButton
                            aria-label="edit"
                            onClick={() => setEditErschieneneTonaufnahme({
                              id: erschieneneTonaufnahmeId,
                              org_music_work: orgMusicWorkId,
                              ...erschienene_tonaufnahme
                            })}
                            size="small"
                          >
                            <Edit/>
                          </IconButton>
                        </OnlyIfPermissions>
                      </Typography>
                      <ErschieneneTonaufnahme
                        id={erschieneneTonaufnahmeId}
                        orgMusicWorkId={orgMusicWorkId}
                        full
                      />
                    </CardContent>
                  </Card>
                ) : null}

                <Card className={classes.root}>
                  <CardContent>
                    <Typography className={classes.title} color="textSecondary" gutterBottom>
                      Identifier
                      <OnlyIfPermissions perm_write_music>
                        {' '}
                        <IconButton
                          aria-label="add"
                          onClick={() => setEditProduktionId({
                            org_music_work: id
                          })}
                          size="small"
                        >
                          <Add/>
                        </IconButton>
                      </OnlyIfPermissions>
                    </Typography>
                    <MusikProduktionIds id={id}/>
                    {showLinkedData && linked_gvl_produkt?.identifiers ? (
                      <Box mt={2}>
                        <Paper variant="outlined">
                          <Box p={1} sx={{
                            maxHeight: 250,
                            overflowY: 'auto'
                          }}>
                            <Typography variant="h6">
                              Verfügbare Referenzdaten
                            </Typography>
                            {linked_gvl_produkt?.identifiers?.map(({
                              type,
                              name
                            }, i) => (
                              <Typography key={i}>
                                {name}
                                {' '}
                                <Chip size="small" component="span" label={type}/>
                                {' '}
                                <Chip size="small" component="span" label="GVL"/>
                              </Typography>
                            ))}
                          </Box>
                        </Paper>
                      </Box>
                    ) : null}
                  </CardContent>
                </Card>
              </Grid>
            </Grid>

            <div className={classes.root}>
              <Paper>
                <AppBar position="static" color="default">
                  <Tabs
                    value={tab}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="fullWidth"
                    scrollButtons="auto"
                    aria-label="scrollable auto tabs example"
                  >
                    <HrefComponent
                      component={Tab}
                      href={`/dashboard/database/${databaseId}/${id}/tasks/`}
                      label={(
                        <>
                          Zu erledigen
                        </>
                      )}
                      {...a11yProps(0)}
                    />
                    <HrefComponent
                      component={Tab}
                      href={`/dashboard/database/${databaseId}/${id}/changes/`}
                      label={(
                        <>
                          Änderungen
                        </>
                      )}
                      {...a11yProps(1)}
                    />
                    <OnlyIfPermissions
                      perm_read_reports
                      containerComponent={HrefComponent}
                      component={Tab}
                      href={`/dashboard/database/${databaseId}/${id}/plays/`}
                      label={(
                        <>
                          Ausstrahlungen
                        </>
                      )}
                      {...a11yProps(2)}
                    />
                  </Tabs>
                </AppBar>
                <TabPanel value={tab} index={0}>
                  <TodoTasks ids={todoTasks} stationIds={stationIds}>
                    <Alert severity="info">
                      <AlertTitle>Nichts zu tun</AlertTitle>
                      <Typography>Zu dieser Musikproduktion gibt es derzeit keine offenen Aufgaben.</Typography>
                    </Alert>
                  </TodoTasks>
                </TabPanel>
                <TabPanel value={tab} index={1}>
                  <MusicWorkChangeHistory id={orgMusicWorkId}/>
                </TabPanel>
                <TabPanel value={tab} index={2} style={{marginTop: -20}}>
                  <Playlist
                    orgMusicWorkId={orgMusicWorkId}
                    defaultOrdering="-datum_von,-datum_uhrzeit_von"
                    loadingContent={(
                      <Alert variant="filled" severity="info" icon={<CircularProgress size="1rem" color="inherit"/>}>
                        Ausstrahlungen werden geladen...
                      </Alert>
                    )}
                    showReports
                    disableEdit
                    hideLinks
                  >
                    <Alert variant="filled" severity="info">
                      Diese Ansicht enthält keine Einträge.
                    </Alert>
                  </Playlist>
                </TabPanel>
              </Paper>
            </div>

          </Box>

          {editMusicWork ? (
            <MusicWorkForm
              data={editMusicWork}
              onClose={() => setEditMusicWork(null)}
            />
          ) : null}

          {editMusicNutzung ? (
            <MusicNutzungForm
              data={editMusicNutzung}
              onClose={() => setEditMusicNutzung(null)}
            />
          ) : null}

          {editPerson ? (
            <MusicPersonForm
              data={editPerson}
              onClose={() => setEditPerson(null)}
            />
          ) : null}

          {editProduktionId ? (
            <MusicProductionIDForm
              data={editProduktionId}
              onClose={() => setEditProduktionId(null)}
            />
          ) : null}

          {editErschieneneTonaufnahme ? (
            <ErschieneneTonaufnahmeForm
              data={editErschieneneTonaufnahme}
              onClose={() => setEditErschieneneTonaufnahme(null)}
            />
          ) : null}
        </>
      ) : null}
    </DashboardLayout>
  );
}
